import { ReactComponent as SvgLeftArrow } from "assets/svg/left-arrow.svg";
import { BaseBreadCrumbs } from "common/components/Breadcrumbs/BaseBreadcrumbs";
import { useAuth } from "common/hooks";
import { EditProfileContent } from "features/userSettings/components/profileSetting/EditProfileContent";
import { StyledUserSettingCard } from "features/userSettings/components/UserSettingsView";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";

export const EditProfileView = (): JSX.Element => {
  const { currentUserProfile } = useAuth();
  const { t } = useTranslation();
  return (
    <div>
      <Link to="/settings/user-profile" className="flex flex-row mb-3 w-fit">
        <TitleWrapper>
          <SvgLeftArrow className="mr-3" width="24px" height="24px" />
        </TitleWrapper>
        <h2>{t("settings.personalDetail.edit")}</h2>
      </Link>
      <BaseBreadCrumbs itemContainerClassName="hover:text-light-blue">
        <Link to="/">{t("settings.breadcrumbs.home")}</Link>
        <Link to="/settings/user-profile">
          {t("settings.userProfile.title")}
        </Link>
        <Link to="/settings/edit-profile">
          {t("settings.personalDetail.edit")}
        </Link>
      </BaseBreadCrumbs>
      <StyledUserSettingCard>
        <EditProfileContent userProfile={currentUserProfile} />
      </StyledUserSettingCard>
    </div>
  );
};

const TitleWrapper = styled.div(() => [
  tw`flex items-center justify-start text-dark-blue`,
]);
