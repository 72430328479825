import { getInitials } from "common/utils/common";
import { Colors } from "enums/colors";
import tw, { styled } from "twin.macro";

type IconProps = {
  variant: "image" | "initials";
  content: string;
  size?: UserIconSize;
  className?: string;
  color?: Colors;
};

const Circle = styled.div(({ variant }: Pick<IconProps, "variant">) => [
  tw`flex justify-center items-center text-center`,
  tw`cursor-pointer`,
  tw`rounded-full overflow-hidden`,
  variant === "image" ? tw`p-0` : tw`p-3`,
]);

const Initials = styled.div(() => [tw`font-medium text-white`]);

export enum UserIconSize {
  SMALL = "small",
  NORMAL = "normal",
  LARGE = "large",
}

const fontSizeMap: Record<UserIconSize, string> = {
  small: "text-xs",
  normal: "text-sm",
  large: "text-base",
};

const iconSizeMap: Record<UserIconSize, number> = {
  small: 24,
  normal: 32,
  large: 40,
};

export const UserIcon = ({
  color,
  variant,
  size,
  content,
  className,
}: IconProps): JSX.Element => {
  const iconSize = iconSizeMap[size ?? "normal"];
  const fontSize = fontSizeMap[size ?? "normal"];
  return (
    <Circle
      className={`bg-${color ?? Colors.LIGHT_GREEN} ` + className}
      variant={variant}
      style={{ width: iconSize, height: iconSize }}
    >
      {variant === "image" ? (
        <img src={content} alt="user-icon" width={iconSize} />
      ) : (
        <Initials className={fontSize}>{getInitials(content)}</Initials>
      )}
    </Circle>
  );
};
