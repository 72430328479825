import { FILE_TYPE } from "constants/file";

import { attachmentAPI } from "api/attachment";
import { Dropzone } from "common/components/Form/Dropzone";
import { Label } from "common/components/Form/FieldContainer";
import { PureUploadDocumentsCreateContext } from "features/public/contexts/PureUploadDocumentsCreateContext";
import { PDFFile } from "features/public/types";
import { supportRequestUploadAndHandleProgressBar } from "features/public/utils/upload";
import { useField } from "formik";
import { cloneDeep } from "lodash";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type SupportRequestUploadFieldProps = {
  name: string;
  label: string;
  className?: string;
  errorMsgClassName?: string;
};

const StyledContentWrapper = tw.div`cursor-pointer flex flex-col items-center`;

export const SupportRequestUploadField = ({
  name,
  label,
  className,
  errorMsgClassName,
}: SupportRequestUploadFieldProps) => {
  const { t } = useTranslation();
  const [field, , helpers] = useField<PDFFile[]>(name);
  const [error, setError] = useState<string>();

  const { setNewUploadedFiles, isUploading, setIsUploading } = useContext(
    PureUploadDocumentsCreateContext
  );

  const handleChangeFile = useCallback(
    async (uploadingFiles: File[]) => {
      setIsUploading(true);
      const uploadedFiles = cloneDeep(field.value);
      try {
        const newUploadedFilesResponse =
          await supportRequestUploadAndHandleProgressBar(
            uploadingFiles,
            uploadedFiles,
            attachmentAPI.publicUpload,
            helpers.setValue,
            setError
          );

        if (uploadedFiles[0].id && newUploadedFilesResponse) {
          setNewUploadedFiles(
            cloneDeep(uploadedFiles.filter((it) => it.id)).concat(
              newUploadedFilesResponse
            )
          );
        } else {
          setNewUploadedFiles(cloneDeep(newUploadedFilesResponse));
        }
      } catch (error) {
        console.error("error", error);
      }
      setIsUploading(false);
    },
    [field.value, helpers, setIsUploading, setNewUploadedFiles]
  );

  return (
    <div className={className}>
      <Label htmlFor={name}>{label}</Label>
      <Dropzone
        className="py-3"
        onChange={handleChangeFile}
        isDisabled={isUploading}
        acceptFileType={[FILE_TYPE.JPG, FILE_TYPE.PNG, FILE_TYPE.PDF]}
        errorMsgPosition="under"
        error={error}
        setError={setError}
      >
        {(open) => (
          <StyledContentWrapper onClick={open}>
            <h3 className="text-dark-grey text-sm mb-2">
              {t("public.onboarding.dropzoneTitle")}
            </h3>
            <p className="text-grey text-xs font-medium">
              {t("public.onboarding.dropzoneTitleHint")}
            </p>
          </StyledContentWrapper>
        )}
      </Dropzone>
    </div>
  );
};
