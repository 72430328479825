import useAuth from "common/hooks/useAuth";
import useRedirectState from "common/hooks/useRedirectState";
import React from "react";
import { Location, Navigate, useLocation } from "react-router-dom";

type AuthenticatedCheckProps = {
  isPublic?: boolean;
  children: React.ReactNode;
};

export const AuthenticatedCheck = ({
  isPublic,
  children,
}: AuthenticatedCheckProps): JSX.Element => {
  const { isLoggedIn } = useAuth();
  const { canRedirect } = useRedirectState();
  const location = useLocation();
  const locationState = location.state as { from?: Location } | null;

  if (isPublic) {
    if (isLoggedIn) {
      return <Navigate to={locationState?.from?.pathname ?? "/"} replace />;
    } else {
      return <>{children}</>;
    }
  } else {
    if (isLoggedIn) {
      return <>{children}</>;
    } else {
      // Empty object here to reset state to make sure after login will direct to dashboard
      return (
        <Navigate
          to="/public/login"
          state={{
            from: canRedirect ? location : null,
          }}
          replace
        />
      );
    }
  }
};
