import * as PopperJS from "@popperjs/core";
import { useState } from "react";
import { Modifier, usePopper } from "react-popper";

type Options<Modifiers> = Omit<Partial<PopperJS.Options>, "modifiers"> & {
  createPopper?: typeof PopperJS.createPopper;
  modifiers?: ReadonlyArray<Modifier<Modifiers>>;
};

export const useCommonPopover = <T>(modifier?: Options<T>) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>();
  const { styles, attributes, update } = usePopper<T>(
    referenceElement,
    popperElement,
    modifier
  );

  return { setReferenceElement, setPopperElement, styles, attributes, update };
};
