import { publicUserAPI, supportPayload } from "api/publicUser";
import { Button } from "common/components/Button";
import { composeErrorHandlers, GenericError } from "common/errorHandling";
import {
  TitleSection,
  BottomSpacingSize,
} from "features/public/components/TitleSection";
import {
  SupportRequestForm,
  SupportRequestFormValues,
} from "features/public/ResetPassword/components/SupportRequestForm";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormSubmissionHandler } from "types/common";

export const SupportRequestView = (): JSX.Element => {
  const { t } = useTranslation();
  const [sentSuccessfulView, setSuccessfulView] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleBackToHomepage = async () => {
    navigate("/public/login");
  };
  const handleSubmit: FormSubmissionHandler<SupportRequestFormValues> = async (
    values
  ) => {
    try {
      const adjustedValues = {
        email: values.email.trim(),
        subject: values.subject.trim(),
        description: values.description.trim(),
        attachments: values.attachments,
      };
      const payload = getSupportPayload(adjustedValues);
      await publicUserAPI.requestSupport(payload);
      setSuccessfulView(true);
    } catch (error) {
      composeErrorHandlers((next) => (e) => {
        next(e);
      })(error as GenericError);
    }
  };

  return (
    <div className="w-full pt-40">
      <TitleSection
        title={
          sentSuccessfulView
            ? t("public.onboarding.supportRequestSuccessTitle")
            : t("public.onboarding.supportRequestTitle")
        }
        description={
          sentSuccessfulView
            ? t("public.onboarding.supportRequestSuccessTitleHint")
            : t("public.onboarding.supportRequestTitleHint")
        }
        bottomSpacing={BottomSpacingSize.CLOSE}
      />
      {sentSuccessfulView ? (
        <Button
          className="w-full mt-40"
          variant="primary"
          onClick={handleBackToHomepage}
        >
          {t("public.button.backToHome")}
        </Button>
      ) : (
        <SupportRequestForm onSubmit={handleSubmit} />
      )}
    </div>
  );
};

export const getSupportPayload = (
  value: SupportRequestFormValues
): supportPayload => {
  return {
    attachmentIds: value.attachments.flatMap((file) => file.id),
    description: value.description,
    email: value.email,
    subject: value.subject,
  };
};
