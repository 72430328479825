import { AuthError } from "common/context/AuthErrorContext";
import {
  composeErrorHandlers,
  ErrorHandler,
  ErrorHandlerCreator,
  isAPIError,
} from "common/errorHandling";
import { useAuthError } from "common/hooks/useAuthError";
import useLogout from "common/hooks/useLogout";
import { useCallback, useMemo } from "react";

export const ERROR_KEY_CONCURRENT_LOGIN = "authentication.concurrent";

export const useAuthErrorHandlerCreator = (): ErrorHandlerCreator => {
  const logout = useLogout();
  const { authError, setAuthError } = useAuthError();
  // const [, setAuthError] = useLocalStorage(LOCAL_STORAGE_KEY.AUTH_ERROR); // TODO change to local storage if need to support cross taps

  return useCallback(
    (next) => {
      return (error) => {
        if (isAPIError(error)) {
          if (error.status === 403) {
            if (!authError) {
              if (error?.errors[0]?.key === ERROR_KEY_CONCURRENT_LOGIN) {
                setAuthError(AuthError.CONCURRENT_LOGIN);
              } else {
                setAuthError(AuthError.EXPIRY);
                logout();
              }
            } else {
              // if auth error exists, dismiss new error
            }
          } else {
            next(error);
          }
        } else {
          next(error);
        }
      };
    },
    [authError, setAuthError, logout]
  );
};

const useAuthErrorHandler = (): ErrorHandler => {
  const onAuthErrorHandler = useAuthErrorHandlerCreator();
  return useMemo(
    () => composeErrorHandlers(onAuthErrorHandler),
    [onAuthErrorHandler]
  );
};

export default useAuthErrorHandler;
