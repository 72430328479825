import { createContext, Dispatch, SetStateAction, useState } from "react";

type VersionContextProps = {
  version: string;
  setVersion: Dispatch<SetStateAction<string>>;
};

/**
 * <1 to disable register related feature
 */
const DEFAULT_VERSION = process.env.REACT_APP_VERSION ?? "1.0.0";

export const VersionContext = createContext<VersionContextProps>({
  version: DEFAULT_VERSION,
  setVersion: () => {},
});

type VersionContextProviderProps = {
  children: React.ReactNode;
};

export const VersionContextProvider = ({
  children,
}: VersionContextProviderProps) => {
  const [version, setVersion] = useState<string>(DEFAULT_VERSION);
  // TODO setVersion feature TBD, set manually for now
  return (
    <VersionContext.Provider value={{ version, setVersion }}>
      {children}
    </VersionContext.Provider>
  );
};
