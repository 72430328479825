import { childrenDomainBuilder } from "common/utils/cross-domain/base/utils";

const KENTRO_ONE_NETWORK_DOMAIN = process.env.REACT_APP_KENTRO_ONE_NETWORK_URL;
export const KENTRO_SIGN_DOMAIN = process.env.REACT_APP_KENTRO_SIGN_URL;
const TOPAZ_DOMAIN = process.env.REACT_APP_TOPAZ_URL;
const MIDAS_DOMAIN = process.env.REACT_APP_MIDAS_URL;
const ATLAS_ELITE_DOMAIN = process.env.REACT_APP_ATLAS_ELITE_URL;

// TODO: Should include both parent and children domain
// ideally BE should return the user subscribed domain
const SUPPORTED_DOMAIN = [
  KENTRO_SIGN_DOMAIN,
  TOPAZ_DOMAIN,
  MIDAS_DOMAIN,
  ATLAS_ELITE_DOMAIN,
  KENTRO_ONE_NETWORK_DOMAIN,
];

export const CHILDREN_DOMAIN = childrenDomainBuilder(
  SUPPORTED_DOMAIN.filter((it) => !!it) as string[]
);
