import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { buildYupLocale } from "validationLocale";

/**
 * Design TBD
 * en for English
 * zh-TW for Taiwanese, case sensitive
 */

export const LANGUAGES: Map<string, string> = new Map([
  ["en", "English"],
  ["zh-TW", "繁體中文"],
  ["zh", "简体中文"],
]);

const CURRENT_LANGUAGE = process.env.REACT_APP_LANGUAGE ?? "en";

i18n
  .use(Backend) // to read public/locale
  .use(initReactI18next)
  .init(
    {
      lng: CURRENT_LANGUAGE,
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      supportedLngs: ["en", "zh-TW"], // need to list out all supported lang to prevent 404 error
    },
    buildYupLocale //the buildYupLocale function is invoked with the second argument being the TFunction
  );

export default i18n;
