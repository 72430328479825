import { Children, ReactNode } from "react";
import tw, { styled } from "twin.macro";

type BreadCrumbComponentProps = {
  children: ReactNode;
  containerClassName?: string;
  itemContainerClassName?: string;
  dividerIcon?: ReactNode;
  dividerSpaceClassName?: string;
  disabledActive?: boolean;
};

type BreadCrumbItemWrapperProps = {
  active: boolean;
};

const BreadCrumbItemWrapper = styled.div(
  ({ active }: BreadCrumbItemWrapperProps) => [
    tw`text-grey text-sm cursor-pointer`,
    active
      ? tw`text-dark-blue hover:text-dark-blue cursor-auto truncate`
      : tw`text-grey text-sm`,
  ]
);

export const BaseBreadCrumbs = ({
  children,
  containerClassName,
  itemContainerClassName,
  dividerSpaceClassName = "mx-1 my-0",
  dividerIcon = <>/</>,
  disabledActive = false,
}: BreadCrumbComponentProps) => {
  const allChildren = Children.toArray(children).map((child, index) => {
    const last = index === Children.toArray(children).length - 1;
    return (
      <BreadCrumbItemWrapper
        className={`${itemContainerClassName}`}
        active={last && !disabledActive}
        key={index}
      >
        {child}
        {!last && (
          <span className={`mx-1 my-0 ${dividerSpaceClassName}`}>
            {dividerIcon}
          </span>
        )}
      </BreadCrumbItemWrapper>
    );
  });

  return (
    <div className={`flex items-center font-medium ${containerClassName}`}>
      {allChildren}
    </div>
  );
};
