import {
  REG_LOWERCASE,
  REG_MINIMUM,
  REG_NUM_OR_SPECIAL,
  REG_UPPERCASE,
} from "constants/passwordValidatorKey";

import { PasswordStatusItem } from "common/components/Form/PasswordStatusItem";
import { useTranslation } from "react-i18next";

type PasswordValidatorProps = {
  password: string;
};

export const PasswordValidator = ({
  password,
}: PasswordValidatorProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex flex-row">
        <div className="basis-2/5">
          <PasswordStatusItem
            label={t("public.onboarding.passwordMinCharacter")}
            password={password}
            testRegex={REG_MINIMUM}
          />
        </div>
        <div className="basis-3/5">
          <PasswordStatusItem
            label={t("public.onboarding.passwordLowercaseCharacter")}
            password={password}
            testRegex={REG_LOWERCASE}
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="basis-2/5">
          <PasswordStatusItem
            label={t("public.onboarding.passwordUppercaseCharacter")}
            password={password}
            testRegex={REG_UPPERCASE}
          />
        </div>
        <div className="basis-3/5">
          <PasswordStatusItem
            label={t("public.onboarding.passwordSpecialCharacter")}
            password={password}
            testRegex={REG_NUM_OR_SPECIAL}
          />
        </div>
      </div>
    </div>
  );
};
