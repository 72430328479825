import React from "react";
import { useTranslation } from "react-i18next";

export const LinkSentView = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="text-dark-blue">
        {t("public.onboarding.forgotPwdSentTitle")}
      </h1>
      <p className="text-dark-grey mt-10 leading-6.5">
        {t("public.onboarding.forgotPwdSentTitleHint")}
      </p>
    </div>
  );
};
