import { FieldContainer } from "common/components/Form/FieldContainer";
import { useField } from "formik";
import Select, {
  GroupBase,
  MultiValue,
  SingleValue,
  StylesConfig,
} from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";
export type SelectOption = {
  value: string;
  label: string;
  index?: number;
};

type SelectFieldProps = {
  className?: string;
  name: string;
  label: string;
  options: SelectOption[];
  isMulti?: boolean;
  onAfterChange?: () => void;
  maxHeight?: number;
  isSearchable?: boolean;
  selectStyles?: StylesConfig<SelectOption>;
  closeMenuOnSelect?: boolean;
  backspaceRemovesValue?: boolean;
  defaultValue?: SelectOption;
  formatOptionLabel?: (
    option: SelectOption,
    formatOptionLabelMeta: FormatOptionLabelMeta<SelectOption>
  ) => JSX.Element;
  components?: Partial<
    SelectComponents<SelectOption, boolean, GroupBase<SelectOption>>
  >;
};

export const SelectField = ({
  className,
  name,
  label,
  options,
  isMulti,
  onAfterChange,
  isSearchable,
  maxHeight,
  selectStyles,
  formatOptionLabel,
  closeMenuOnSelect,
  backspaceRemovesValue,
  components,
  defaultValue,
}: SelectFieldProps): JSX.Element => {
  const [, , helpers] = useField(name);

  const handleChange: (
    value: MultiValue<SelectOption> | SingleValue<SelectOption>
  ) => void = isMulti
    ? (options) =>
        options &&
        helpers.setValue(
          (options as MultiValue<SelectOption>).map((it) => it.value)
        )
    : (option) =>
        option &&
        helpers.setValue((option as SingleValue<SelectOption>)?.value);

  return (
    <FieldContainer className={className} name={name} label={label}>
      <Select
        defaultValue={defaultValue}
        id={name}
        options={options}
        onChange={(event) => {
          handleChange(event);
          if (onAfterChange) onAfterChange();
        }}
        isMulti={isMulti}
        styles={selectStyles}
        formatOptionLabel={formatOptionLabel}
        isSearchable={isSearchable}
        maxMenuHeight={maxHeight}
        menuPlacement="auto"
        hideSelectedOptions={false}
        closeMenuOnSelect={closeMenuOnSelect}
        backspaceRemovesValue={backspaceRemovesValue}
        components={components}
      />
    </FieldContainer>
  );
};
