import { BaseBreadCrumbs } from "common/components/Breadcrumbs/BaseBreadcrumbs";
import { Card } from "common/components/Card";
import { useAuth } from "common/hooks";
import { ApplicationAccess } from "features/userSettings/components/applicationAccess/ApplicationAccess";
import { AccountDetails } from "features/userSettings/components/passwordSetting/AccountDetail";
import { PersonalDetails } from "features/userSettings/components/profileSetting/PersonalDetail";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";
import { CurrentUserProfile } from "types/user";

type DividerSize = "sm" | "md" | "lg";

export const Divider = styled.div(({ size }: { size?: DividerSize }) => [
  tw`w-full border-t border-greyscale my-5`,
  size && size === "sm" && tw`my-3`,
]);
export const ItemLabel = styled.label(() => [
  tw`text-h3 leading-7 w-full text-dark-blue`,
]);
export const Wrapper = styled.div(() => [tw`flex flex-row`]);
export const StyledUserSettingCard = styled(Card)`
  ${tw`py-8 justify-between items-center w-full mt-10 bg-white`}
`;

export type UserProfileProps = {
  userProfile: CurrentUserProfile | null;
};

export const UserSettingsView = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentUserProfile } = useAuth();

  return (
    <div className="h-full">
      <h2 className="mb-3">{t("settings.userProfile.title")}</h2>
      <BaseBreadCrumbs itemContainerClassName="hover:text-light-blue">
        <Link to="/">{t("settings.breadcrumbs.home")}</Link>
        <Link to="/settings/user-profile">
          {t("settings.userProfile.title")}
        </Link>
      </BaseBreadCrumbs>

      <StyledUserSettingCard>
        <PersonalDetails userProfile={currentUserProfile} />
      </StyledUserSettingCard>
      <StyledUserSettingCard>
        <AccountDetails userProfile={currentUserProfile} />
      </StyledUserSettingCard>
      <StyledUserSettingCard>
        <ApplicationAccess userProfile={currentUserProfile} />
      </StyledUserSettingCard>
    </div>
  );
};
