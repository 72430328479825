import { CategoriesTab } from "features/applicationDashboard/components/CategoriesTab";
import { Title } from "features/applicationDashboard/components/Title";
import { ApplicationList } from "features/applicationDashboard/pages/dashboard/components/ApplicationList";
import {
  ApplicationInfo,
  Categories,
} from "features/applicationDashboard/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type ExploreOtherApplicationsProps = {
  className?: string;
  data: ApplicationInfo[];
  onClick: (app: ApplicationInfo) => void;
};

export const ExploreOtherApplications = ({
  className,
  data,
  onClick,
}: ExploreOtherApplicationsProps): JSX.Element => {
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState({
    index: 0,
    data: data,
  });

  useEffect(() => {
    setSelectedCategory({
      index: 0,
      data: data,
    });
  }, [data]);

  const handleSelectedTab = (
    selectedIndex: number,
    selectedCategory: string
  ) => {
    setSelectedCategory({
      index: selectedIndex,
      data:
        selectedCategory === Categories.ALL
          ? data
          : data.filter((item) => item.category === selectedCategory),
    });
  };

  return (
    <div
      className={`col-span-full ${className && className}`}
      data-cy="explore-other-applications"
    >
      <Title
        className="w-full mb-8"
        title={t("application.title.exploreOtherApplications")}
      />
      <CategoriesTab
        selectedIndex={selectedCategory.index}
        onClick={handleSelectedTab}
      >
        <ApplicationList
          className="pt-5"
          data={selectedCategory.data}
          onClick={onClick}
        />
      </CategoriesTab>
    </div>
  );
};
