import { Tag } from "common/components/Tag";
import { BackButton } from "features/applicationDashboard/components/BackButton";
import { Slider } from "features/applicationDashboard/components/Slider/Slider";
import { SubscribedButton } from "features/applicationDashboard/components/SubscribedButton";
import { useEnquirePopupHandler } from "features/applicationDashboard/hooks/useEnquirePopupHandler";
import { ApplicationInformation } from "features/applicationDashboard/pages/applicationDetail/components/ApplicationInformation";
import { YouMayAlsoLike } from "features/applicationDashboard/pages/applicationDetail/components/YouMayAlsoLike";
import { useYouMayAlsoLikeData } from "features/applicationDashboard/pages/applicationDetail/hooks/useYouMayAlsoLikeData";
import { ApplicationInfo } from "features/applicationDashboard/types";
import { getTabText } from "features/applicationDashboard/utils/common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";

type ApplicationDetailLayoutProps = {
  className?: string;
  data: ApplicationInfo;
  icon: React.ReactNode;
  previewImages: string[];
  children: React.ReactNode;
};
const StyledCardWrapper = tw.div`h-full col-span-full py-10 bg-white rounded-lg shadow-primary `;

const StyledBackSection = tw.div`col-span-full grid grid-cols-12 gap-x-5 mb-10`;
const StyledTopSection = tw.div`col-span-full grid grid-cols-12 gap-x-5 mb-9`;
const StyledPreviewPicturesSection = tw.div`col-span-full mb-14 px-8`;
const StyledBottomSection = tw.div`col-span-full grid grid-cols-12 gap-x-5`;

const StyledTitle = tw.div`text-h3 text-dark-blue font-medium`;
const StyledDescription = tw.div`text-dark-grey mb-4`;

export const ApplicationDetailLayout = ({
  className,
  data,
  icon,
  previewImages,
  children,
}: ApplicationDetailLayoutProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handler } = useEnquirePopupHandler();
  const { data: youMayAlsoLikeData } = useYouMayAlsoLikeData(data);

  const handleClickEnquireMore = () => {
    handler(data);
  };

  const handleBack = () => {
    navigate("/dashboard");
  };

  return (
    <StyledCardWrapper data-cy="application-detail">
      <div className={className}>
        <StyledBackSection>
          <BackButton className="col-span-2" onClick={handleBack} />
        </StyledBackSection>

        <StyledTopSection>
          <div className="pl-8 flex col-span-8 justify-between gap-x-10">
            <div className="flex-1">{icon}</div>
            <div className="flex flex-col justify-between text-sm gap-4">
              <StyledTitle>{t(data.name)}</StyledTitle>
              <StyledDescription>{t(data.description)}</StyledDescription>
              <Tag>{t(getTabText(data.category))}</Tag>
            </div>
          </div>
          <div className="pr-8 col-start-11 col-span-2 flex justify-center">
            <SubscribedButton
              isSubscribed={data.isSubscribed}
              onClick={handleClickEnquireMore}
            />
          </div>
        </StyledTopSection>

        <StyledPreviewPicturesSection>
          <Slider
            imagesUrl={previewImages}
            slidesPerView={2}
            spaceBetween={40}
            isLoop
            delay={2500}
            isAutoPlay
            isPauseOnMouseEnter
          />
        </StyledPreviewPicturesSection>

        <StyledBottomSection>
          <ApplicationInformation>{children}</ApplicationInformation>
          <YouMayAlsoLike data={youMayAlsoLikeData} />
        </StyledBottomSection>
      </div>
    </StyledCardWrapper>
  );
};
