import { messageToAllValidDomain } from "common/utils/cross-domain/base/postHandler";
import { CHILDREN_DOMAIN } from "common/utils/cross-domain/react/config";
import { LOCAL_STORAGE_KEY } from "common/utils/cross-domain/react/helper";

export const postTokenHandler = async (
  targetDomain: string[] = CHILDREN_DOMAIN
) => {
  return messageToAllValidDomain(
    [
      {
        key: LOCAL_STORAGE_KEY.ACCESS_TOKEN,
        value: localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN),
      },
      {
        key: LOCAL_STORAGE_KEY.REFRESH_TOKEN,
        value: localStorage.getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN),
      },
      {
        key: LOCAL_STORAGE_KEY.TIMEOUT,
        value: localStorage.getItem(LOCAL_STORAGE_KEY.TIMEOUT),
      },
    ],
    targetDomain
  );
};
