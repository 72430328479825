import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Party, PartyMap } from "types/party";

export type PartyState = {
  current: Party | null;
  other: Party[];
};

const initialState: PartyState = {
  current: null,
  other: [],
};

const partySlice = createSlice({
  name: "party",
  initialState,
  reducers: {
    setParties(state, action: PayloadAction<PartyMap>) {
      state.current = action.payload.current;
      state.other = action.payload.other;
    },
    resetParties() {
      return initialState;
    },
  },
});

const { setParties, resetParties } = partySlice.actions;

export { setParties, resetParties };

const partyReducer = partySlice.reducer;
export default partyReducer;
