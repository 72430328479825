import { ContentSection } from "features/public/Register/components/RegisterPoup/ContentSection";
import {
  ContentTitle,
  SectionHeading,
} from "features/public/Register/components/RegisterPoup/RegisterPopup";
import { SectionContent } from "features/public/Register/components/RegisterPoup/SectionContent";

export const Privacy = () => (
  <>
    <div>
      <h1 className="font-bold text-h1 text-light-blue">Privacy Policy</h1>
      <p className="mt-4 text-dark-grey">Last updated date: 4 November 2022</p>
    </div>
    <div className="mt-10">
      <ContentSection index="1.">
        <SectionHeading>ABOUT THIS PRIVACY POLICY</SectionHeading>
        <SectionContent index="1.1">
          This Privacy Policy sets forth the handling practices of CryptoBLK
          Limited, a company incorporated under the laws of Hong Kong with
          company registration number 2529453 and having its own office of
          business at Units 505 – 510, 5/F., 1E Core Building, 1 Science Park
          East Avenue, Hong Kong Science Park, Shatin, New Territories, Hong
          Kong (together with our subsidiaries, our holding company,
          subsidiaries of our holding company from time to time, collectively
          called “CryptoBLK”, “we”, “our” or “us”) in regard to the collection,
          usage and disclosure of personal information or personal information
          that you may provide to us through using this website
          https://kentro.network) and our platform, namely Kentro (collectively
          the “Platform”), or by using any product or service provided by us via
          the Platform (the “Services”).
        </SectionContent>
        <SectionContent index="1.2">
          1.2 CryptoBLK is dedicated to protecting the confidentiality and
          privacy of information entrusted to it. As part of this fundamental
          obligation, CryptoBLK is committed to the appropriate protection and
          use of personal information that has been collected online.
        </SectionContent>
        <SectionContent index="1.3">
          If you do not accept this Privacy Policy and/or do not meet and/or
          comply with the provisions set forth herein, then you should not use
          our Platform.
        </SectionContent>
      </ContentSection>

      <ContentSection index="2.">
        <SectionHeading>TYPES OF INFORMATION WE COLLECT</SectionHeading>
        <div>
          <SectionContent index="2.1">
            The following provides examples of the type of information that we
            collect from you and how we use that information.
          </SectionContent>
          <div className="grid grid-cols-3 gap-x-10">
            <ContentTitle>Context</ContentTitle>
            <ContentTitle>Types of data</ContentTitle>
            <ContentTitle>
              Primary purpose for collection and use of data
            </ContentTitle>

            <SectionContent>Client user account information</SectionContent>
            <SectionContent>
              We collect the full name , company, job title, department
              information and contact information (including but not limited to
              physical address for billing, email address and telephone numbers)
              of our clients and their employees with whom we may interact.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in contacting our clients and
              communicating with them concerning normal business administration
              such as projects, services, and billing.
            </SectionContent>

            <SectionContent>Client user account information</SectionContent>
            <SectionContent>
              We collect personal information from our clients when they create
              an account to access and use the Services or request certain free
              Services from our Platform. This information could include
              business contact information such as name, email address, title,
              company information, industry, and password for our Services.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in providing account related
              functionalities to our users, monitoring account log-ins, and
              detecting potential fraudulent logins or account misuse.
              Additionally, we use this information to fulfill our contract to
              provide you with Services.
            </SectionContent>

            <SectionContent>Contact information of vendors</SectionContent>
            <SectionContent>
              Users of our Services may ask their vendors or service providers
              to submit company and security related information on our Platform
              (e.g., to complete a security questionnaire). When a user invites
              a vendor we collect the name and email address of the vendor.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in contacting vendors on behalf of
              our clients in order to invite them to communicate with companies
              through our Platform. Among other things, the communication allows
              our clients to efficiently solicit, and receive, security
              questionnaires, and allows vendors to efficiently solicit, and
              transmit, security questionnaires. Additionally, we use this
              information to fulfill our contract to provide Services which may
              include soliciting, receiving, transmitting, and hosting responses
              to security questions.
            </SectionContent>

            <SectionContent>Account information of vendors</SectionContent>
            <SectionContent>
              We collect personal information from vendors when they create an
              account to access and use the Services or request certain free
              Services from our Platform. This information could include
              business contact information such as name, email address, title,
              company information, and password for our Services.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in providing account related
              functionalities to our vendor-users, monitoring account log-ins,
              and detecting potential fraudulent logins or account misuse.
              Additionally, in some cases, we use this information to fulfill
              our contract to provide vendor-users with Services.
            </SectionContent>

            <SectionContent>Cookies and first party tracking</SectionContent>
            <SectionContent>
              We use cookies and clear GIFs. “Cookies” are small pieces of
              information that a website sends to a computer’s hard drive while
              a web site is viewed. See our Cookies Policy below for further
              information.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in making our Platform operate
              efficiently.
            </SectionContent>

            <SectionContent>Cookies and first party tracking</SectionContent>
            <SectionContent>
              We participate in behavior-based advertising, this means that a
              third party uses technology (e.g., a cookie) to collect
              information about your use of our Platform so that they can
              provide advertising about products and services tailored to your
              interests on our Platform, or on other websites. See our Cookies
              Policy below for more information.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in understanding our users and
              providing tailored services.
            </SectionContent>

            <SectionContent>Demographic information</SectionContent>
            <SectionContent>
              We use IP information to 1). ensure the legality of our documents
              (under eSignature law); 2). understand how user behavior varies in
              different locations in order to improve our Services; 3.)
              depending on location, provide a better support and success
              service.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in ensuring that our Services is
              legal and providing tailored services based on the location
              (Country) – such as appropriate 1) support, 2) contract content,
              and 3) templates. IP information will not be used for other
              purposes absent explicit consent.
            </SectionContent>

            <SectionContent>Email interconnectivity</SectionContent>
            <SectionContent>
              If you receive email from us, we use certain tools to capture data
              related to when you open our message, click on any links or
              banners it contains and make purchases.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in understanding how you interact
              with our communications to you. Such data capture will only be
              deployed following receipt of explicit consent.
            </SectionContent>

            <SectionContent>Feedback/Support</SectionContent>
            <SectionContent>
              We collect personal information from you contained in any inquiry
              you submit to us regarding our Platform or Services, such as
              completing our online forms, calling, or emailing for the purposes
              of general inquiries, support requests, or to report an issue.
              When you communicate with us over the phone, your calls may be
              recorded and analyzed for training, quality control and for sales
              and marketing purposes. During such calls we will notify you of
              the recording via either voice prompt or script.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in receiving, and acting upon, your
              feedback, issues, or inquiries.
            </SectionContent>

            <SectionContent>Mailing list</SectionContent>
            <SectionContent>
              When you sign up for one of our mailing lists, we collect your
              email address.
            </SectionContent>
            <SectionContent>
              We share information about our products and services with
              individuals that consent to receive such information. We also have
              a legitimate interest in sharing information about our products or
              services.
            </SectionContent>

            <SectionContent>Marketing data</SectionContent>
            <SectionContent>
              When you subscribe to one of our mailing list(s), we collect your
              email address.
            </SectionContent>
            <SectionContent>
              We share information about our products and services with
              individuals that consent to receive such information. We also have
              a legitimate interest in sharing information about our products or
              services.
            </SectionContent>

            <SectionContent>Mobile device data</SectionContent>
            <SectionContent>
              We collect information from your mobile device when visiting our
              Platform. Such information may include operating system type
              and/or mobile device model, browser type, domain, and other system
              settings, the language your system uses and the country and time
              zone of your device, geo-location, unique device identifier and/or
              other device identifier, mobile phone carrier identification, and
              device software platform and firmware information.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in identifying unique visitors, and
              in understanding how users interact with us on their mobile
              devices.
            </SectionContent>

            <SectionContent>Financial Data</SectionContent>
            <SectionContent>
              Subsequent to Service enrollment (where we collect name, email and
              phone number, job role, company name and size), to place an order,
              we collect payment information for example, your billing address,
              bank account and credit card details.
            </SectionContent>
            <SectionContent>
              We use your information to perform our contract to provide you
              with products or services.
            </SectionContent>

            <SectionContent>Surveys</SectionContent>
            <SectionContent>
              When you participate in a survey we collect information that you
              provide through the survey. If the survey is provided by a third
              party service provider, the third party’s privacy policy applies
              to the collection, use, and disclosure of your information.
              Participation in any such surveys is completely voluntary and you
              therefore have a choice whether to disclose such information.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in understanding your opinions, and
              collecting information relevant to our organization.
            </SectionContent>

            <SectionContent>Platform interactions</SectionContent>
            <SectionContent>
              We use technology to monitor how you interact with our Platform.
              This may include which links you click on, or information that you
              type into our online forms. This may also include information
              about your device or browser.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in understanding how you interact
              with our Platform to better improve it, and to understand your
              preferences and interests in order to select offerings that you
              might find most useful. We also have a legitimate interest in
              detecting and preventing fraud.
            </SectionContent>

            <SectionContent>Web logs</SectionContent>
            <SectionContent>
              We collect information, including your browser type, operating
              system, Internet Protocol (IP) address (a number that is
              automatically assigned to a computer when the Internet is used),
              domain name, click-activity, referring website, and/or a date/time
              stamp for visitors.
            </SectionContent>
            <SectionContent>
              We have a legitimate interest in monitoring our networks and the
              visitors to our Platform. Among other things, it helps us
              understand which of our services is the most popular.
            </SectionContent>
          </div>
        </div>
        <SectionContent index="2.2">
          We collect the above information when you provide it to us or when you
          use or visit our Platform. We may also receive information about you
          from other sources, including:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              our personnel, agents, advisors, consultants, and contractors in
              connection with our operations or Services;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              other users of our Services;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              other services linked to your account, for example if you register
              or log in your account using your Google credentials, we receive
              your name and email address to authenticate you, as permitted by
              your Google profile settings;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(d)">
              our group companies or overseas offices that provide information
              technology services, system administrative services, and marketing
              services; and
            </SectionContent>
            <SectionContent className="mb-[0]" index="(e)">
              our business className="mb-4" partners and service providers who
              provide technical, payment, delivery services, advertising
              networks, analytics, market research, and search information
              services.
            </SectionContent>
          </div>
        </SectionContent>
      </ContentSection>

      <ContentSection index="3.">
        <SectionHeading>
          USE AND PROCESSING OF PERSONAL INFORMATION
        </SectionHeading>
        <SectionContent index="3.1">
          We only use your personal information where the law allows us to. We
          use your personal information only where:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              we need to perform the contract we have entered into (or are about
              to enter into) with you, including to operate and provide our
              Services, to provide customer support and personalised features,
              and to protect the safety and security of our Platform;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              it satisfies a legitimate interest which is not overridden by your
              fundamental rights or data protection interests, for example for
              research and development, and in order to protect our legal rights
              and interests;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              you have given us consent to do so for a specific purpose, for
              example we may send you direct marketing materials or publish your
              information as part of our testimonials or customer stories to
              promote our products or services with your permission;
            </SectionContent>
            <SectionContent className="mb-[0]" index="(d)">
              we need to comply with a legal or regulatory obligation;
            </SectionContent>
          </div>
        </SectionContent>
        <SectionContent index="3.2">
          Although the sections above describe our primary purpose in collecting
          your information, in many situations we have more than one purpose. In
          addition to the purposes and uses described above, we use information
          in the following ways:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              to identify you when you visit our Platform.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              to conduct analytics.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              to respond to inquiries related to support, employment
              opportunities, or other requests; or
            </SectionContent>
            <SectionContent className="mb-[0]" index="(d)">
              for internal administrative purposes, as well as to manage our
              relationships.
            </SectionContent>
          </div>
        </SectionContent>
      </ContentSection>

      <ContentSection index="4.">
        <SectionHeading>SHARING OF INFORMATION</SectionHeading>
        <SectionContent index="4.1">
          In addition to the specific situations discussed elsewhere in this
          Privacy Policy, we may share personal information in the following
          situations:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              Affiliates and acquisitions. We may share information with our
              corporate affiliates (e.g., parent company, sister companies,
              subsidiaries, joint ventures, or other companies under common
              control). If another company acquires, or plans to acquire, our
              company, business, or our assets, we will also share information
              with that company, including at the negotiation stage.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              Other disclosures with your consent. We may ask if you would like
              us to share your information with other unaffiliated third parties
              who are not described elsewhere in this this Privacy Policy.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              Other disclosures without your consent. We may disclose
              information in response to subpoenas, warrants, or court orders,
              or in connection with any legal process, or to comply with
              relevant laws. We may also share your information in order to
              establish or exercise our rights, to defend against a legal claim,
              to investigate, prevent, or take action regarding possible illegal
              activities, suspected fraud, safety of person or property, or a
              violation of our policies, or to comply with your request for the
              shipment of products to or the provision of services by a
              third-party intermediary. We may share personal information on
              aggregated or de-identified basis with third parties for research
              and analysis, profiling, and similar purposes to help us improve
              our products and services.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(d)">
              Public. The Platform may provide the opportunity to post comments,
              or reviews, in a public forum. If you decide to submit information
              on these pages, that information may be publicly available.
            </SectionContent>
            <SectionContent className="mb-[0]" index="(e)">
              Service providers. We share your information with our service
              providers. Among other things service providers help us to
              administer our Platform, send e-mail communications, conduct
              surveys, provide technical support, detect fraud, process
              payments, and assist in the fulfillment of orders. Our service
              providers will be given access to your personal information as is
              reasonably necessary to provide the Platform and related Services.
              Our service providers are contractually obligated to use your
              personal information only at our direction and in accordance with
              our Privacy Policy; to handle your personal information in
              confidence; and to not disclose your personal information to
              unauthorized third parties. Service providers who violate these
              obligations are subject to appropriate discipline including, but
              not limited to, termination as a service provider.
            </SectionContent>
          </div>
        </SectionContent>
        <SectionContent index="4.2">
          Except as otherwise stated in this Privacy Policy, we do not sell,
          trade, rent or otherwise share for marketing purposes your Personal
          information with third parties without your consent.
        </SectionContent>
      </ContentSection>

      <ContentSection index="5.">
        <SectionHeading>SHARING OF INFORMATION</SectionHeading>
        <SectionContent index="5.1">
          We only retain personal information for so long as it is reasonably
          necessary to fulfil the purposes we collected it for, including for
          the purposes of satisfying any legal, accounting, or reporting
          requirements. After such time, we will delete or anonymise your
          information, or if this is not possible, we will securely store your
          information and isolate it from further use. We periodically review
          the basis and appropriateness of our data retention policy.
        </SectionContent>
      </ContentSection>

      <ContentSection index="6.">
        <SectionHeading>SHARING OF INFORMATION</SectionHeading>
        <SectionContent index="6.1">
          We implement security measures designed to protect your personal
          information from unauthorized access. We apply these tools based on
          the sensitivity of the personal information we collect, use, and
          store, and the current state of technology. We protect your personal
          information through technical and organizational security measures to
          minimize risks associated with data loss, misuse, unauthorized access,
          and unauthorize disclosure and alteration. We periodically review our
          information collection, storage and processing practices, including
          technical and organizational measures, to guard against unauthorized
          access to systems. Your account is protected by your account password
          and we urge you to take steps to keep your personal information safe
          by not disclosing your password and by logging out of your account
          after each use.
        </SectionContent>
        <SectionContent index="6.2">
          While we implement safeguards designed to protect your information,
          please note that no transmission of information on the Internet is
          completely secure. We cannot guarantee that your information, during
          transmission through the Internet or while stored on our systems or
          processed by us, is absolutely safe and secure.
        </SectionContent>
        <SectionContent index="6.3">
          We have put in place procedures to deal with any suspected privacy
          breach and will notify you and any applicable regulator of a breach
          where we are legally required to do so.
        </SectionContent>
        <SectionContent index="6.4">
          By using our Platform, you acknowledge that you understand and agree
          to assume these risks. personal information
        </SectionContent>
      </ContentSection>

      <ContentSection index="7.">
        <SectionHeading>
          HOW WE STORE AND TRANSFER INFORMATION INTERNATIONALLY
        </SectionHeading>
        <SectionContent index="7.1">
          We collect information globally and primarily store that information
          online at a cloud solution provided by our service provider. We may
          also from time to time transfer, process, and store your information
          in other server in or outside Hong Kong.
        </SectionContent>
        <SectionContent index="7.2">
          Some of the countries in which our company or service providers are
          located may not have the privacy and data protection laws that are
          equivalent to those in your country of residence. When we share
          information with these companies or service providers, we make use of
          contractual clauses, corporate rules, and other appropriate mechanisms
          to safeguard the transfer of information.
        </SectionContent>
        <SectionContent index="7.3">
          While we take reasonable measure to ensure that service providers keep
          your information confidential and secure, such service provider’s
          practices are ultimately beyond our control.
        </SectionContent>
      </ContentSection>

      <ContentSection index="8.">
        <SectionHeading>YOUR PRIVACY RIGHTS</SectionHeading>
        <SectionContent index="8.1">
          Applicable data protection legislation may give you as the data
          subject, various rights in relation to your personal information that
          we hold and process, which generally include:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              Right of access - the right to obtain from us confirmation as to
              whether or not personal information concerning you is being
              processed, and, where that is the case, access to that personal
              information and various other information, including the purpose
              for the processing, with whom the data is shared, how long the
              data will be retained, and the existence of various other rights.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              Right to rectification - the right to obtain from us, without
              undue delay, the putting right of inaccurate personal information
              concerning you.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              Right to erasure - sometimes referred to as the ‘right to be
              forgotten’, this is the right for you to request that, in certain
              circumstances, we delete data relating to you.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(d)">
              Right to restrict processing - the right to request that, in
              certain circumstances, we restrict the processing of your data.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(e)">
              Right to data portability - the right, in certain circumstances,
              to receive that personal information which you have provided to us
              in a structured, commonly used and machine-readable format, and
              the right to have that personal information transmitted to another
              controller.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(f)">
              Right to object - the right, in certain circumstances, to object
              to personal information being processed by us where it is in
              relation to direct marketing, or in relation to processing
              supported by the argument of legitimate interest.
            </SectionContent>
            <SectionContent className="mb-[0]" index="(g)">
              Right not to be subject to automated decision making - the right
              not to be subject to a decision based solely on automated
              processing, including profiling, which produces legal effects
              concerning you or similarly significantly affects you.
            </SectionContent>
          </div>
        </SectionContent>
        <SectionContent index="8.2">
          Applicable data protection legislation may give you as the data
          subject, various rights in relation to your personal information that
          we hold and process, which generally include:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              contacting us using any medium you wish, including in writing, by
              telephone, by text, electronically, or using such social media as
              we employ for communication purposes;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              by completing a form which we can supply to you for this purpose;
              or
            </SectionContent>
            <SectionContent className="mb-[0]" index="(c)">
              through a third-party whom you have authorised for this purpose.
            </SectionContent>
          </div>
        </SectionContent>
        <SectionContent index="8.3">
          Any request under paragraph 8.1 will normally be addressed free of
          charge. However, we may charge a reasonable administration fee if your
          request is clearly unfounded, repetitive, or excessive.
        </SectionContent>
        <SectionContent index="8.4">
          Please note that your rights are not absolute, meaning that in some
          circumstances, exceptions exist under applicable law. The law may
          provide exemptions from requests involving your personal information.
        </SectionContent>
      </ContentSection>

      <ContentSection index="9.">
        <SectionHeading>MARKETING</SectionHeading>
        <SectionContent index="9.1">
          We may also wish to provide you with information about special
          features of our Platform and Services or any other service or products
          we think may be of interest to you. If you would rather not receive
          this information, please send an email to [product@cryptoblk.io].
        </SectionContent>
        <SectionContent index="9.2">
          We may also want to provide you with related information from third
          parties we think may be of interest to you. If you would rather not
          receive this information, please send an email to
          [product@cryptoblk.io].
        </SectionContent>
        <SectionContent index="9.3">
          You may opt out of receiving marketing materials or newspaper from us
          by using the unsubscribe link in our communications, by updating your
          preferences within your account on our Platform, or by send an email
          to [product@cryptoblk.io]. Please note, however, that even if you opt
          out from receiving marketing materials from us, you will continue to
          receive notifications or information from us that are necessary for
          the use of our Platform and Services.
        </SectionContent>
      </ContentSection>

      <ContentSection index="10.">
        <SectionHeading>COMPLAINT</SectionHeading>
        <SectionContent index="10.1">
          We are committed to resolving valid complaints about your privacy and
          our collection or use of your personal information. For questions or
          complaints regarding our data use practices or this Privacy Policy,
          please contact us as provided below. Should you remain unsatisfied
          with our response to your complaint, you have the right to contact
          your local data protection authority.
        </SectionContent>
      </ContentSection>

      <ContentSection index="11.">
        <SectionHeading>CHILDREN AND MINORS</SectionHeading>
        <SectionContent index="11.1">
          CryptoBLK does not knowingly collect personal information from
          children under the age of thirteen (13). If we learn that we have
          collected personal information from a child under age thirteen (13),
          we will delete such information as quickly as possible. If you believe
          that a child under the age of thirteen (13) may have provided us
          personal information, please contact us at: [product@cryptoblk.io] .
          By using the Platform, you represent that you are at least eighteen
          (18) years old and understand that you must be at least eighteen (18)
          years old in order to create an account and/or purchase and/or use the
          Services through the Platform.
        </SectionContent>
      </ContentSection>

      <ContentSection index="12.">
        <SectionHeading>THIRD PARTY WEBSITES AND SERVICES</SectionHeading>
        <SectionContent index="12.1">
          We have no control over the privacy practices of websites or
          applications that we do not own. We are not responsible for the
          practices employed by any websites and/or services linked to and/or
          from our Platform, including the information and/or content contained
          therein. Please remember that when you use a link to go from our
          Platform to another website and/or service, our Privacy Policy does
          not apply to such third-party websites and/or services. Your browsing
          and interaction on any third-party website and/or service, including
          those that have a link on our Platform, are subject to such
          third-party’s own rules and policies. In addition, you agree that we
          are not responsible and do not have control over any third-parties
          that you authorize to access your personal information. If you are
          using a third-party website and/or service and you allow them to
          access your personal information, you do so at your own risk.
        </SectionContent>
      </ContentSection>

      <ContentSection index="13.">
        <SectionHeading>CHANGES TO OUR PRIVACY POLICY</SectionHeading>
        <SectionContent index="13.1">
          In general, changes will be made to this Privacy Policy to address new
          or modified laws and/or new or modified business procedures. However,
          we may update this Privacy Policy at any time, with or without advance
          notice, so please review it periodically. We may provide you
          additional forms of notice of modifications and/or updates as
          appropriate under the circumstances. Your continued use of the
          Platform after any modification to this Privacy Policy will constitute
          your acceptance of such modifications and/or updates. You can
          determine when this Privacy Policy was last revised by referring to
          the date it was last “Updated” above.
        </SectionContent>
      </ContentSection>

      <ContentSection index="14.">
        <SectionHeading>CONTACTING US</SectionHeading>
        <SectionContent index="14.1">
          For questions or complaints regarding our use of your personal
          information or Privacy Policy or to forward deletion requests, please
          contact us at: [product@cryptoblk.io].
        </SectionContent>
      </ContentSection>

      <ContentSection index="15.">
        <SectionHeading>COOKIES POLICY</SectionHeading>
        <SectionContent index="15.1">
          We use cookies in connection with the operation of our Platform. A
          cookie is a small file that is sent by a web server (where we host our
          Platform) to a web browser (from where you view our Platform) and
          which is then stored by the browser. The cookie contains an identifier
          which is stored in your browser and then sent back to our server each
          time your browser accesses our Platform. These cookies may either be
          ‘persistent cookies’ (in which case they will continue to be held by
          your browser until they are deleted, or until a specified event/date)
          or they will be ‘session cookies’ which expire when you close your
          browser.
        </SectionContent>
        <SectionContent index="15.2">
          Although most browsers automatically accept cookies, you can choose
          whether or not to accept cookies via your browser's settings. You may
          also delete cookies from your device at any time. However, please be
          aware that if you do not accept cookies, you may not be able to fully
          experience some of the Platform’s features.
        </SectionContent>
        <SectionContent index="15.3">
          The cookies that we use fall into following groups:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              Essential: these cookies are necessary for the operation of our
              Platform, or essential to the provision of the Services. These
              include, for example, cookies that allow you, as a subscriber to
              our Services, to stay logged in to your account and to access
              information, sections of our Platform, or Services not generally
              available to non-subscribers. If you block these cookies you will
              not be able to access the Services (which you may have paid for).
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              Performance: these cookies measure how often you visit our
              Platform and how you use them. They also allow us to calculate the
              number of visitors to our Platform, see what those visitors do on
              our Platform, how long they spend on the Platform, and which links
              they click. These cookies help us to improve how our Platform
              works, and enable us to make changes so that users stay longer or
              return more often. If you block these cookies, we will not be able
              to improve our Services as easily.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              Targeting: similar to performance cookies, these cookies tell us
              which pages you visit, and the links you follow, so that we can
              make our Platform more relevant to your interests. If you block
              these cookies, we will be unable to improve the experience our
              users get from our Platform.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(d)">
              Functionality: these cookies make sure that you and your
              preferences are remembered when you return to our Platform. That
              means that you can store data that you wish to return to, complete
              logs as to what you have seen/used on the Platform, and generally
              allow us to provide you with a more personalised experience. If
              you block these cookies you may have reduced functionality, or not
              be able to store personal preferences.
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(e)">
              Advertising: these collect information about your visit to our
              Platform, such as what you have looked at and the links you have
              followed, so that we can target our advertising at you, or exclude
              certain types of advertising that would not interest you. We can
              also gather information about your browser, device and your IP
              address. If you block these cookies, you may receive advertising
              that is not relevant to you.
            </SectionContent>
            <SectionContent className="mb-[0]" index="(f)">
              Third party cookies: these are used where we use third-party
              applications on our Platform to customise the Services, or to
              provide an increased functionality. They may relate to the use of
              social media, or the use of another complementary service. If you
              block these cookies you may not be able to access the Services
              that you want to use.
            </SectionContent>
          </div>
        </SectionContent>
      </ContentSection>
    </div>
  </>
);
