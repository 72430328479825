import { ApplicationDescription } from "features/applicationDashboard/components/ApplicationDescription";
import { SubscribedButton } from "features/applicationDashboard/components/SubscribedButton";
import { useEnquirePopupHandler } from "features/applicationDashboard/hooks/useEnquirePopupHandler";
import { ApplicationInfo } from "features/applicationDashboard/types";
import { getTabText } from "features/applicationDashboard/utils/common";
import { useTranslation } from "react-i18next";

type ApplicationListItemProps = {
  information: ApplicationInfo;
  onClick: () => void;
  className?: string;
};

export const ApplicationListItem = ({
  information,
  onClick,
  className,
}: ApplicationListItemProps): JSX.Element => {
  const { t } = useTranslation();
  const { handler } = useEnquirePopupHandler();

  const handleClickEnquireMore = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    handler(information);
  };

  return (
    <div
      className={`grid grid-cols-10 gap-x-5 cursor-pointer ${
        className && className
      }`}
      onClick={onClick}
    >
      <ApplicationDescription
        className="col-span-8 pr-5"
        icon={information.icon}
        title={t(information.name)}
        description={t(information.description)}
        tag={t(getTabText(information.category))}
      />
      <div className="col-span-2 justify-self-end self-center">
        <SubscribedButton
          isSubscribed={information.isSubscribed}
          onClick={(e) => handleClickEnquireMore(e)}
        />
      </div>
    </div>
  );
};
