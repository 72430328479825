import { ReactComponent as SvgChevronDown } from "assets/svg/chevron-down.svg";
import { renderHorizontalIconByApplicationName } from "features/applicationDashboard/components/ApplicationLabelWithIcon";
import {
  ApplicationDisplayName,
  ApplicationName,
} from "features/applicationDashboard/types";
import { Divider } from "features/userSettings/components/UserSettingsView";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ApplicationDisplayMap: Record<ApplicationName, ApplicationDisplayName> = {
  "Kentro Sign": ApplicationDisplayName.KentroSign,
  "ATLAS ELITE": ApplicationDisplayName.AtlasElite,
  MIDAS: ApplicationDisplayName.Midas,
  TOPAZ: ApplicationDisplayName.Topaz,
};

type ApplicationAccessItemProps = {
  app: ApplicationName;
  company?: string | null;
  className?: string;
};

export const ApplicationAccessItem = ({
  app,
  company,
  className,
}: ApplicationAccessItemProps): JSX.Element => {
  const { t } = useTranslation();
  const [isDetailShow, setDetailShow] = useState(false);
  return (
    <div className={className} onClick={() => setDetailShow((prev) => !prev)}>
      <div className="flex items-center justify-between cursor-pointer">
        <div>
          {renderHorizontalIconByApplicationName(ApplicationDisplayMap[app])}
        </div>
        <SvgChevronDown />
      </div>
      {isDetailShow && (
        <div>
          {/* TODO: update more field in the future */}
          <Divider size="sm" />
          <div className="font-medium text-dark-blue">
            <span className="text-xs text-dark-grey">
              {t("settings.applicationsAccess.companyGroup")}
            </span>
            <div>{company || "-"}</div>
          </div>
          <Divider size="sm" />
        </div>
      )}
    </div>
  );
};
