import "validationLocale";
import "i18n";

import { ModalProvider } from "@liholiho/react-modal-hook";
import App from "App";
import { AuthErrorContextProvider } from "common/context/AuthErrorContext";
import { VersionContextProvider } from "common/context/VersionContext";
import { WithRequest } from "common/HOC/WithRequest";
import { ChildrenIFrame } from "common/utils/cross-domain/react/ChildrenIFrame";
import { CHILDREN_DOMAIN } from "common/utils/cross-domain/react/config";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import { store } from "redux/store";
import reportWebVitals from "reportWebVitals";
import { SWRConfig } from "swr";

import "index.scss";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <VersionContextProvider>
      <AuthErrorContextProvider>
        <ModalProvider rootComponent={TransitionGroup}>
          <Provider store={store}>
            <SWRConfig
              value={{
                revalidateOnFocus: false,
              }}
            >
              <BrowserRouter>
                <Suspense>
                  <WithRequest>
                    <ChildrenIFrame childrenDomain={CHILDREN_DOMAIN} />
                    <App />
                  </WithRequest>
                </Suspense>
              </BrowserRouter>
            </SWRConfig>
          </Provider>
        </ModalProvider>
      </AuthErrorContextProvider>
    </VersionContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// expose store when running in Cypress
if ((window as any).Cypress) {
  (window as any).store = store;
}
