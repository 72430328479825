import { ReactComponent as SvgDot } from "assets/svg/dot.svg";
import { ReactNode } from "react";

type StatusItemProps = {
  className?: string;
  iconSize?: string | number;
  spacing?: "md" | "sm";
  isDotIconShow?: boolean;
  children?: ReactNode;
};

export const StatusItem = ({
  className,
  children,
  iconSize = 8,
  spacing,
  isDotIconShow,
}: StatusItemProps) => {
  return (
    <span
      className={"flex items-center justify-start " + className}
      data-cy="password-status"
    >
      {isDotIconShow && (
        <SvgDot
          className={`${spacing === "sm" ? "mr-1.5" : "mr-3"} shrink-0`}
          width={iconSize}
          height={iconSize}
          role="img"
        />
      )}
      {children}
    </span>
  );
};
