import { request } from "common/HOC/WithRequest";
import { UUID } from "types/common";

export type App = {
  id: UUID;
  appName: string;
};

export type AppInfo = {
  app: App;
  enabled: boolean;
};

export type SubscriptionEnquiryPayload = {
  userCompanyName: string | null;
  userCompanyTitle: string | null;
  applicationIds: UUID[];
};

const dashboardPrefix = "dashboard";
export const applicationAPI = {
  applicationDetail: (applicationId: UUID) =>
    `${dashboardPrefix}/apps/${applicationId}`,
  getApplicationDetail: (applicationId: UUID) =>
    request<AppInfo>(`${dashboardPrefix}/apps/${applicationId}`, "GET"),
  enquiry: (enquiryBody: SubscriptionEnquiryPayload) =>
    request(`${dashboardPrefix}/enquiry`, "POST", enquiryBody),
};
