import { useModal } from "@liholiho/react-modal-hook";
import { LoadingModal } from "features/public/ForgotPassword/components/LoadingModal";

type ShowModal = () => void;
type HideModal = () => void;
export const useLoadingModal = (): [ShowModal, HideModal] => {
  const [showModal, hideModal] = useModal(({ in: isOpen }) => {
    return <LoadingModal isOpen={isOpen} />;
  });
  return [showModal, hideModal];
};
