import { ReactComponent as SvgCheckCircle } from "assets/svg/check-circle.svg";
import { ReactComponent as SvgCrossCircle } from "assets/svg/cross-circle.svg";
import { ReactComponent as SvgWarning } from "assets/svg/warning.svg";
import classNames from "classnames";
import React, { FunctionComponent, SVGProps } from "react";

type MessageProps = {
  variant: MessageType;
  children: React.ReactNode;
  className?: string;
  isBackgroundEnable?: boolean;
  isIconShow?: boolean;
};

type MessageType = "warning" | "success" | "error";
const MessageIconMap: Record<
  MessageType,
  FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>
> = {
  warning: SvgWarning,
  success: SvgCheckCircle,
  error: SvgCrossCircle,
};

const MessageColorMap: Record<MessageType, string> = {
  warning: "text-light-red",
  success: "text-light-green",
  error: "text-light-red",
};

const BackgroundColorMap: Record<MessageType, string> = {
  warning: "bg-error-red/10",
  success: "bg-green/10",
  error: "bg-error-red/10",
};

export const Message = ({
  children,
  variant,
  className,
  isBackgroundEnable = true,
  isIconShow = true,
}: MessageProps): JSX.Element => {
  const backgroundColor = isBackgroundEnable
    ? `${BackgroundColorMap[variant]} h-[48px] px-6`
    : "bg-transparent";

  const Icon = MessageIconMap[variant];

  const classStr = classNames(
    `flex items-center space-x-2`,
    MessageColorMap[variant],
    backgroundColor,
    className
  );

  return (
    <div className={classStr}>
      {isIconShow && (
        <div className="shrink-0">
          <Icon width={20} height={20} />
        </div>
      )}

      <div className="font-medium" data-cy="message-error">
        {children}
      </div>
    </div>
  );
};
