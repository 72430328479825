import { ReactNode } from "react";

export const ContentSection = ({
  children,
  index,
}: {
  children: ReactNode;
  index: string;
}) => (
  <div className="mb-5 flex">
    {!!index && (
      <span className="mr-5 ml-3 font-bold text-[18px] uppercase text-dark-blue">
        {index}
      </span>
    )}
    <div className="flex-1">{children}</div>
  </div>
);
