import { publicUserAPI } from "api/publicUser";
import { Container, ContentWrapper } from "features/PublicRoutes";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ResetPasswordLayout = ({
  children,
  setToken,
}: {
  children: React.ReactNode;
  setToken: Dispatch<SetStateAction<string | null>>;
}): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("resetToken");
  // Validate token
  useEffect(() => {
    if (token) {
      setToken(token);
      publicUserAPI.validateToken(token).catch((error) => {
        switch (error.errors[0]?.key) {
          case "token.not.found":
            setToken(null);
            navigate("/public/reset-password/link-invalid");
            break;
          case "token.expired":
            navigate("/public/reset-password/link-invalid");
            break;
          default:
            break;
        }
      });
    }
  }, [navigate, setToken, token]);
  return (
    <ContentWrapper>
      <Container>
        <div className="col-start-2 col-end-6 flex items-center">
          {children}
        </div>
      </Container>
    </ContentWrapper>
  );
};
