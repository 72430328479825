export const REG_MINIMUM = new RegExp(/.{8,}$/);
export const REG_LOWERCASE = new RegExp(/(?=.*?[a-z])/);
export const REG_UPPERCASE = new RegExp(/(?=.*?[A-Z])/);
export const REG_NUM_OR_SPECIAL = new RegExp(
  /(?=.*?[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~0-9])/
);
export const REG_PASSWORD_VALIDATOR = new RegExp(
  REG_LOWERCASE.source +
    REG_UPPERCASE.source +
    REG_NUM_OR_SPECIAL.source +
    REG_MINIMUM.source
);
