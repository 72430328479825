import { receiveMessageHandler } from "common/utils/cross-domain/base/receiveHandler";
import { ORIGIN_URL } from "common/utils/cross-domain/base/utils";
import { CrossDomainMessageType } from "common/utils/cross-domain/react/helper";
import { v4 as uuidv4 } from "uuid";

export const crossDomainPostMessage = <T>(
  iframe: HTMLIFrameElement,
  destination: ORIGIN_URL,
  value: T
): Promise<void> => {
  const eventId = uuidv4();

  if (destination === "*") {
    throw new Error("INVALID_TARGET_DOMAIN");
  } else {
    // TODO add test to check what postMessage receive
    iframe.contentWindow?.postMessage(
      { value, id: eventId, action: CrossDomainMessageType.MESSAGE },
      destination
    );
  }

  // wrap postMessage into Promise
  return new Promise<void>((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(`TIME_OUT_ERROR`);
      window.removeEventListener("message", respListener, false);
    }, 1000 * 15);

    const respListener = (resp: MessageEvent) => {
      receiveMessageHandler(
        resp,
        (id, action, content, eventOrigin) => {
          if (
            eventId === id &&
            action === CrossDomainMessageType.RESPONSE &&
            eventOrigin === destination
          ) {
            resolve();
            window.removeEventListener("message", respListener, false);
            clearTimeout(timer);
          }
        },
        [destination]
      );
    };

    window.addEventListener("message", respListener, false);
  });
};

export const messageToAllValidDomain = async <T>(
  messageValue: T,
  childrenDomain: ORIGIN_URL[]
) => {
  // reading async/await in loop https://stackoverflow.com/questions/37576685/using-async-await-with-a-foreach-loop
  return Promise.all(
    childrenDomain.map(async (domain) => {
      const targetWindow = document.getElementById(domain) as HTMLIFrameElement;
      if (targetWindow) {
        await crossDomainPostMessage(targetWindow, domain, messageValue);
      } else {
        throw new Error(`INVALID_IFRAME_CONFIG`);
      }
    })
  );
};
