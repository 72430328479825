import {
  EMAIL_FORMAT_INCORRECT_ERROR,
  FIELD_IS_REQUIRED_ERROR,
} from "constants/commonValidatorError";
import { REG_EMAIL_VALIDATOR } from "constants/emailValidatorKey";

import { publicUserAPI } from "api/publicUser";
import { ReactComponent as SvgWarning } from "assets/svg/warning.svg";
import { Button } from "common/components/Button";
import { InputField } from "common/components/Form/InputField";
import { useLoadingModal } from "features/public/ForgotPassword/hooks/useLoadingModal";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

type ForgotPasswordForm = {
  email: string;
};

export const ForgotPasswordView = () => {
  const { t } = useTranslation();
  const schema: yup.SchemaOf<ForgotPasswordForm> = yup.object().shape({
    email: yup
      .string()
      .trim()
      .required()
      .test("email-validate", t(EMAIL_FORMAT_INCORRECT_ERROR), (value) => {
        return REG_EMAIL_VALIDATOR.test(String(value));
      }),
  });
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const handleSubmit = async (value: ForgotPasswordForm) => {
    setSubmitting(true);
    try {
      await publicUserAPI.forgotPassword(value.email.toLowerCase());
      navigate("/public/forgot-password/sent-email");
    } catch (err) {
      console.error(err);
    }
    setSubmitting(false);
    hideModal();
  };

  const [showModal, hideModal] = useLoadingModal();

  const handleShow = (email: string) => {
    if (email !== "" && REG_EMAIL_VALIDATOR.test(email)) {
      showModal();
    }
  };

  return (
    <div className="w-full h-fit">
      <h1 className="mb-3 text-dark-blue">
        {t("public.onboarding.forgotPwdTitle")}
      </h1>

      <p className="text-dark-grey mb-10">
        {t("public.onboarding.forgotPwdTitleHint")}
      </p>

      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          email: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ errors, values }) => {
          return (
            <Form autoComplete="off" noValidate>
              <InputField
                name="email"
                label={`${t("common.email")}*`}
                type="email"
                hideErrorMsg={errors.email !== t(FIELD_IS_REQUIRED_ERROR)}
              />
              <div className="w-full mt-[108px]">
                {errors.email && errors.email !== t(FIELD_IS_REQUIRED_ERROR) && (
                  <div className="flex items-center gap-2 text-error-red">
                    <SvgWarning className="w-3.5 h-3.5" />
                    <p className="text-xs">{errors.email}</p>
                  </div>
                )}
                <Button
                  variant="primary"
                  type="submit"
                  className="w-full mt-2.5"
                  disabled={isSubmitting}
                  onClick={() => handleShow(values.email)}
                >
                  {t("common.next")}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
