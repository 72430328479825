import KentroSignPreview1 from "assets/images/kentro-sign-preview-01.png";
import KentroSignPreview2 from "assets/images/kentro-sign-preview-02.png";
import KentroSignPreview3 from "assets/images/kentro-sign-preview-03.png";
import KentroSignPreview4 from "assets/images/kentro-sign-preview-04.png";
import { ReactComponent as SvgKentroSign } from "assets/svg/kentro-sign.svg";
import { ApplicationDetailLayout } from "features/applicationDashboard/pages/applicationDetail/ApplicationDetailLayout";
import {
  StyledSubtitle,
  StyledText,
} from "features/applicationDashboard/pages/applicationDetail/pages/AtlasEliteView";
import { AppDetailPageProps } from "features/applicationDashboard/pages/applicationDetail/types";
import { useTranslation } from "react-i18next";

export const KentroSignView = ({
  data,
  className,
}: AppDetailPageProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ApplicationDetailLayout
      className={className && className}
      data={data}
      icon={<SvgKentroSign />}
      previewImages={[
        KentroSignPreview1,
        KentroSignPreview2,
        KentroSignPreview3,
        KentroSignPreview4,
      ]}
    >
      <div className="w-full mb-6">
        <StyledSubtitle>
          {t("application.kentroSign.detail.title1")}
        </StyledSubtitle>
        <StyledText>{t("application.kentroSign.detail.text1")}</StyledText>
        <StyledSubtitle>
          {t("application.kentroSign.detail.title2")}
        </StyledSubtitle>
        <StyledText>{t("application.kentroSign.detail.text2")}</StyledText>
        <StyledSubtitle>
          {t("application.kentroSign.detail.title3")}
        </StyledSubtitle>
        <StyledText>{t("application.kentroSign.detail.text3")}</StyledText>
      </div>
    </ApplicationDetailLayout>
  );
};
