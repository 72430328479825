import { useModal } from "@liholiho/react-modal-hook";
import { Modal } from "common/components/Modal";
import { RefreshTokenExpiredPopup } from "common/components/RefreshTokenExpiredPopup";

export const useRefreshTokenExpiredPopupHandler = (
  onStay: () => void,
  onLeave: () => void
) => {
  const [show, hide] = useModal(({ in: isOpen }) => {
    return (
      <Modal open={isOpen}>
        <RefreshTokenExpiredPopup onStay={onStay} onLeave={onLeave} />
      </Modal>
    );
  }, []);

  return {
    show,
    hide,
  };
};
