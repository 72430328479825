import { EMAIL_FORMAT_INCORRECT_ERROR } from "constants/commonValidatorError";

import { publicOtpApi } from "api/publicOtp";
import { Button } from "common/components/Button";
import { InputField } from "common/components/Form/InputField";
import { TitleSection } from "features/public/components/TitleSection";
import { DEFAULT_USER_ONBOARDING_INFO } from "features/public/contexts/UserOnboardingContext";
import { useUserOnboardingInfo } from "features/public/Register/hooks/useUserOnboardingInfo";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormSubmissionHandler } from "types/common";
import * as yup from "yup";

export type EmailFormValues = {
  email: string;
};

export const EmailForm = (): JSX.Element => {
  const { t } = useTranslation();
  const schema: yup.SchemaOf<EmailFormValues> = yup.object().shape({
    email: yup.string().email(t(EMAIL_FORMAT_INCORRECT_ERROR)).required(),
  });
  const { userOnboardingInfo, setUserOnboardingInfo, onNextStep } =
    useUserOnboardingInfo();
  const handleSubmit: FormSubmissionHandler<EmailFormValues> = async (
    values
  ) => {
    try {
      setUserOnboardingInfo({
        ...DEFAULT_USER_ONBOARDING_INFO,
        email: values.email.trim(),
      });
      await publicOtpApi.requestOtp(
        values.email,
        userOnboardingInfo.firstName,
        userOnboardingInfo.lastName
      );
      onNextStep();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="relative w-full">
      <TitleSection
        title={`${t("public.onboarding.emailFormTitle")}`}
        description={t("public.onboarding.emailFormTitleHint")}
      />
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        initialValues={{ email: userOnboardingInfo.email }}
      >
        <Form className="" autoComplete="off" noValidate>
          <InputField
            name="email"
            label={`${t("common.email")}*`}
            type="text"
            className="mt-16"
          />
          <Button type="submit" variant="primary" className="w-full mt-72">
            {t("common.next")}
          </Button>
        </Form>
      </Formik>
    </div>
  );
};
