import {
  CrossDomainMessage,
  ReceiveMessageCallback,
  receiveMessageHandler,
} from "common/utils/cross-domain/base/receiveHandler";
import { isTopWindow } from "common/utils/cross-domain/base/utils";
import { CHILDREN_DOMAIN } from "common/utils/cross-domain/react/config";
import { CrossDomainMessageValue } from "common/utils/cross-domain/react/helper";
import { CrossDomainMessageType } from "common/utils/cross-domain/react/helper";
import { useEffect } from "react";
import { UUID } from "types/common";

export const crossDomainTokenListenHandler: ReceiveMessageCallback<
  CrossDomainMessageValue[]
> = (
  id: UUID,
  type: CrossDomainMessageType,
  receiveMessage: CrossDomainMessageValue[],
  eventOrigin = "*"
) => {
  if (
    !isTopWindow() &&
    window.parent &&
    type !== CrossDomainMessageType.RESPONSE
  ) {
    window.parent.postMessage(
      {
        id,
        action: CrossDomainMessageType.RESPONSE,
        content: `200`, // to represent success
      },
      eventOrigin
    );
  }

  if (type === CrossDomainMessageType.MESSAGE) {
    if (receiveMessage.length) {
      receiveMessage.forEach((receiveMessage) => {
        const { key, value } = receiveMessage;
        value ? localStorage.setItem(key, value) : localStorage.removeItem(key);
      });
    } else localStorage.clear();
  }
};

export const useCrossDomainTokenListener = () => {
  useEffect(() => {
    const messageListener = (
      e: MessageEvent<CrossDomainMessage<CrossDomainMessageValue[]>>
    ) => {
      receiveMessageHandler(e, crossDomainTokenListenHandler, CHILDREN_DOMAIN);
    };
    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener, false);
    };
  }, []);
};
