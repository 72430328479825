import { useModal } from "@liholiho/react-modal-hook";
import { ErrorPopup } from "common/components/ErrorPopup";
import { Modal } from "common/components/Modal";
import useLogout from "common/hooks/useLogout";

export const useErrorMessagePopupHandler = () => {
  const logout = useLogout();

  const [show, hide] = useModal(({ in: isOpen }) => {
    return (
      <Modal open={isOpen} onClose={hide} size="md">
        <ErrorPopup onClose={hide} onLogout={() => logout()} />
      </Modal>
    );
  });

  return {
    show,
  };
};
