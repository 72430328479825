import { VersionContext } from "common/context/VersionContext";
import { useContext } from "react";
import semver from "semver";

export const useVersion = () => {
  const { version } = useContext(VersionContext);

  const isValidVersion = (versionCondition: string) => {
    return isVersionSatisfy(versionCondition, version);
  };

  return {
    version,
    isValidVersion,
  };
};

export const isVersionSatisfy = (
  versionCondition: string,
  currentVersion: string
) => {
  return semver.satisfies(currentVersion, versionCondition);
};
