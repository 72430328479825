import { Title } from "features/applicationDashboard/components/Title";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type ApplicationInformationProps = {
  children: ReactNode;
};

export const ApplicationInformation = ({
  children,
}: ApplicationInformationProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="col-span-7">
      <Title
        className="w-full mb-8 pl-8"
        title={t("application.title.applicationInformation")}
      />
      {children}
    </div>
  );
};
