import { ReactNode } from "react";

export const SectionContent = ({
  index,
  children,
  className,
}: {
  index?: string;
  children: ReactNode;
  className?: string;
}) => (
  <div className={`text-start text-dark-blue flex mb-10 ${className}`}>
    {!!index && <span className="w-10">{index}</span>}
    <div className="flex-1">{children}</div>
  </div>
);
