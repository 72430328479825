import React from "react";
import tw from "twin.macro";

type FieldProps = {
  className?: string;
  errorMsgClassName?: string;
  name: string;
  label?: string;
  children: React.ReactNode;
  hideErrorMsg?: boolean;
  fieldError?: string;
};

export const FieldContainer = ({
  className,
  name,
  label,
  children,
  hideErrorMsg,
  errorMsgClassName,
  fieldError,
}: FieldProps): JSX.Element => {
  return (
    <div className={className} data-cy="field-container">
      {label && <Label htmlFor={name}>{label}</Label>}
      {children}
      {!hideErrorMsg && (
        <div className={`text-error-red text-xs ${errorMsgClassName}`}>
          {fieldError}
        </div>
      )}
    </div>
  );
};

export const Label = tw.label`
  inline-block
  text-dark-grey
  font-medium
  text-xs
  mb-1
`;
