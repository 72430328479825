import { useModal } from "@liholiho/react-modal-hook";
import { Modal } from "common/components/Modal";
import { ExpireActionTokenPopup } from "features/public/components/ExpireActionTokenPopup";

export const useActionTokenExpirePopupHandler = (onRefresh: () => void) => {
  const [show, hide] = useModal(({ in: isOpen }) => {
    return (
      <Modal open={isOpen} onClose={hide} size="sm">
        <ExpireActionTokenPopup onRefresh={onRefresh} />
      </Modal>
    );
  });
  return {
    handler: show,
  };
};
