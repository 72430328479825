import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import "features/applicationDashboard/components/Slider/slider.scss";

export type SilderProps = {
  imagesUrl: string[];
  slidesPerView?: number;
  spaceBetween?: number;
  isAutoPlay?: boolean;
  delay?: number;
  isLoop?: boolean;
  isPauseOnMouseEnter?: boolean;
};

export const Slider = ({
  imagesUrl,
  slidesPerView = 1,
  spaceBetween = 0,
  isAutoPlay = false,
  delay = 2500,
  isLoop = true,
  isPauseOnMouseEnter = false,
}: SilderProps) => {
  return (
    <Swiper
      slidesPerView={slidesPerView}
      spaceBetween={spaceBetween}
      autoplay={
        isAutoPlay
          ? {
              delay,
              pauseOnMouseEnter: isPauseOnMouseEnter,
              disableOnInteraction: false,
            }
          : false
      }
      pagination={{
        clickable: true,
      }}
      loop={isLoop}
      modules={[Autoplay, Pagination]}
    >
      {imagesUrl.map((url, index) => (
        <SwiperSlide key={index}>
          <img src={url} className="w-full" alt="app" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
