import { AppInfo } from "api/dashboard";
import { useApp } from "common/hooks/useApp";
import useCurrentUserSubscription from "common/hooks/useCurrentUserSubscription";
import { ApplicationInfo } from "features/applicationDashboard/types";
import { generateFullApplicationInfoByAppInfo } from "features/applicationDashboard/utils/common";
import { useMemo } from "react";

export type AppDetailInfoByIdResult = {
  appDetailData?: ApplicationInfo;
  error: unknown;
  mutate: () => Promise<AppInfo | undefined>;
};

export const useAppDetailInfoById = (
  applicationId?: string
): AppDetailInfoByIdResult => {
  const { data, error, mutate } = useApp(applicationId);
  const currentUserSubscriptions = useCurrentUserSubscription();
  const appDetailData: ApplicationInfo | undefined = useMemo(() => {
    return data
      ? generateFullApplicationInfoByAppInfo(data, currentUserSubscriptions)
      : undefined;
  }, [currentUserSubscriptions, data]);
  return {
    appDetailData,
    error,
    mutate,
  };
};
