import { AppInfo, applicationAPI } from "api/dashboard";
import { APIError } from "common/errorHandling";
import { request } from "common/HOC/WithRequest";
import useAuthErrorHandler from "common/hooks//useAuthErrorHandler";
import { ApplicationInfo } from "features/applicationDashboard/types";
import useSWR, { SWRResponse } from "swr";
import { UUID } from "types/common";

export type AppDetailInfoByIdResult = {
  appDetailData?: ApplicationInfo;
  error: unknown;
  mutate: () => Promise<AppInfo | undefined>;
};

export const useApp = (
  applicationId?: UUID
): SWRResponse<AppInfo, APIError> => {
  const onError = useAuthErrorHandler();
  return useSWR<AppInfo, APIError>(
    applicationId
      ? applicationAPI.applicationDetail(applicationId)
      : applicationId,
    request,
    { onError }
  );
};
