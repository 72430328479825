import { userAPI } from "api/user";
import { GenericError } from "common/errorHandling";
import { useAuth, useAuthErrorHandler } from "common/hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "redux/hooks";
import { setCurrentUser, setLoggedIn } from "redux/slices/auth/authSlice";

const useAuthRehydrate = (): boolean => {
  const dispatch = useDispatch();
  const { accessToken, currentUserProfile } = useAuth();
  const [isHydrated, setIsHydrated] = useState(false);
  const onAuthError = useAuthErrorHandler();

  useEffect(() => {
    let isCancelled = false;

    const fetch = async () => {
      setIsHydrated(false);

      if (!accessToken || currentUserProfile) {
        setIsHydrated(true);
        return;
      }

      try {
        const profile = await userAPI.getCurrentUserInfo();

        if (!isCancelled) {
          dispatch(setLoggedIn());
          dispatch(setCurrentUser(profile));
        }
      } catch (e) {
        onAuthError(e as GenericError);
      }

      setIsHydrated(true);
    };

    fetch();

    return () => {
      isCancelled = true;
    };
  }, [accessToken, currentUserProfile, dispatch, onAuthError]);

  return isHydrated;
};

export default useAuthRehydrate;
