import classNames from "classnames";
import { Colors } from "enums/colors";
import { ReactNode } from "react";
import tw from "twin.macro";

type TagProps = {
  className?: string;
  color?: Colors;
  children: ReactNode;
};

const TagWrapper = tw.div`flex items-center justify-center w-fit px-3 py-1 rounded-2xl`;

export const Tag = ({ className, color, children }: TagProps) => {
  const classStr = classNames(
    "text-xs font-medium",
    color
      ? `bg-${color}-opacity text-${color}`
      : `bg-${Colors.LIGHT_BLUE}-opacity text-${Colors.LIGHT_BLUE}`,
    className
  );

  return <TagWrapper className={classStr}>{children}</TagWrapper>;
};
