import { AppInfo } from "api/dashboard";
import { request } from "common/HOC/WithRequest";
import useAuthErrorHandler from "common/hooks/useAuthErrorHandler";
import useCurrentUserSubscription from "common/hooks/useCurrentUserSubscription";
import { useUpdateApps } from "features/applicationDashboard/hooks/useUpdateApps";
import { ApplicationInfo } from "features/applicationDashboard/types";
import { generateFullApplicationInfoByAppInfo } from "features/applicationDashboard/utils/common";
import { useMemo } from "react";
import useSWRInfinite from "swr/infinite";
import { ListPage } from "types/common";

export type InfiniteAppListResult = {
  apps: ApplicationInfo[];
  total?: number;
  isLoading: boolean;
  error: unknown;
  fetchNext: () => void;
  mutate: () => Promise<ListPage<AppInfo>[] | undefined>;
};

export const useInfiniteAppList = (
  appName?: string,
  enabled?: boolean,
  pageNum?: number,
  pageSize?: number
): InfiniteAppListResult => {
  const onError = useAuthErrorHandler();
  const currentUserSubscription = useCurrentUserSubscription();
  const urlParams = new URLSearchParams();

  const pageSizeParams = pageSize || 10;
  urlParams.set("pageSize", pageSizeParams.toString());

  if (appName) {
    urlParams.set("appName", appName);
  }
  if (enabled) {
    urlParams.set("enabled", enabled.toString());
  }

  const {
    data: appPageList,
    error,
    size,
    setSize,
    mutate,
  } = useSWRInfinite<ListPage<AppInfo>>(getAppListPath(urlParams), request, {
    onError,
    revalidateAll: true,
  });
  const isLoading: boolean = useMemo(
    () =>
      (!error && !appPageList) ||
      (size > 0 && !!appPageList && appPageList[size - 1] === undefined),
    [appPageList, error, size]
  );

  const total = useMemo(
    () =>
      appPageList ? appPageList[appPageList.length - 1].numberOfElements : 0,
    [appPageList]
  );
  const cumulativeDocumentList: AppInfo[] | undefined = useMemo(() => {
    return appPageList?.map((page) => page.content).flat();
  }, [appPageList]);

  const fullAppList: ApplicationInfo[] | undefined = useMemo(
    () =>
      cumulativeDocumentList?.map((app) =>
        generateFullApplicationInfoByAppInfo(app, currentUserSubscription)
      ) ?? [],
    [cumulativeDocumentList, currentUserSubscription]
  );
  useUpdateApps(fullAppList);

  return {
    apps: fullAppList,
    total,
    isLoading,
    error,
    fetchNext: () => setSize(size + 1),
    mutate,
  };
};

const getAppListPath =
  (params: URLSearchParams) =>
  (
    pageIndex: number,
    previousPageData: ListPage<AppInfo> | null
  ): string | null => {
    if (previousPageData && previousPageData.numberOfElements === 0) {
      return null;
    }
    params.set("pageNum", pageIndex.toString());

    return `dashboard/apps?${params.toString()}`;
  };
