import { FieldContainer } from "common/components/Form/FieldContainer";
import { useField } from "formik";
import { ChangeEvent } from "react";
import tw, { styled } from "twin.macro";

export enum FieldRenderType {
  SELECT = "select",
  INPUT = "input",
  TEXTAREA = "textarea",
}

type TextareaProps = {
  className?: string;
  errorMsgClassName?: string;
  name: string;
  label?: string;
  type: string;
  disabled?: boolean;
  placeholder?: string;
  hideErrorMsg?: boolean;
  hideBorderError?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
};

export const TextareaField = ({
  className,
  errorMsgClassName,
  name,
  label,
  type,
  placeholder,
  disabled = false,
  hideErrorMsg,
  hideBorderError = true,
  onBlur,
  onFocus,
}: TextareaProps): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    helpers.setValue(e.target.value);
  };
  return (
    <FieldContainer
      className={className}
      name={name}
      label={label}
      hideErrorMsg={hideErrorMsg}
      errorMsgClassName={errorMsgClassName}
      fieldError={meta.error}
    >
      <StyledTextarea
        id={name}
        value={field.value}
        onChange={handleChange}
        $hasError={!!meta.error}
        $hideErrorMsg={hideErrorMsg}
        $hideBorderError={hideBorderError}
      />
    </FieldContainer>
  );
};

const StyledTextarea = styled.textarea<{
  $hasError?: boolean;
  $hideErrorMsg?: boolean;
  $hideBorderError?: boolean;
  disabled?: boolean;
}>`
  ${tw`
    bg-background-grey
    resize-none
    text-dark-blue
    rounded-normal
    px-2
    py-3
    border
    border-background-grey
    focus:border-navy
    w-full
    h-36
    font-medium
  `}
  ${(props) => props.$hasError && tw`border-error-red`}
  ${(props) =>
    props.$hideErrorMsg && props.$hideBorderError && tw`border-background-grey`}
  ${(props) => props.disabled && tw`text-grey`}
`;
