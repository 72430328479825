import { Popover } from "@headlessui/react";
import { Button } from "common/components/Button";
import { Card } from "common/components/Card";
import { Footer } from "common/components/Footer";
import { Header } from "common/components/Header";
import { KentroToastContainer } from "common/components/KentroToastContainer";
import { UserIcon } from "common/components/UserIcon";
import { UserProfile } from "common/components/UserProfile";
import { VersionDisplay } from "common/components/VersionDisplay";
import { useCommonPopover } from "common/hooks/useCommonPopover";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { useGenerateColorByString } from "common/hooks/useGenerateColorByString";
import { MainContainerBackground } from "common/layouts/MainContainerBackground";
import { generateFullName, isSettingPage } from "common/utils/common";
import { useLogoutPopupHandler } from "features/applicationDashboard/hooks/useLogoutPopupHandler";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import tw, { styled } from "twin.macro";

const GridContainer = styled.div`
  ${tw`grid grid-cols-layout grid-rows-layout`}
`;
const MainContentGrid = styled.div(() => [tw`grid grid-cols-layout`]);

type MainContentPositionProps = {
  isUserProfilePage: boolean;
};
const MainContentPosition = styled.div(
  ({ isUserProfilePage }: MainContentPositionProps) => [
    tw`col-span-1 col-start-2 mb-16`,
    isUserProfilePage ? tw`mt-10` : tw`mt-72`,
  ]
);
const ItemWrapper = styled.div(() => [
  tw`flex flex-1 items-center cursor-pointer`,
]);

const ItemText = styled.span(() => [
  tw`list-inside list-item ml-2 text-sm font-medium`,
]);

type ApplicationDashboardLayoutProps = {
  className?: string;
  children: React.ReactNode;
};
export const ApplicationDashboardLayout = ({
  className,
  children,
}: ApplicationDashboardLayoutProps): JSX.Element => {
  const { setReferenceElement, setPopperElement, styles, attributes } =
    useCommonPopover({ placement: "bottom-start" });
  const navigate = useNavigate();
  const currentUser = useCurrentUserProfile();
  const { handler: showLogoutPopup } = useLogoutPopupHandler();

  const { color } = useGenerateColorByString(currentUser?.email);

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isUserProfilePage = useMemo(() => {
    return isSettingPage(pathname);
  }, [pathname]);

  return (
    <>
      <div
        className={`${className && className} w-screen h-screen flex flex-1`}
      >
        <GridContainer className="grow h-fit min-h-screen">
          <Header className="col-span-full grid grid-cols-layout">
            <Popover className="relative" data-cy="user">
              <Popover.Button
                ref={setReferenceElement}
                data-cy="user-profile-settings"
              >
                <UserIcon
                  variant={"initials"}
                  content={generateFullName(
                    currentUser?.firstName,
                    currentUser?.lastName
                  )}
                  color={color}
                />
              </Popover.Button>
              <Popover.Panel
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                className="z-popover"
              >
                {({ close }) => (
                  /** TODO: try change to grid col in ticket KON-106 */
                  <Card className="bg-white mt-7 p-6 min-h-[216px] w-[280px] flex flex-col gap-x-8 justify-between">
                    <UserProfile color={color} userProfile={currentUser} />
                    <div className="flex-1 w-full flex flex-col space-y-4 my-8">
                      <ItemWrapper
                        onClick={() => {
                          navigate("/settings/user-profile");
                          close();
                        }}
                      >
                        <ItemText>{t("settings.userProfile.title")}</ItemText>
                      </ItemWrapper>
                      <VersionDisplay versionCondition=">= 2.0.0">
                        <ItemWrapper
                          onClick={() => {
                            navigate("/settings/language-settings");
                            close();
                          }}
                        >
                          <ItemText>
                            {t("settings.languageSettings.title")}
                          </ItemText>
                        </ItemWrapper>
                      </VersionDisplay>
                    </div>
                    <Button
                      className="w-full"
                      variant="primary"
                      onClick={showLogoutPopup}
                    >
                      {t("application.button.logout")}
                    </Button>
                  </Card>
                )}
              </Popover.Panel>
            </Popover>
          </Header>

          <MainContainerBackground isPictureBackground={!isUserProfilePage}>
            <MainContentGrid className="relative top-0 w-full">
              <MainContentPosition isUserProfilePage={isUserProfilePage}>
                <KentroToastContainer />
                {children}
              </MainContentPosition>
            </MainContentGrid>
          </MainContainerBackground>

          <Footer className="col-span-full" />
        </GridContainer>
      </div>
    </>
  );
};
