import { Tag } from "common/components/Tag";
import {
  ApplicationIcon,
  Categories,
} from "features/applicationDashboard/types";
import tw from "twin.macro";

type ApplicationDescriptionProps = {
  icon: ApplicationIcon;
  title: string;
  description: string;
  tag: Categories;
  className?: string;
};

const StyledTitle = tw.div`text-dark-blue font-medium`;
const StyledDescription = tw.div`text-dark-grey`;

export const ApplicationDescription = ({
  icon,
  title,
  description,
  tag,
  className,
}: ApplicationDescriptionProps): JSX.Element => {
  return (
    <div className={`flex gap-x-5 ${className && className}`}>
      <img
        className="block mx-0 my-auto w-20 h-20"
        src={icon.src}
        alt={icon.alt}
      />
      <div className="flex flex-col justify-between text-sm gap-2">
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
        <Tag>{tag}</Tag>
      </div>
    </div>
  );
};
