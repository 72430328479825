import { Button } from "common/components/Button";
import { TitleSection } from "features/public/components/TitleSection";
import { useUserOnboardingInfo } from "features/public/Register/hooks/useUserOnboardingInfo";
import { formatDate } from "features/public/utils/onboarding";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export type AccountExistProps = {
  className?: string;
};

export const AccountExist = ({ className }: AccountExistProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userOnboardingInfo, clearOnboardingInfo } = useUserOnboardingInfo();

  const handleToLoginPage = () => {
    clearOnboardingInfo();
    navigate("../../login");
  };

  const handleToResetPasswordPage = () => {
    navigate(
      `../../reset-password?resetToken=${userOnboardingInfo.resetPasswordToken}`
    );
  };
  return (
    <div className={`relative w-full ${className}`}>
      <TitleSection
        title={`${t("public.onboarding.accountExistTitle")}`}
        description={
          <Trans
            i18nKey="public.onboarding.accountExistTitleHint"
            values={{
              email: userOnboardingInfo.email,
            }}
            className="leading-6.5"
          >
            <span className="text-light-blue" />
          </Trans>
        }
      />
      <div className="text-dark-grey font-medium leading-6.5 mb-16">
        <div>
          <span>{t("public.onboarding.accountCreatedDate")}</span>
          <span className="text-dark-blue ml-1">
            {userOnboardingInfo.createdAt
              ? formatDate(userOnboardingInfo.createdAt)
              : null}
          </span>
        </div>
        <div>
          <span>{t("public.onboarding.lastLogin")}</span>
          <span className="text-dark-blue ml-1">
            {userOnboardingInfo.lastLoginAt
              ? formatDate(userOnboardingInfo.lastLoginAt)
              : null}
          </span>
        </div>
      </div>
      <Button
        type="button"
        variant="primary"
        className="w-full mb-16"
        onClick={handleToLoginPage}
      >
        {t("public.button.login")}
      </Button>
      <div>
        <div className="text-dark-grey mb-2 leading-6.5">
          {t("public.onboarding.accountExistHint")}
        </div>
        <Button
          type="button"
          variant="secondary"
          className="w-full"
          onClick={handleToResetPasswordPage}
        >
          {t("public.button.resetPassword")}
        </Button>
      </div>
    </div>
  );
};
