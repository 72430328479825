import MidasPreview1 from "assets/images/midas-preview-01.png";
import MidasPreview2 from "assets/images/midas-preview-02.png";
import MidasPreview3 from "assets/images/midas-preview-03.png";
import { ReactComponent as SvgMidas } from "assets/svg/midas.svg";
import { ApplicationDetailLayout } from "features/applicationDashboard/pages/applicationDetail/ApplicationDetailLayout";
import {
  StyledSubtitle,
  StyledText,
} from "features/applicationDashboard/pages/applicationDetail/pages/AtlasEliteView";
import { AppDetailPageProps } from "features/applicationDashboard/pages/applicationDetail/types";
import { useTranslation } from "react-i18next";

export const MidasView = ({
  data,
  className,
}: AppDetailPageProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ApplicationDetailLayout
      className={className && className}
      data={data}
      icon={<SvgMidas />}
      previewImages={[MidasPreview1, MidasPreview2, MidasPreview3]}
    >
      <div className="w-full mb-6">
        <StyledSubtitle>{t("application.midas.detail.title1")}</StyledSubtitle>
        <StyledText>{t("application.midas.detail.text1")}</StyledText>
        <StyledSubtitle>{t("application.midas.detail.title2")}</StyledSubtitle>
        <StyledText>{t("application.midas.detail.text2")}</StyledText>
      </div>
    </ApplicationDetailLayout>
  );
};
