import { BaseBreadCrumbs } from "common/components/Breadcrumbs/BaseBreadcrumbs";
import { SelectOption } from "common/components/Form/SelectField";
import { toast } from "common/toast";
import {
  BottomSpacingSize,
  TitleSection,
} from "features/public/components/TitleSection";
import { LanguageSelection } from "features/userSettings/components/languageSetting/LanguageSelection";
import { LANGUAGES } from "i18n";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type LanguageSettingsProp = {
  onSelect: (language: SelectOption) => void;
};

const LanguageSettings = ({ onSelect }: LanguageSettingsProp): JSX.Element => {
  const { t, i18n } = useTranslation();
  const availableLanguages = i18n.services.resourceStore.data;

  const languageOptions: SelectOption[] = useMemo(
    () =>
      Object.keys(availableLanguages).reduce((acc: SelectOption[], code, i) => {
        const name = LANGUAGES.get(code);
        if (name) {
          const option: SelectOption = {
            value: code,
            label: name,
            index: i,
          };
          acc.push(option);
        }
        return acc;
      }, []),
    [availableLanguages]
  );

  return (
    <div className="bg-inherit h-full" data-cy="language-settings">
      <TitleSection
        title={t("settings.languageSettings.title")}
        description={
          <BaseBreadCrumbs itemContainerClassName="hover:text-light-blue">
            <Link to="/dashboard">{t("settings.breadcrumbs.home")}</Link>
            {t("settings.languageSettings.title")}
          </BaseBreadCrumbs>
        }
        bottomSpacing={BottomSpacingSize.CLOSE}
      />
      <LanguageSelection options={languageOptions} onSelect={onSelect} />
    </div>
  );
};

export const LanguageSettingsView = (): JSX.Element => {
  const { t, i18n } = useTranslation();

  const handleSelectedLanguage = (selected: SelectOption) => {
    i18n.changeLanguage(selected.value, () => {
      toast.success(
        t("settings.toast.languageSettings", {
          language: selected.label,
        })
      );
    });
  };

  return <LanguageSettings onSelect={handleSelectedLanguage} />;
};
