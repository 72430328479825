import { LatestAppsContextProvider } from "common/context/LatestAppsContext";
import { useAppStatus } from "common/hooks/useAppStatus";
import { useVersion } from "common/hooks/useVersion";
import { ApplicationDetailRoutes } from "features/applicationDashboard/pages/applicationDetail/applicationDetailRoutes";
import { DashboardView } from "features/applicationDashboard/pages/dashboard/DashboardView";
import { LanguageSettingsView } from "features/userSettings/components/languageSetting/LanguageSettingsView";
import { EditProfileView } from "features/userSettings/components/profileSetting/EditProfileView";
import { UserSettingsView } from "features/userSettings/components/UserSettingsView";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

export const BusinessRoutes = (): JSX.Element => {
  const { isValidVersion } = useVersion();
  useAppStatus();
  return (
    <Routes>
      <Route index element={<Navigate to="dashboard" />} />
      <Route
        path="dashboard"
        element={
          <LatestAppsContextProvider>
            <Outlet />
          </LatestAppsContextProvider>
        }
      >
        <Route index element={<DashboardView />} />
        <Route
          path="details/:applicationId/*"
          element={<ApplicationDetailRoutes />}
        />
      </Route>
      <Route path="settings" element={<Outlet />}>
        <Route path="user-profile" element={<Outlet />}>
          <Route index element={<UserSettingsView />} />
          <Route path="edit-profile" element={<EditProfileView />} />
        </Route>

        {isValidVersion(">2.0.0") && (
          <Route path="language-settings" element={<Outlet />}>
            <Route index element={<LanguageSettingsView />} />
          </Route>
        )}
      </Route>
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
};
