import { ReactComponent as LeftArrow } from "assets/svg/left-arrow.svg";
import { Button } from "common/components/Button";
import { useTranslation } from "react-i18next";

type BackButtonProps = {
  onClick: () => void;
  className?: string;
};

export const BackButton = ({
  onClick,
  className,
}: BackButtonProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Button
      className={`${className} flex !justify-start`}
      variant="lightBlue"
      onClick={onClick}
    >
      <div className="w-full flex pl-8">
        <LeftArrow />
        <p className="ml-3 text-h3 font-bold">{t("application.button.back")}</p>
      </div>
    </Button>
  );
};
