import { EMAIL_FORMAT_INCORRECT_ERROR } from "constants/commonValidatorError";
import { REG_EMAIL_VALIDATOR } from "constants/emailValidatorKey";

import { Button } from "common/components/Button";
import { InputField } from "common/components/Form/InputField";
import { TextareaField } from "common/components/Form/TextareaField";
import { Message } from "common/components/Message";
import { UploadDocumentsProvider } from "features/public/contexts/PureUploadDocumentsCreateContext";
import { SupportRequestUploadedFilesList } from "features/public/ResetPassword/components/SupportRequestUploadedFilesList";
import { SupportRequestUploadField } from "features/public/ResetPassword/components/SupportRequestUploadField";
import { UploadedFile } from "features/public/types";
import { FILE_NOT_UPLOAD_COMPLETED_ERROR } from "features/public/utils/onboarding";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { FormSubmissionHandler } from "types/common";
import * as yup from "yup";

export type SupportRequestFormValues = {
  email: string;
  subject: string;
  description: string;
  attachments: UploadedFile[];
};

type SupportRequestFormProps = {
  onSubmit: FormSubmissionHandler<SupportRequestFormValues>;
};

export const SupportRequestForm = ({
  onSubmit,
}: SupportRequestFormProps): JSX.Element => {
  const { t } = useTranslation();
  const schema: yup.SchemaOf<SupportRequestFormValues, UploadedFile> = yup
    .object()
    .shape({
      email: yup
        .string()
        .trim()
        .required()
        .test("email-validate", t(EMAIL_FORMAT_INCORRECT_ERROR), (value) => {
          return REG_EMAIL_VALIDATOR.test(String(value));
        }),
      subject: yup.string().required(),
      description: yup.string().required(),
      attachments: yup
        .array()
        .test(
          "is-complete",
          t(FILE_NOT_UPLOAD_COMPLETED_ERROR),
          (value: UploadedFile[] | undefined) => {
            return (
              value?.every(
                (file: UploadedFile) =>
                  file && file.id && file.loaded && file.loaded >= file.size
              ) || false
            );
          }
        ),
    });

  return (
    <Formik
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      initialValues={{
        email: "",
        subject: "",
        description: "",
        attachments: [] as UploadedFile[],
      }}
    >
      {({ values, isSubmitting, errors }) => (
        <Form autoComplete="off" noValidate>
          <UploadDocumentsProvider fieldName="attachments">
            <InputField
              className="mt-10"
              name="email"
              label={`${t("common.yourEmailAddress")}*`}
              type="text"
              hideErrorMsg={errors.email === t(EMAIL_FORMAT_INCORRECT_ERROR)}
            />
            <InputField
              className="mt-8"
              name="subject"
              label={`${t("common.subject")}*`}
              type="text"
            />
            <TextareaField
              className="mt-8"
              name="description"
              label={`${t("common.description")}*`}
              type="text"
            />
            <SupportRequestUploadField
              className="mt-8"
              name="attachments"
              label={t("common.attachments")}
            />
            <SupportRequestUploadedFilesList className="mt-10" />
            <div className="mt-20 mb-2">
              {errors.email &&
                errors.email === t(EMAIL_FORMAT_INCORRECT_ERROR) && (
                  <Message
                    className="text-xs mb-2"
                    variant="warning"
                    isBackgroundEnable={false}
                  >
                    {errors.email}
                  </Message>
                )}
              {errors.attachments && (
                <Message
                  className="text-xs mb-2"
                  isBackgroundEnable={false}
                  variant="warning"
                >
                  <>{errors.attachments}</>
                </Message>
              )}
              <Button
                className="w-full"
                variant="primary"
                disabled={isSubmitting}
                type="submit"
              >
                {t("common.submit")}
              </Button>
            </div>
          </UploadDocumentsProvider>
        </Form>
      )}
    </Formik>
  );
};
