import { Colors, generateColorByString } from "enums/colors";
import { useMemo } from "react";

export const useGenerateColorByString = (input: string | undefined) => {
  const color: Colors = useMemo(() => {
    return input ? generateColorByString(input) : Colors.LIGHT_BLUE;
  }, [input]);

  return { color };
};
