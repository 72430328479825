import AtlasElitePreview1 from "assets/images/atlas-elite-preview-01.png";
import AtlasElitePreview2 from "assets/images/atlas-elite-preview-02.png";
import AtlasElitePreview3 from "assets/images/atlas-elite-preview-03.png";
import { ReactComponent as SvgAtlasElite } from "assets/svg/atlas-elite.svg";
import { ApplicationDetailLayout } from "features/applicationDashboard/pages/applicationDetail/ApplicationDetailLayout";
import { AppDetailPageProps } from "features/applicationDashboard/pages/applicationDetail/types";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

export const StyledSubtitle = tw.p`text-sm font-medium text-dark-blue mb-2 pl-8`;
export const StyledText = tw.p`text-sm text-dark-grey mb-6 pl-8`;

export const AtlasEliteView = ({
  data,
  className,
}: AppDetailPageProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ApplicationDetailLayout
      className={className && className}
      data={data}
      icon={<SvgAtlasElite />}
      previewImages={[
        AtlasElitePreview1,
        AtlasElitePreview2,
        AtlasElitePreview3,
      ]}
    >
      <div className="w-full mb-6">
        <StyledSubtitle>
          {t("application.atlasElite.detail.title1")}
        </StyledSubtitle>
        <StyledText>{t("application.atlasElite.detail.text1")}</StyledText>
        <StyledSubtitle>
          {t("application.atlasElite.detail.title2")}
        </StyledSubtitle>
        <StyledText>{t("application.atlasElite.detail.text2")}</StyledText>
        <StyledSubtitle>
          {t("application.atlasElite.detail.title3")}
        </StyledSubtitle>
        <StyledText>{t("application.atlasElite.detail.text3")}</StyledText>
        <StyledSubtitle>
          {t("application.atlasElite.detail.title4")}
        </StyledSubtitle>
        <StyledText>{t("application.atlasElite.detail.text4")}</StyledText>
      </div>
    </ApplicationDetailLayout>
  );
};
