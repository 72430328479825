import { SupportRequestFormValues } from "features/public/ResetPassword/components/SupportRequestForm";
import {
  PDFFile,
  UploadDocumentFormValue,
  UploadedFile,
} from "features/public/types";
import { isFileDuplicate } from "features/public/utils/onboarding";
import { useFormikContext } from "formik";
import React, { createContext, useEffect, useState } from "react";

type PureUploadDocumentsCreateContextProps = {
  deleteFiles: PDFFile[];
  handleDeleteFile: (file: PDFFile) => void;
  newUploadedFiles: PDFFile[];
  setNewUploadedFiles: (newUploadFile: PDFFile[]) => void;
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
};

export const PureUploadDocumentsCreateContext =
  createContext<PureUploadDocumentsCreateContextProps>({
    deleteFiles: [],
    handleDeleteFile: () => {},
    newUploadedFiles: [],
    setNewUploadedFiles: () => {},
    isUploading: false,
    setIsUploading: () => {},
  });

type UploadDocumentsProviderProps = {
  fieldName: string;
  children: React.ReactNode;
};
export const UploadDocumentsProvider = ({
  fieldName,
  children,
}: UploadDocumentsProviderProps) => {
  const { setFieldValue, getFieldMeta } = useFormikContext<
    UploadDocumentFormValue | SupportRequestFormValues
  >();

  const [newUploadedFiles, setNewUploadedFiles] = useState<PDFFile[]>([]);
  // Use on isUploading status, Tell newUploadedFiles to combine the deleted items to calculate the last value to be set to the field
  const [deleteFiles, setDeleteFiles] = useState<PDFFile[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const handleDeleteFile = (file: PDFFile) => {
    const newValues = getFieldMeta<UploadedFile[] | PDFFile[]>(
      fieldName
    ).value.filter(
      (uploadedFile) =>
        !(
          uploadedFile.id === file.id &&
          uploadedFile.name === file.name &&
          uploadedFile.size === file.size
        )
    );
    setFieldValue(fieldName, newValues);
    if (isUploading) {
      setDeleteFiles((prev) => [...prev, file]);
    }
  };

  useEffect(() => {
    if (newUploadedFiles.length > 0 && !isUploading) {
      if (deleteFiles.length > 0) {
        const newValues = newUploadedFiles.filter(
          (newFile) =>
            !deleteFiles.some((deleteFile) =>
              isFileDuplicate(deleteFile, newFile.name, newFile.size)
            )
        );
        setFieldValue(fieldName, newValues);
        setDeleteFiles([]);
      } else {
        setFieldValue(fieldName, newUploadedFiles);
      }
      setNewUploadedFiles([]);
    }
  }, [deleteFiles, fieldName, isUploading, newUploadedFiles, setFieldValue]);

  return (
    <PureUploadDocumentsCreateContext.Provider
      value={{
        deleteFiles,
        handleDeleteFile,
        newUploadedFiles,
        setNewUploadedFiles,
        isUploading,
        setIsUploading,
      }}
    >
      {children}
    </PureUploadDocumentsCreateContext.Provider>
  );
};
