import { useVersion } from "common/hooks/useVersion";

type VersionDisplayProps = {
  versionCondition: string;
  children: React.ReactNode;
};

export const VersionDisplay = ({
  versionCondition,
  children,
}: VersionDisplayProps) => {
  const { isValidVersion } = useVersion();
  return <>{isValidVersion(versionCondition) && children}</>;
};
