import { LoadingSpinner } from "common/components/LoadingSpinner";
import { Modal } from "common/components/Modal";

type LoadingModalProps = {
  isOpen: boolean;
};

export const LoadingModal = ({ isOpen }: LoadingModalProps): JSX.Element => {
  return (
    <Modal open={isOpen} size="emd">
      <div tabIndex={0} className="flex justify-center py-12">
        <LoadingSpinner />
      </div>
    </Modal>
  );
};
