import { publicUserAPI } from "api/publicUser";
import { Button } from "common/components/Button";
import { composeErrorHandlers, GenericError } from "common/errorHandling";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

type LinkInvalidViewProps = {
  token: string | null;
};
export const LinkInvalidView = ({ token }: LinkInvalidViewProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleRequestNewLink = async () => {
    if (token) {
      try {
        await publicUserAPI.requestNewLink(token);
        navigate("/public/reset-password/link-sent");
      } catch (e) {
        composeErrorHandlers()(e as GenericError);
      }
    }
  };

  return (
    <div className="w-full">
      <h1 className="text-dark-blue">
        {t("public.onboarding.forgotPwdInvalidLinkTitle")}
      </h1>
      <p className="text-dark-grey mt-10 leading-6.5">
        {token
          ? t("public.onboarding.forgotPwdInvalidLinkTitleHint")
          : t("public.onboarding.forgotPwdNotFoundLinkTitleHint")}
      </p>

      <div className="mt-40">
        {token ? (
          <>
            <Button
              className="w-full"
              variant="primary"
              onClick={handleRequestNewLink}
            >
              {t("public.button.requestNewLink")}
            </Button>

            <p className="text-sm text-dark-blue font-medium mt-5">
              {t("public.onboarding.forgotPwdExpiredPersist")}{" "}
              <NavLink to="../support-request" className="text-light-blue ">
                {t("public.button.letUsKnow")}
              </NavLink>
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
};
