export type WithLoadingProps = {
  isLoading: boolean;
};

export const WithLoading = <T,>(Component: React.ComponentType<T>) => {
  return (props: WithLoadingProps & T) => {
    const { isLoading } = props;
    return isLoading ? (
      <div className="flex">
        {/* TODO: update loading */}
        <p className="m-auto">loading...</p>
      </div>
    ) : (
      <Component {...props} />
    );
  };
};
