import { UserIcon, UserIconSize } from "common/components/UserIcon";
import { generateFullName } from "common/utils/common";
import { Colors } from "enums/colors";
import tw from "twin.macro";
import { CurrentUserProfile } from "types/user";

type UserProfileComponentProps = {
  className?: string;
  userProfile: CurrentUserProfile | null;
  color: Colors;
};

const Wrapper = tw.div`flex w-full space-x-4`;

export const UserProfile = ({
  className,
  color,
  userProfile,
}: UserProfileComponentProps) => {
  return (
    <Wrapper className={className && className}>
      <div className="self-center">
        <UserIcon
          size={UserIconSize.LARGE}
          variant="initials"
          content={generateFullName(
            userProfile?.firstName,
            userProfile?.lastName
          )}
          color={color}
        />
      </div>
      <div className="flex flex-col min-w-0">
        <span className="font-semibold text-dark-blue">
          {generateFullName(userProfile?.firstName, userProfile?.lastName)}
        </span>
        <span className="text-xs font-medium text-dark-grey truncate">
          {userProfile?.email}
        </span>
      </div>
    </Wrapper>
  );
};
