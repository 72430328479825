import { applicationAPI, SubscriptionEnquiryPayload } from "api/dashboard";
import { ReactComponent as CheckCircle } from "assets/svg/check-circle.svg";
import { Button } from "common/components/Button";
import { InputField } from "common/components/Form/InputField";
import { SelectField, SelectOption } from "common/components/Form/SelectField";
import { composeErrorHandlers, GenericError } from "common/errorHandling";
import { ApplicationLabel } from "features/applicationDashboard/components/ApplicationLabelWithIcon";
import { ApplicationInfo } from "features/applicationDashboard/types";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatOptionLabelMeta, StylesConfig } from "react-select";
import { CurrentUserProfile } from "types/user";

export type EnquireMorePopupProps = {
  hideModal: () => void;
  options: SelectOption[];
  defaultOption?: SelectOption;
  userProfile: CurrentUserProfile | null;
};

export type EnquirePopupHandlerProps = {
  handler: (app: ApplicationInfo) => void;
};

export type EnquireMoreDataProps = {
  applications: string[];
  companyName: string;
  companyTitle: string;
};

const customApplicationsSelect: StylesConfig<SelectOption> = {
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  control: (base) => ({
    ...base,
    paddingTop: 10,
    paddingBottom: 10,
    border: "none",
    background: "#F5F8FA",
    cursor: "pointer",
  }),
  menuList: (base) => ({
    ...base,
    background: "#F5F8FA",
  }),
  placeholder: (base) => ({
    ...base,
    display: "none",
  }),
  option: (base, state) => {
    return {
      ...base,
      paddingTop: 10,
      paddingBottom: 10,
      background: state.isSelected ? "#F5F8FA" : "",
      "&:hover": {
        background: "#80C6FF",
        cursor: "pointer",
        "input[type='checkbox']": {
          background: "#80C6FF",
        },
      },
    };
  },
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  multiValue: (base) => ({
    ...base,
    background: "transparent",
    marginRight: 24,
  }),
  multiValueRemove: (base) => ({
    ...base,
    display: "none",
  }),
};

const formatOptionLabel = (
  option: SelectOption,
  formatOptionLabelMeta: FormatOptionLabelMeta<SelectOption>
) => (
  <ApplicationLabel
    option={option}
    formatOptionLabelMeta={formatOptionLabelMeta}
  />
);

export const EnquireMorePopup = ({
  hideModal,
  options,
  defaultOption,
  userProfile,
}: EnquireMorePopupProps) => {
  const [isConfirm, setIsConfirm] = useState(false);
  const { t } = useTranslation();
  const refTimer = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (refTimer.current !== null) {
        window.clearTimeout(refTimer.current);
      }
    };
  }, []);

  const autoCloseModal = () => {
    refTimer.current = window.setTimeout(() => {
      hideModal();
      window.clearTimeout(refTimer.current!!);
      refTimer.current = null;
    }, 10000);
  };

  const initialValues: EnquireMoreDataProps = {
    applications: defaultOption ? [defaultOption.value] : [],
    companyName: userProfile?.companyName ?? "",
    companyTitle: userProfile?.companyTitle ?? "",
  };

  const handleSubmit = async (values: EnquireMoreDataProps) => {
    try {
      const requestBody: SubscriptionEnquiryPayload = {
        userCompanyName: values.companyName || null,
        userCompanyTitle: values.companyTitle || null,
        applicationIds: values.applications,
      };
      await applicationAPI.enquiry(requestBody);
      setIsConfirm(true);
      autoCloseModal();
    } catch (error) {
      composeErrorHandlers((next) => (e) => {
        next(e);
      })(error as GenericError);
    }
  };

  return isConfirm ? (
    <div>
      <div className="flex">
        <CheckCircle className="text-light-blue w-12 h-12 mx-auto" />
      </div>
      <p className="mt-11 text-center text-dark-blue">
        {t("application.enquirePopup.confirmNotification")}
      </p>
    </div>
  ) : (
    <div>
      <h2 className="font-bold text-light-blue text-center capitalize">
        {t("application.button.enquireMore")}
      </h2>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form className="mt-7">
          <SelectField
            name="applications"
            label={t("application.enquirePopup.selectFieldLabel")}
            isMulti
            options={options}
            isSearchable={false}
            closeMenuOnSelect={false}
            backspaceRemovesValue={false}
            selectStyles={customApplicationsSelect}
            defaultValue={defaultOption ?? options[0]}
            formatOptionLabel={formatOptionLabel}
          />
          <InputField
            type="text"
            name="companyName"
            label={t("common.companyName")}
            placeholder={t("application.enquirePopup.companyNamePlaceHolder")}
            className="mt-6"
          />
          <InputField
            type="text"
            name="companyTitle"
            label={t("common.companyTitle")}
            placeholder={t("application.enquirePopup.companyTitlePlaceHolder")}
            className="mt-6"
          />
          <p className="mt-12 text-dark-grey font-medium">
            {t("application.enquirePopup.HintConfirm")}
          </p>
          <Button
            type="submit"
            variant="primary"
            className="mt-4 w-full transition-all"
          >
            {t("common.confirm")}
          </Button>
          <Button
            type="button"
            variant="secondary"
            className="mt-4 w-full transition-all"
            onClick={hideModal}
          >
            {t("common.cancel")}
          </Button>
        </Form>
      </Formik>
    </div>
  );
};
