import { ApplicationName } from "features/applicationDashboard/types";

export type SubscriptionInfo = {
  app: {
    id: string;
    appName: ApplicationName;
  };
  id: string;
  startDateTime: Date;
  endDateTime: Date;
  isProfileSetup: boolean;
};
export type CurrentUserProfile = {
  email: string;
  firstName: string;
  lastName: string;
  companyName: Readonly<string | null>;
  companyTitle: string | null;
  userSubscription: SubscriptionInfo[];
};

export type UserPersonalDetail = {
  firstName: string;
  lastName: string;
};

export type CreateCompanyPayload = {
  name: string | null;
  title: string | null;
};

export type UpdateUserSettingPayload = {
  languageCode: string;
};

export type UpdateProfileSettingPayload = {
  personalDetail: UserPersonalDetail;
  company: CreateCompanyPayload;
  setting: UpdateUserSettingPayload;
};

export enum AccountType {
  CORPORATE = "CORPORATE",
  INDIVIDUAL = "INDIVIDUAL",
}
