import { useModal } from "@liholiho/react-modal-hook";
import { Modal } from "common/components/Modal";
import { RegisterPopup } from "features/public/Register/components/RegisterPoup/RegisterPopup";

export const useRegisterPopupHandler = () => {
  const [show, hide] = useModal(({ in: isOpen }) => {
    return (
      <Modal open={isOpen} size="elg">
        <RegisterPopup hideModal={hide} />
      </Modal>
    );
  });

  return {
    show,
  };
};
