import { publicOtpApi } from "api/publicOtp";
import { Message } from "common/components/Message";
import {
  composeErrorHandlers,
  GenericError,
  isAPIError,
} from "common/errorHandling";
import { TitleSection } from "features/public/components/TitleSection";
import { OTPFormBody } from "features/public/Register/components/OTPFormBody";
import { OTPResend } from "features/public/Register/components/OTPResend";
import { useUserOnboardingInfo } from "features/public/Register/hooks/useUserOnboardingInfo";
import { OnboardingScenario } from "features/public/Register/types";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormSubmissionHandler } from "types/common";
import * as yup from "yup";

export type OTPFormValues = {
  otp: string;
};

const OTP_ERROR_MESSAGE = "public.onboarding.OTPInvalid";

export const OTPForm = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schema: yup.SchemaOf<OTPFormValues> = yup.object().shape({
    otp: yup
      .string()
      .required(t(OTP_ERROR_MESSAGE))
      .test("otp-length", t(OTP_ERROR_MESSAGE), (value) => {
        return value?.length === 6;
      }),
  });
  const {
    onboardingScenario,
    userOnboardingInfo,
    setUserOnboardingInfo,
    onNextStep,
    setOnboardingScenario,
  } = useUserOnboardingInfo();

  const handleOTPFormSubmit: FormSubmissionHandler<OTPFormValues> = async (
    value,
    helpers
  ) => {
    try {
      const verifyRes = await publicOtpApi.verifyOtp(
        userOnboardingInfo.email,
        value.otp
      );

      // User already existed
      if (verifyRes.createdAt || verifyRes.lastLoginAt) {
        setUserOnboardingInfo((prev) => {
          return verifyRes.userProfile
            ? {
                ...prev,
                ...verifyRes.userProfile,
                actionToken: verifyRes.actionToken,
                createdAt: verifyRes.createdAt,
                lastLoginAt: verifyRes.lastLoginAt,
                resetPasswordToken: verifyRes.resetPasswordToken,
              }
            : {
                ...prev,
                actionToken: verifyRes.actionToken,
                createdAt: verifyRes.createdAt,
                lastLoginAt: verifyRes.lastLoginAt,
                resetPasswordToken: verifyRes.resetPasswordToken,
              };
        });
        navigate("../account-existed");
      }
      // KS User migration
      else if (verifyRes.userProfile) {
        setUserOnboardingInfo((prev) => {
          return verifyRes.userProfile
            ? {
                ...prev,
                actionToken: verifyRes.actionToken,
                email: verifyRes.userProfile.email,
                firstName: verifyRes.userProfile.firstName,
                lastName: verifyRes.userProfile.lastName,
                companyName: verifyRes.userProfile.companyName,
                companyTitle: verifyRes.userProfile.companyTitle,
                applicationName: verifyRes.userProfile?.applicationName,
              }
            : prev;
        });
        onboardingScenario === OnboardingScenario.NEW_USER_ONBOARDING &&
          setOnboardingScenario(OnboardingScenario.KS_USER_SIGN_UP);
        onNextStep();
      }
      // New user on KON
      else {
        setUserOnboardingInfo((prev) => {
          return {
            ...prev,
            actionToken: verifyRes.actionToken,
          };
        });
        setOnboardingScenario(OnboardingScenario.NEW_USER_ONBOARDING);
        onNextStep();
      }
    } catch (e) {
      composeErrorHandlers((next) => (e) => {
        if (isAPIError(e)) {
          switch (e.errors[0]?.key) {
            case "Otp.Invalid":
              helpers.setFieldError("otp", t(OTP_ERROR_MESSAGE));
              helpers.setFieldValue("otp", "");
              break;
            default:
              next(e);
          }
        } else {
          next(e);
        }
      })(e as GenericError);
    }
  };

  return (
    <div className="relative w-full">
      <TitleSection
        title={`${t("public.onboarding.OTPFormTitle")}`}
        description={
          <>
            <span>{t("public.onboarding.OTPFormTitleHint")}</span>
            <span className="text-light-blue">{userOnboardingInfo.email}</span>
          </>
        }
      />
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleOTPFormSubmit}
        initialValues={{ otp: "" }}
      >
        {({ errors, handleSubmit }) => {
          return (
            <Form
              className=""
              autoComplete="off"
              noValidate
              onKeyDown={(e) => {
                e.key === "Enter" && handleSubmit();
              }}
            >
              <OTPFormBody />
              {errors.otp && (
                <Message
                  variant="warning"
                  className="mt-1 text-xs font-medium"
                  isBackgroundEnable={false}
                  isIconShow={false}
                >
                  {errors.otp}
                </Message>
              )}

              <OTPResend />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
