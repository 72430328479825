import { UploadResponse } from "api/attachment";
import { PDFFile, UploadedFile } from "features/public/types";
import {
  isFileDuplicate,
  SUPPORT_REQUEST_FILES_UPLOAD_LIMIT_ERROR,
} from "features/public/utils/onboarding";
import { t } from "i18next";
import { cloneDeep } from "lodash";

export const getNonDuplicatedFiles = (
  uploadingFiles: File[],
  uploadedFiles: PDFFile[]
) => {
  return uploadingFiles.filter(
    (f) =>
      !uploadedFiles?.some(
        (uploadedFile) =>
          uploadedFile.name === f.name && uploadedFile.size === f.size
      )
  );
};

export const supportRequestUploadAndHandleProgressBar = async (
  uploadingFiles: File[],
  uploadedFiles: PDFFile[],
  api: (
    file: File,
    onUploadProgress: (loaded: number) => void,
    onCancelFile: () => void
  ) => Promise<UploadResponse>,
  updateProgressBar: (value: PDFFile[]) => void,
  setError: (value: string | undefined) => void
): Promise<UploadedFile[]> => {
  try {
    const result = await Promise.allSettled(
      uploadingFiles.map(async (file) => {
        let loadedValue = 0;
        const localURL = URL.createObjectURL(file);
        return api(
          file,
          (loaded: number) => {
            loadedValue = loaded;
            const existedFile = uploadedFiles.find((f) => {
              return isFileDuplicate(f, file.name, file.size);
            });
            if (!existedFile) {
              const newFile: PDFFile = {
                name: file.name,
                size: file.size,
                loaded,
                URL: localURL,
              };
              uploadedFiles.push(newFile);
            } else {
              // update progress value
              existedFile.loaded = loaded;
            }
            // display progress value
            updateProgressBar(cloneDeep(uploadedFiles));
          },
          () => {}
        ).then((data) => {
          return new Promise<UploadedFile>((resolve) => {
            resolve({
              id: data.attId,
              size: file.size,
              name: file.name,
              loaded: loadedValue,
              URL: localURL,
            });
          });
        });
      })
    );

    // Handle files upload rate limit
    const successResult = (
      result.filter(
        (it) => it.status === "fulfilled"
      ) as PromiseFulfilledResult<UploadedFile>[]
    ).map((it) => it.value);
    if (successResult.length !== result.length) {
      setError(t(SUPPORT_REQUEST_FILES_UPLOAD_LIMIT_ERROR));
    }
    return successResult;
  } catch (e) {
    console.error(e);
    return [];
  }
};
