import { ReactComponent as SvgBuildings } from "assets/svg/buildings.svg";
import { ReactComponent as SvgMail } from "assets/svg/mail.svg";
import { ReactComponent as SvgSitemap } from "assets/svg/sitemap.svg";
import { Button } from "common/components/Button";
import { GridWrapper } from "common/components/GridWrapper";
import {
  UserProfileProps,
  ItemLabel,
  Divider,
  Wrapper,
} from "features/userSettings/components/UserSettingsView";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw, { styled } from "twin.macro";

// Note that the left and right padding of the card should be included in grid 12 (grid-cols-12)
export const ProfileSettingCardLeftContainer = styled.div(() => [
  tw`col-span-9 pl-8`,
]);

export const ProfileSettingCardRightContainer = styled.div(() => [
  tw`col-span-3 justify-self-end pr-8`,
]);

const ProfileImage = styled.span(() => [
  tw`flex items-center justify-start text-dark-grey`,
]);
const ProfileValue = styled.span(() => [
  tw`text-dark-grey text-base leading-5.5`,
]);

export const PersonalDetails = ({ userProfile }: UserProfileProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleEditProfile = () => {
    navigate("/settings/user-profile/edit-profile");
  };
  return (
    <div className="w-full" data-cy="user-profile">
      <div className="flex flex-col w-full px-8">
        <ItemLabel>{t("settings.personalDetail.title")}</ItemLabel>
        <Divider />
      </div>
      <GridWrapper>
        <ProfileSettingCardLeftContainer className="flex flex-col flex-1 break-all ">
          <ItemLabel className="break-all block">
            {`${userProfile?.firstName || ""} ${userProfile?.lastName || ""}`}
          </ItemLabel>
          <div className="flex flex-col gap-[9px] mt-[18px]">
            <Wrapper>
              <ProfileImage>
                <SvgMail className="mr-3" />
              </ProfileImage>
              <ProfileValue className="break-all">
                {userProfile?.email || ""}
              </ProfileValue>
            </Wrapper>
            {userProfile?.companyName && (
              <Wrapper>
                <ProfileImage>
                  <SvgBuildings className="mr-3" />
                </ProfileImage>
                <ProfileValue>{userProfile.companyName}</ProfileValue>
              </Wrapper>
            )}
            {userProfile?.companyTitle && (
              <Wrapper>
                <ProfileImage>
                  <SvgSitemap className="mr-3" />
                </ProfileImage>
                <ProfileValue>{userProfile.companyTitle}</ProfileValue>
              </Wrapper>
            )}
          </div>
        </ProfileSettingCardLeftContainer>
        <ProfileSettingCardRightContainer>
          <Button
            variant="primary"
            className="px-9 h-12 text-base tracking-wider"
            onClick={handleEditProfile}
          >
            {t("settings.personalDetail.edit")}
          </Button>
        </ProfileSettingCardRightContainer>
      </GridWrapper>
    </div>
  );
};
