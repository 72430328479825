import tw, { styled } from "twin.macro";

type ButtonProps = {
  variant?: "primary" | "secondary" | "white" | "lightBlue";
};

export const Button = styled.button(({ variant }: ButtonProps) => [
  tw`rounded-lg py-3 min-w-[120px] h-12 flex justify-center items-center`,
  tw`text-dark-grey font-bold leading-4.5`,
  tw`bg-background-grey hover:bg-greyscale`,
  variant === "primary" && tw`bg-light-blue text-white hover:bg-navy`,
  variant === "secondary" &&
    tw`bg-background-grey text-light-blue hover:bg-pale-blue hover:text-white`,
  tw`disabled:bg-grey disabled:text-white`,
  variant === "white" && tw`bg-white text-light-blue`,
  variant === "lightBlue" && tw`bg-white text-light-blue hover:bg-white`,
]);
