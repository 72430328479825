import { createContext, Dispatch, SetStateAction, useState } from "react";

export enum AuthError {
  EXPIRY = "EXPIRY",
  CONCURRENT_LOGIN = "CONCURRENT_LOGIN",
}

type AuthErrorContextProps = {
  authError: AuthError | undefined;
  setAuthError: Dispatch<SetStateAction<AuthError | undefined>>;
};

export const AuthErrorContext = createContext<AuthErrorContextProps>({
  authError: undefined,
  setAuthError: () => {},
});

type AuthErrorContextProviderProps = {
  children: React.ReactNode;
};

export const AuthErrorContextProvider = ({
  children,
}: AuthErrorContextProviderProps) => {
  const [authError, setAuthError] = useState<AuthError>();

  return (
    <AuthErrorContext.Provider value={{ authError, setAuthError }}>
      {children}
    </AuthErrorContext.Provider>
  );
};
