import { ReactComponent as SvgWarning } from "assets/svg/warning.svg";
import { Button } from "common/components/Button";
import { useTranslation } from "react-i18next";
export const ExpireActionTokenPopup = ({
  onRefresh,
}: {
  onRefresh: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center flex-col">
      <SvgWarning
        name="cancel"
        width="48"
        height="48"
        className="text-light-blue mb-10"
      />
      <div className="w-full mb-8 text-center font-medium leading-[22px] px-12">
        {t("public.onboarding.sessionExpiredTitle")}
      </div>

      <p className="mb-6 text-center text-xs text-dark-grey">
        {t("public.onboarding.sessionExpiredDesc")}
      </p>

      <Button className="w-full" onClick={onRefresh} variant="primary">
        {t("common.refresh")}
      </Button>
    </div>
  );
};
