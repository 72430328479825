import accountPage from "assets/image/account-page.png";
import logo from "assets/image/logo.png";
import { VersionDisplay } from "common/components/VersionDisplay";
import { LanguageSwitch } from "features/public/components/LanguageSwitch";
import React from "react";

export const PublicLayout = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <div className="grid grid-cols-12 w-screen h-screen">
      <div className="col-span-6 relative">
        <div className="absolute bg-navy h-full w-full z-window" />
        <img
          className="absolute h-full w-full object-cover opacity-50 z-fixed"
          src={accountPage}
          alt=""
        />
        <img
          className="absolute z-icon left-[40px] top-[44px] w-1/12 h-1/12"
          src={logo}
          alt="logo"
        />
      </div>
      <div className="relative grid grid-cols-6 col-span-6 gap-x-5 px-6">
        <VersionDisplay versionCondition=">= 2.0.0">
          <LanguageSwitch
            currentLanguage="English"
            className="absolute top-[78px] col-start-5 col-end-6 w-full z-fixed"
          />
        </VersionDisplay>
        {children}
      </div>
    </div>
  );
};
