import KentroFooterLogo from "assets/images/kentro-footer-logo.png";
import tw, { styled } from "twin.macro";

const Divider = styled.div(() => [tw`h-[1px] bg-dark-greyscale my-5`]);

type FooterProps = {
  className: string;
};

export const Footer = ({ className }: FooterProps) => {
  return (
    <div
      className={`${className} bg-light-blue text-white font-medium text-xs py-6 px-[130px]`}
    >
      <div>
        <img src={KentroFooterLogo} alt="Kentro Footer Logo" />
      </div>
      <Divider />
      <div>
        <span className="mr-[60px]">
          © 2022 CryptoBLK Limited All rights reserved
        </span>
        <span className="mr-6">Privacy Policy</span>
        <span>Terms of Use</span>
      </div>
    </div>
  );
};
