import { useModal } from "@liholiho/react-modal-hook";
import { SelectOption } from "common/components/Form/SelectField";
import { Modal } from "common/components/Modal";
import { useAuth } from "common/hooks";
import { useInfiniteAppList } from "common/hooks/useInfiniteAppList";
import {
  EnquireMorePopup,
  EnquirePopupHandlerProps,
} from "features/applicationDashboard/components/EnquireMorePopup";
import { useMemo, useState } from "react";
export const useEnquirePopupHandler = (): EnquirePopupHandlerProps => {
  const [defaultOption, setDefaultOption] = useState<SelectOption>();
  const { currentUserProfile } = useAuth();
  const { apps } = useInfiniteAppList();

  const options: SelectOption[] = useMemo(() => {
    return apps.map((app, index) => {
      return {
        label: app.name,
        value: app.id,
        index: index,
      };
    });
  }, [apps]);

  const [show, hide] = useModal(
    ({ in: isOpen }) => {
      return (
        <Modal open={isOpen} onClose={hide} size="md">
          <EnquireMorePopup
            options={options}
            hideModal={hide}
            defaultOption={defaultOption}
            userProfile={currentUserProfile}
          />
        </Modal>
      );
    },
    [defaultOption, options]
  );

  const showEnquireMorePopup: EnquirePopupHandlerProps["handler"] = (app) => {
    const option: SelectOption = {
      label: app.name,
      value: app.id,
    };
    setDefaultOption(option);
    show();
  };

  return {
    handler: showEnquireMorePopup,
  };
};
