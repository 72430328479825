type TitleProps = {
  title: string;
  className?: string;
};

export const Title = ({ title, className }: TitleProps): JSX.Element => {
  return (
    <div className={className && className}>
      <h3 className="text-light-blue font-bold">{title}</h3>
    </div>
  );
};
