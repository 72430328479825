import { request } from "common/HOC/WithRequest";

export type UserProfileResponse = {
  email: string;
  firstName: string;
  lastName: string;
  applicationName: string;
  companyName: string;
  companyTitle: string;
};

export type VerifyOtpResponse = {
  actionToken: string;
  resetPasswordToken: string | null;
  userProfile: UserProfileResponse | null;
  lastLoginAt: string | null;
  createdAt: string | null;
};
const prefix = "public/otp";
export const publicOtpApi = {
  requestOtp: (email: string, firstName: string, lastName: string) =>
    request(`${prefix}`, "POST", { email, firstName, lastName }),
  verifyOtp: (email: string, otp: string): Promise<VerifyOtpResponse> =>
    request(`${prefix}/validate`, "POST", {
      email,
      inputOtp: otp,
    }),
};
