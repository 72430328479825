import { LatestAppsContext } from "common/context/LatestAppsContext";
import { ApplicationInfo } from "features/applicationDashboard/types";
import _ from "lodash";
import { useContext, useEffect } from "react";

export const useUpdateApps = (
  currentApps: ApplicationInfo[] | undefined
): {
  apps: ApplicationInfo[] | undefined;
} => {
  const { apps, setApps } = useContext(LatestAppsContext);

  useEffect(() => {
    const isNewAppsData = !_.isEqual(currentApps, apps);
    if (isNewAppsData) {
      setApps(currentApps);
    }
  }, [apps, currentApps, setApps]);

  return { apps };
};
