import { FILE_TYPE, MAX_FILE_SIZE } from "constants/file";

import classNames from "classnames";
import { transformFileTypeToFileExtension } from "common/utils/common";
import {
  FILE_SIZE_EXCEED_ERROR,
  FILE_TYPE_INVALID_ERROR,
  TOO_MANY_FILES_ERROR,
} from "features/public/utils/onboarding";
import { t } from "i18next";
import { Dispatch, SetStateAction, useCallback } from "react";
import { ErrorCode, FileRejection, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

const DropzoneContainer = tw.div`h-full border-2 border-dashed flex flex-col items-center justify-center`;

type DropzoneProps = {
  className?: string;
  onChange: (files: File[]) => void;
  children?: (open: () => void) => React.ReactNode;
  acceptFileType?: FILE_TYPE | FILE_TYPE[];
  errorMsgPosition?: "under" | "inner";
  isDisabled?: boolean;
  error: string | undefined;
  setError: Dispatch<SetStateAction<string | undefined>>;
};

export const Dropzone = ({
  className,
  onChange,
  error,
  setError,
  children,
  isDisabled = false,
  acceptFileType = FILE_TYPE.PDF,
  errorMsgPosition = "inner",
}: DropzoneProps): JSX.Element => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setError(undefined);
      if (rejectedFiles.length > 0) {
        const rejectedErrors = rejectedFiles.map((it) => it.errors[0].code);
        let errorMsg;
        switch (true) {
          case rejectedErrors.includes(ErrorCode.TooManyFiles):
            setError(t(TOO_MANY_FILES_ERROR));
            break;

          case rejectedErrors.includes(ErrorCode.FileInvalidType):
            errorMsg = getErrorMessageByErrorCode(
              ErrorCode.FileInvalidType,
              acceptFileType
            );
            setError(errorMsg);
            break;

          case rejectedErrors.includes(ErrorCode.FileTooLarge):
            errorMsg = getErrorMessageByErrorCode(
              ErrorCode.FileTooLarge,
              acceptFileType
            );
            setError(errorMsg);
            break;
        }
      }
      if (acceptedFiles.length > 0) {
        onChange(acceptedFiles);
      }
    },
    [setError, acceptFileType, onChange, t]
  );
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: transformFileTypeToFileExtension(acceptFileType), // use file extension instead of mime type for specific file blocker
    maxSize: MAX_FILE_SIZE,
    onDrop,
    noClick: true,
    noKeyboard: true,
    disabled: isDisabled,
  });

  return (
    <>
      <DropzoneContainer
        {...getRootProps({
          role: "div",
        })}
        className={classNames(className, {
          "bg-error-red bg-opacity-10 border-light-red": !!error,
          "bg-white border-grey": !error,
        })}
      >
        <input
          disabled={isDisabled}
          {...getInputProps()}
          data-cy="input-file"
        />
        {children && children(open)}
      </DropzoneContainer>
      {error && errorMsgPosition === "under" && (
        <div className="text-error-red text-xs font-medium mt-1">{error}</div>
      )}
    </>
  );
};

export const getErrorMessageByErrorCode = (
  errorCode: ErrorCode,
  acceptFileType: FILE_TYPE | FILE_TYPE[]
) => {
  const acceptFileTypeText =
    typeof acceptFileType === "string" ? acceptFileType : acceptFileType.join();

  const errorMessage =
    errorCode === ErrorCode.FileInvalidType
      ? t(FILE_TYPE_INVALID_ERROR, { fileType: acceptFileTypeText })
      : t(FILE_SIZE_EXCEED_ERROR);
  return errorMessage;
};
