import { ReactComponent as SvgLeftArrow } from "assets/svg/left-arrow.svg";
import { Container, ContentWrapper } from "features/PublicRoutes";
import { BackButton } from "features/RegisterRoutes";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const ForgotPasswordLayout = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <BackButton
        onClick={() => {
          navigate("/public");
        }}
      >
        <SvgLeftArrow className="mr-3" />
        <span>{t("common.back")}</span>
      </BackButton>

      <ContentWrapper>
        <Container>
          <div className="col-start-2 col-end-6 flex items-center justify-center">
            {children}
          </div>
        </Container>
      </ContentWrapper>
    </>
  );
};
