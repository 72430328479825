import { useRegisterPopupHandler } from "features/public/Register/hooks/useRegisterPopupHandler";
import { useUserOnboardingInfo } from "features/public/Register/hooks/useUserOnboardingInfo";
import { OnboardingUrlMap } from "features/public/Register/types";
import { RegisterPopupStepMap } from "features/public/utils/onboarding";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useRegisterPopupByScenario = () => {
  const location = useLocation();
  const { show: showRegisterPopup } = useRegisterPopupHandler();
  const { currentRoute, onboardingScenario } = useUserOnboardingInfo();

  useEffect(() => {
    if (
      currentRoute === RegisterPopupStepMap[onboardingScenario] &&
      location.pathname.includes(OnboardingUrlMap[currentRoute])
    ) {
      showRegisterPopup();
    }
  }, [currentRoute, onboardingScenario, location.pathname, showRegisterPopup]);
};
