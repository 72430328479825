import { AppInfo } from "api/dashboard";
import AtlasEliteLogo from "assets/images/atlas-elite-vertical.png";
import KentroSignLogo from "assets/images/kentro-sign-vertical.png";
import MidasLogo from "assets/images/midas-vertical.png";
import TopazLogo from "assets/images/topaz-vertical.png";
import { KENTRO_SIGN_DOMAIN } from "common/utils/cross-domain/react/config";
import {
  ApplicationName,
  ApplicationIcon,
  Categories,
  ApplicationDisplayName,
  ApplicationInfo,
  RoutesPath,
} from "features/applicationDashboard/types";
import { SubscriptionInfo } from "types/user";

export const getApplicationText = (appName: ApplicationName) => {
  switch (appName) {
    case ApplicationName.AtlasElite:
      return {
        name: "application.atlasElite.name",
        description: "application.atlasElite.description",
      };
    case ApplicationName.KentroSign:
      return {
        name: "application.kentroSign.name",
        description: "application.kentroSign.description",
      };
    case ApplicationName.Midas:
      return {
        name: "application.midas.name",
        description: "application.midas.description",
      };
    case ApplicationName.Topaz:
      return {
        name: "application.topaz.name",
        description: "application.topaz.description",
      };
    default:
      return {
        name: appName,
        description: appName,
      };
  }
};

export const getAppIcon = (appName: ApplicationName): ApplicationIcon => {
  switch (appName) {
    case ApplicationName.AtlasElite:
      return {
        src: AtlasEliteLogo,
        alt: "Atlas Elite",
        url: "",
      };
    case ApplicationName.KentroSign:
      return {
        src: KentroSignLogo,
        alt: "Kentro Sign",
        url: KENTRO_SIGN_DOMAIN,
      };
    case ApplicationName.Midas:
      return {
        src: MidasLogo,
        alt: "Midas",
        url: "",
      };
    case ApplicationName.Topaz:
      return {
        src: TopazLogo,
        alt: "Topaz",
        url: "",
      };
    default:
      return {
        src: appName,
        alt: appName,
        url: "",
      };
  }
};

// TODO: If API offer this property in AppInfo can delete this function
export const getAppCategory = (appName: ApplicationName) => {
  switch (appName) {
    case ApplicationName.AtlasElite:
      return Categories.FINANCE;
    case ApplicationName.KentroSign:
      return Categories.TOOLS;
    case ApplicationName.Midas:
      return Categories.INSURANCE;
    case ApplicationName.Topaz:
      return Categories.CONSTRUCTION;
    default:
      return Categories.TOOLS;
  }
};

export const getTabText = (tab: Categories): string => {
  switch (tab) {
    case Categories.CONSTRUCTION:
      return "application.tab.construction";
    case Categories.FINANCE:
      return "application.tab.finance";
    case Categories.INSURANCE:
      return "application.tab.insurance";
    case Categories.TOOLS:
      return "application.tab.tools";
    case Categories.ALL:
      return "application.tab.all";
    default:
      return "";
  }
};

export const getRoutesPathByAppName = (name: ApplicationDisplayName) => {
  switch (name) {
    case ApplicationDisplayName.AtlasElite:
      return RoutesPath.AtlasElite;
    case ApplicationDisplayName.KentroSign:
      return RoutesPath.KentroSign;
    case ApplicationDisplayName.Midas:
      return RoutesPath.Midas;
    case ApplicationDisplayName.Topaz:
      return RoutesPath.Topaz;
    default:
      return "";
  }
};

export const generateFullApplicationInfoByAppInfo = (
  appData: AppInfo,
  subscriptionInfo: SubscriptionInfo[]
): ApplicationInfo => {
  const subscriptionMap = subscriptionInfo.reduce((acc, curr) => {
    acc.set(curr.app.id, curr.isProfileSetup);
    return acc;
  }, new Map<string, boolean>());

  const appName = appData.app.appName as ApplicationName;
  const appFullInfo: ApplicationInfo = {
    id: appData.app.id,
    icon: getAppIcon(appName),
    name: getApplicationText(appName).name,
    description: getApplicationText(appName).description,
    category: getAppCategory(appName), // TODO: if api offer can delete
    isSubscribed: appData.enabled,
    isProfileSetup: !!subscriptionMap.get(appData.app.id),
  };
  return appFullInfo;
};
