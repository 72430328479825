import { ContentSection } from "features/public/Register/components/RegisterPoup/ContentSection";
import { SectionHeading } from "features/public/Register/components/RegisterPoup/RegisterPopup";
import { SectionContent } from "features/public/Register/components/RegisterPoup/SectionContent";

export const TermOfUse = () => (
  <>
    <div>
      <h1 className="font-bold text-h1 text-light-blue">Terms of Use</h1>
      <p className="mt-4 text-dark-grey">Last updated date: 4 November 2022</p>
    </div>
    <div className="mt-10">
      <SectionHeading>PART A - GENERAL TERMS</SectionHeading>

      <ContentSection index="1.">
        <SectionHeading>THESE TERMS</SectionHeading>
        <SectionContent index="1.1">
          These terms of use, as updated from time to time, govern your use and
          access to our Platform, our Licensed Software, our Licensed
          Documentation, our Services, our web portals, our application
          programming interfaces (APIs), our notifications and any information
          or content appearing therein (collectively our “<b>Offerings</b>”).
        </SectionContent>
        <SectionContent index="1.2">
          These terms of uses contain the terms and conditions that govern your
          access to and use of the Offerings provided by us and is an agreement
          between us and you or the entity you represent.
        </SectionContent>
        <SectionContent index="1.3">
          BY CLICKING A BUTTON OR CHECKBOX TO ACCEPT OR AGREE TO THESE TERMS
          WHERE THAT OPTION IS MADE AVAILABLE, CLICKING A BUTTON TO USE OR
          ACCESS ANY OF THE OFFERINGS, OR, IF EARLIER, USING OR OTHERWISE
          ACCESSING THE OFFERINGS (THE DATE ON WHICH ANY OF THE EVENTS LISTED
          ABOVE OCCUR BEING THE “<b>EFFECTIVE DATE</b>”), YOU (1) ACCEPT AND
          AGREE TO THESE TERMS OF USE AND ANY ADDITIONAL TERMS, RULES AND
          CONDITIONS ISSUED BY US FROM TIME TO TIME AND (2) CONSENT TO THE
          COLLECTION, USE, DISCLOSURE AND OTHER HANDLING OF INFORMATION AS
          DESCRIBED IN OUR PRIVACY POLICY.
        </SectionContent>
        <SectionContent index="1.4">
          IF YOU DO NOT AGREE WITH ANY OR ALL OF THESE TERMS, THEN YOU ARE
          EXPRESSLY PROHIBITED FROM USING THE OFFERINGS AND YOU MUST DISCONTINUE
          USE IMMEDIATELY.
        </SectionContent>
        <SectionContent index="1.5">
          “<b>User</b>” refers to you individually. IF YOU ARE AGREEING TO THESE
          TERMS AS A REPRESENTATIVE OF AN ENTITY, YOU REPRESENT THAT YOU HAVE
          THE AUTHORITY TO BIND THAT ENTITY AND “<b>USER</b>” SHALL ALSO REFER
          TO THAT ENTITY ACCESSING THE OFFERINGS BY, THROUGH OR ON BEHALF OF THE
          USER. CryptoBLK and User are each a “<b>party</b>” and, together, are
          the “<b>parties.</b>”
        </SectionContent>
      </ContentSection>

      <ContentSection index="2.">
        <div className="text-dark-blue">
          <p>
            In these terms of use, the following terms have the following
            meanings unless the context otherwise requires (such meanings to be
            equally applicable to both the singular and plural forms of the
            terms defined):
          </p>
          <br />
          <p>
            “<b>Adaptations and Modifications</b>” means, with respect to any
            Intellectual Property, all derivative works thereof, developments
            therefrom or improvements or enhancements thereto. The term “
            <b>Adapt and Modify</b>” shall mean to make, have made, and create
            derivative works, improvements, and enhancements.
          </p>
          <br />
          <p>
            “<b>CryptoBLK Indemnitee</b>” means CryptoBLK, its Affiliates, the
            officers, employees, agents and Representatives of CryptoBLK and of
            its Affiliates.
          </p>
          <br />
          <p>
            “<b>CryptoBLK IP</b>” shall have the meaning as ascribed to it in
            section 15.1.
          </p>
          <br />
          <p>
            “<b>CryptoBLK Marks</b>” shall have the meaning as ascribed to it in
            section 16.1.
          </p>
          <br />
          <p>
            “Customer” means the party who has entered into the Master Agreement
            with CryptoBLK for the use of the Offerings in consideration of fees
            and shall be a User hereof itself, who may authorise directly and
            indirectly certain entities and individuals to also become a User
            hereof pursuant to the terms of the Master Agreement.
          </p>
          <br />
          <p>
            “<b>Designee</b>” means the corporate vendors, contractors and/or
            designees with whom the Customer transacts business with for the
            Customer’s internal business purposes who is authorised by the
            Customer to use the offerings and be a User hereof itself pursuant
            to the terms of the Master Agreement.
          </p>
          <br />
          <div>
            “<b>Force Majeure Events</b>” means, in relation to either Party,
            any circumstances beyond the reasonable control of that Party
            including:
            <div className="ml-7 mt-4">
              <SectionContent className="mb-[16px]" index="(a)">
                Act of God, explosion, flood, tempest, fire or accident;
              </SectionContent>
              <SectionContent className="mb-[16px]" index="(b)">
                war or threat of war, acts of terrorism, sabotage, insurrection,
                civil disturbance or requisition;
              </SectionContent>
              <SectionContent className="mb-[16px]" index="(c)">
                floods, earthquakes, hurricanes, epidemics or pandemics;
              </SectionContent>
              <SectionContent className="mb-[16px]" index="(d)">
                acts, restrictions, regulations, bye-laws, prohibitions or
                measures of any kind on the part of any governmental,
                parliamentary or local authority;
              </SectionContent>
              <SectionContent className="mb-[16px]" index="(e)">
                import or export regulations or embargoes;
              </SectionContent>
              <SectionContent className="mb-[16px]" index="(f)">
                strikes, lock-outs or other industrial actions or trade
                disputes;
              </SectionContent>
              <SectionContent className="mb-[16px]" index="(g)">
                power failure or breakdown in machinery.
              </SectionContent>
            </div>
          </div>
          <br />
          <p>
            “Governmental Authority” means any foreign or federal, state,
            national, provincial, or local government or political subdividision
            thereof, or any multinational organization or any authority, agency
            or commission entitled to exercise any administrative, executive,
            judicial, legislative, police, regulatory or taxing authority or
            power, any court or tribunal (or any department, bureau or division
            thereof, or any governmental arbitrator or arbitral body)
          </p>
          <br />
          <p>
            “Harmful Code” means any computer code that is intended or known to
            be harmful, destructive, disabling or which assists in or enables
            theft, alteration, denial of service, unauthorised disclosure or
            destruction or corruption of data including viruses, worms, spyware,
            adware, keyloggers, trojans, and any new types of programmed threats
            that may be classified, but excludes passwords, trial period
            software and like features which are security features or intended
            elements of the software solely used to prevent unauthorised use of
            the software in contravention of a licence.
          </p>
          <br />
          <p>
            “<b>Licensed Documentation</b>” means the documentation relating to
            the Licensed Software provided by CryptoBLK, as amended from time to
            time;
          </p>
          <br />
          <p>
            “<b>Licensed Software</b>” means the software and its updates
            (including related integration, implementation and configuration
            from CryptoBLK as part of the Services) licensed by CryptoBLK to a
            User;
          </p>
          <br />
          <p>
            “Object Code” means computer programs assembled or compiled in
            magnetic or electronic binary form on software media, which are
            readable and useable by machines, but not generally readable by
            humans without reverse assembly, reverse compiling, or reverse
            engineering.
          </p>
          <br />
          <p>
            “Order Form” means the standard form in Annex 2 of the Master
            Agreement, which shall be completed, executed and delivered by
            CryptoBLK and a Customer for ordering Software licenses and/or
            Services.
          </p>
          <br />
          <p>
            “<b>Person</b>” includes a legal person, incorporated or
            unincorporated entities.
          </p>
          <br />
          <p>
            “<b>Platform</b>” means <i>Kentro™</i>, a software platform
            developed by CryptoBLK, which offers software, services,
            applications, utilities and databases.
          </p>
          <br />
          <p>
            “<b>Relevant IP</b>” means all relevant Intellectual Property Rights
            of CryptoBLK or any other person in relation to the Licensed
            Software and the Licensed Documentation.
          </p>
          <br />
          <div>
            “<b>Relief Event</b>” means any of the following:
            <div className="ml-7 mt-4">
              <SectionContent className="mb-[16px]" index="(h)">
                The failure of, or problems relating to, network services,
                connections, software, firmware or equipment not under the
                control of CryptoBLK;
              </SectionContent>
              <SectionContent className="mb-[16px]" index="(i)">
                Misuse or abuse of the Licensed Software by any Person,
                including the User, other than CryptoBLK;
              </SectionContent>
              <SectionContent className="mb-[16px]" index="(j)">
                Failure or delay to perform any of its obligations by the User
                as set out in the Master Agreement;
              </SectionContent>
              <SectionContent className="mb-[16px]" index="(k)">
                Use of installation of the Licensed Software in a way not
                permitted under the Master Agreement or the Licensed
                Documentation by any Person, including the User, other than
                CryptoBLK;
              </SectionContent>
              <SectionContent className="mb-[16px]" index="(l)">
                Any Force Majeure Event;
              </SectionContent>
              <SectionContent className="mb-[16px]" index="(m)">
                Cyber-attacks, meaning the use or operation, as a means of
                inflicting harm, of any computer, computer system, computer
                software programme, malicious code, computer virus or process or
                any other electronic system, whether targeted or not, whether
                malicious or not.
              </SectionContent>
            </div>
          </div>
          <br />
          <p>
            “Representatives” means a Person acting on behalf of another Person,
            with fully documented authorities to act and the scope of such
            actions.
          </p>
          <br />
          <p>
            “<b>Services</b>” means the services to be provided by CryptoBLK to
            a User.
          </p>
          <br />
          <p>
            “<b>Third Party</b>” means a Person other than me or you, who may or
            may not be another User of the Offerings.
          </p>
          <br />
          <p>
            “<b>Third Party Claim</b>” means any written assertion by a Third
            Party of any claim, or the commence by any such Third Party of any
            action against CryptoBLK Indemnitees or User Indemnitees.
          </p>
          <br />
          <p>
            “<b>Third Party Services</b>” means any services which are provided
            by any Third Party received by a User in connection with or arising
            from the Use of the Offerings.
          </p>
          <br />
          <p>
            “<b>Third Party Software</b>” means any software or application
            which is proprietary to any Third Party Used by a User in connection
            with or arising from the Use of the Offerings.
          </p>
          <br />
          <p>
            “Use” means to activate the processing capabilities of the
            Offerings, load, execute, access, employ the Offerings or display or
            general information resulting form such capabilities.
          </p>
          <br />
          <p>
            “<b>User Data</b>” shall have the meaning as ascribed to it in
            section 9.1.
          </p>
          <br />
          <p>
            “<b>User Indemnitees</b>” means the User, its Affiliates as
            authorised to Use the Licensed Software pursuant to the Master
            Agreement, the officers, employees, agents and Representatives of
            the User and such authorised Affiliates.
          </p>
          <br />
          <p>
            “<b>User Marks</b>” means the Users’ trademarks and logos.
          </p>
          <br />
          <p>
            “<b>Master Agreement</b>” means the Master Platform-as-a-Service
            Agreement entered by Customer and CryptoBLK in relation to the use
            of the Offerings, together with its schedules and annexes, as
            amended and supplemented from time to time, together with the Order
            Form(s), these terms of use, the Privacy Policy and other
            operational guides and protocol documents as may be provided to the
            User by CryptoBLK from time to time, each of which shall by
            reference deemed as incorporated into the terms of the Master
            Agreement. In these terms of use, unless the context otherwise
            requires, each reference to “<b>MASTER AGREEMENT</b>” shall to the
            largest extent applicable, include the Order Form(s), these terms of
            use, the Privacy Policy and other operational guides and protocol
            documents.
          </p>
        </div>
      </ContentSection>

      <ContentSection index="3.">
        <SectionHeading>ABOUT US AND HOW TO CONTACT US</SectionHeading>
        <SectionContent index="3.1">
          We are CRYPTOBLK LIMITED, a private company having its own office of
          business at Units 505 – 510, 5/F., 1E Core Building, 1 Science Park
          East Avenue, Hong Kong Science Park, Shatin, New Territories, Hong
          Kong (hereinafter referred to as “<b>CryptoBLK</b>” or “<b>we</b>”).
        </SectionContent>
        <SectionContent index="3.2">
          For any questions or problems relating to our Offerings, these terms,
          the Privacy Policy or the Master Agreement, you can contact us by
          telephoning our customer service team at +852 3611 2668 or emailing us
          at{" "}
          <a className="text-light-blue" href="mailto:info@kentro.network">
            info@kentro.network
          </a>{" "}
          or writing to us at Units 505-510, 5/F, Core Building 1, No. 1 Science
          Park East Avenue, Hong Kong Science Park, Pak Shek Kok, N.T. Hong
          Kong.
        </SectionContent>
        <SectionContent index="3.3">
          When we use the words “writing” or “written” in these terms, this
          includes emails.
        </SectionContent>
      </ContentSection>

      <ContentSection index="4.">
        <SectionHeading>MASTER AGREEMENT</SectionHeading>
        <SectionContent index="4.1">
          Each User shall comply with terms set out therein and all the terms in
          the Master Agreement to the largest extent applicable including but
          not limited to all the provisions relating to the grant of licences
          and the use of the Offerings. Unless the context otherwise requires,
          each reference to the “Customer” under the Master Agreement shall also
          include you, to the extent that you are accessing or using the
          Offerings as authorised by the Customer pursuant to the terms of the
          Master Agreement. In the event any term of contained in these terms of
          use conflicts with any term in an executed Master Agreement, the term
          in the Master Agreement shall govern. YOU SHALL REQUEST THE CUSTOMER
          WHO AUTHORISES YOUR USE OF THE OFFERINGS FOR A COPY OF THE SAAS
          AGREEMENT.
        </SectionContent>
      </ContentSection>

      <ContentSection index="5.">
        <SectionHeading>CHANGES OF TERMS</SectionHeading>
        <SectionContent index="5.1">
          We may amend these terms from time to time by posting the updated
          terms on our Platform. If we make material changes, we may notify you
          of the changes before they become effective. By continuing to use our
          Offerings after the changes come into effect means that you agree to
          be bound by the revised policy.
        </SectionContent>
      </ContentSection>

      <ContentSection index="6.">
        <SectionHeading>
          THIRD PARTY PLATFORM / SOFTWARE / SERVICES
        </SectionHeading>
        <SectionContent index="6.1">
          If and where applicable, we may connect with, and receive and/or
          exchange information and data with, websites, platforms, portals
          and/or systems administered or managed by Third Parties (who may or
          may not be a User of the Platform) from other applicable
          jurisdictions. Some of these Third Parties may require a direct
          agreement with you. You shall agree to and comply with any such terms
          and shall indemnify and keep indemnified CryptoBLK against any loss or
          damage which CryptoBLK may suffer or incur as a result of your breach
          of such terms. CryptoBLK may treat your breach of the such additional
          agreement as a breach of this Agreement.
        </SectionContent>
        <SectionContent index="6.2">
          Certain Third Party Software and/or Third Party Services available in
          connection with the Use of the Offerings may be subject to end user
          license agreements and terms and conditions separately issued by such
          Third Party. CryptoBLK assumes no liability with respect to such Third
          Party Software and/or Third Party Services. Your use of such Third
          Party Software and/or Third Party Services is subject to the terms and
          conditions of the license accompanying such Third Party Software
          and/or Third Party Services. You shall comply with any such terms and
          shall indemnify and keep indemnified CryptoBLK against any loss or
          damage which CryptoBLK may suffer or incur as a result of your breach
          of such terms. CryptoBLK may treat your breach of the additional terms
          relating to Third Party Software and/or Third Party Services as a
          breach of this Agreement.
        </SectionContent>
        <SectionContent index="6.3">
          You acknowledge and agree that we shall not be responsible or liable,
          directly or indirectly, for any damage or loss caused or alleged to be
          caused by or in connection with your use of such Third Party Software
          and/or Third Party Services or your reliance on any data, content or
          resources, or any goods available on or through any Third Party’s
          websites, platforms, portals or applications.
        </SectionContent>
      </ContentSection>

      <ContentSection index="7.">
        <SectionHeading>AVAILABILITY OF OUR OFFERINGS</SectionHeading>
        <SectionContent index="7.1">
          We are constantly changing and improving the Offerings we provide. We
          may from time to time change or discontinue any of the products or
          services we offer, or add or remove functionalities or features, and
          we may suspend or stop certain products, services, functionalities or
          features altogether.
        </SectionContent>
        <SectionContent index="7.2">
          We may release products, services, functionalities or features that we
          are still testing and evaluating. We will label such services as
          “beta”, “preview”, “early access” or “trial” or any words or phrases
          with similar meanings. You understand that these beta services are not
          as reliable as other products or services we offer.
        </SectionContent>
        <SectionContent index="7.3">
          We try our best to ensure that our Offerings is always available, but
          we do not guarantee that the operation of or access to our Offerings
          will be uninterrupted or continuous. Our Offerings may be interrupted
          for maintenance, repairs, upgrades, network or equipment failures. We
          shall incur no responsibility or liability for any unavailability of
          our Offerings or for any loss, damage, costs and/or expenses of any
          kind or character that is or may be suffered or incurred as a result
          of any unavailability of our Offerings.
        </SectionContent>
        <SectionContent index="7.4">
          You are responsible for configuring your information technology,
          computer programmes, equipment or system in order to accommodate the
          blockchain protocol for the access and use of the Offerings via our
          web portal or API.
        </SectionContent>
      </ContentSection>

      <ContentSection index="8.">
        <SectionHeading>USE OF THE OFFERINGS</SectionHeading>
        <SectionContent index="8.1">
          We hereby grant you a limited, non-exclusive, non-transferable,
          non-sublicensable and revocable right and license to access and use
          the Offerings subject to these terms of use and the Master Agreement.
        </SectionContent>
        <SectionContent index="8.2">
          You must comply with the Acceptable Use Policy as set out in Annex 1
          hereto and all Applicable Laws in using or accessing the Offerings.
        </SectionContent>
      </ContentSection>

      <ContentSection index="9.">
        <SectionHeading>INFORMATION YOU SUBMIT</SectionHeading>
        <SectionContent index="9.1">
          “<b>User Data</b>” refers to information or content you submit to or
          display on or through the Offerings, or information or content created
          or transferred by you using the Offerings, including any data relating
          to transactions entered into by you and your trading partners.
        </SectionContent>
        <SectionContent index="9.2">
          You represent and warrant that you have all rights and permissions
          necessary to provide User Data to us and / or other relevant users.
          You warrant that User Data is not and will not infringe rights of any
          Third Parties and that you have all the necessary rights, power and
          authority to satisfy your obligations with regard to User Data under
          these terms.
        </SectionContent>
        <SectionContent index="9.3">
          You will not provide false or misleading User Data. You shall be
          liable for the accuracy, correctness, completeness, origin and
          integrity of such User Data. You shall promptly notify us and /or
          other relevant users if there is change or update to the User Data.
        </SectionContent>
        <SectionContent index="9.4">
          Subject to these terms of use, you retain your rights, title and
          interest, including all Intellectual Property Rights, in all User
          Data.
        </SectionContent>
        <SectionContent index="9.5">
          By making your User Data available to, or sharing your User Data
          through, the Offerings, you grant us a worldwide, limited,
          royalty-free, non-exclusive, non-transferrable right to use, host,
          store, reproduce, modify, create derivative works (such as those
          resulting from translations, adaptations or other changes we make so
          that your User Data works better with our Offerings) and create
          metadata derived from the User Data in aggregate form. We may also
          communicate, publish, and distribute User Data only as explicitly
          configured by you through the Offerings. The rights you grant in this
          license are for the limited purpose of operating, promoting, and
          improving our Offerings, and to develop new ones. This license
          continues even if you stop using our Offerings.
        </SectionContent>
        <SectionContent index="9.6">
          You may request that we delete any of User Data that you submit to the
          Offerings or exclude User Data from the megadata derived in aggregate
          form by notifying us. To the extent within our control we will remove
          User Data from the Offerings to the extent if possible and permitted
          by applicable law; however, it may persist in backup or residual
          copies for as long as those copies are maintained. For purposes of
          clarification, once you submit or share User Data with others via the
          Offerings (e.g., other users or third parties), we no longer have
          control over those User Data and will not be able to delete it or
          prevent them from using it.
        </SectionContent>
        <SectionContent index="9.7">
          You are responsible for your use of User Data and any consequences
          thereof, including any consequences of the use of User Data by other
          users or Third Parties. We are not responsible or liable for any use
          of User Data, nor the use of any content or information submitted by
          other Users or visitors.
        </SectionContent>
      </ContentSection>

      <ContentSection index="10.">
        <SectionHeading>USER ACCOUNTS AND ACCOUNT SECURITY</SectionHeading>
        <SectionContent index="10.1">
          In order to access the Offerings, you may be required to establish an
          account with us and provide certain registration information. You must
          provide true, complete, and accurate account information and promptly
          update this information if it changes. You must not share your access
          credentials with another party and must maintain the security of your
          account by implementing adequate security measures to safeguard your
          access credentials. You will promptly notify us if you discover or
          suspect that someone has accessed your account without your
          permission. You are responsible for all activities that occur in
          connection with your account and accept all risks of unauthorized
          access.
        </SectionContent>
        <SectionContent index="10.2">
          For the avoidance of doubt, whether or not a User registers, a User’s
          use of the Offerings is governed by these terms, the Privacy Policy
          and the terms under the Master Agreement.
        </SectionContent>
      </ContentSection>

      <ContentSection index="11.">
        <SectionHeading>FEES</SectionHeading>
        <SectionContent index="11.1">
          We may charge Customers, and if applicable, Designees, fees for using
          the Offerings.
        </SectionContent>
        <SectionContent index="11.2">
          Our current pricing and payment policies as may be updated from time
          to time are posted or otherwise made available via the Offerings. You
          agree to pay fees owed to us under any terms, policies or other
          written or electronic agreement we may have in place. We may require
          you to maintain valid credit card or other payment account information
          with us or our payment processor in order to use the Offerings, and if
          so, you hereby authorize us to charge your credit card or other
          payment account for the Offerings. Any failure to maintain valid,
          up-to-date payment information with us or our payment processor or to
          keep your payments current will constitute a material breach of these
          terms, for which we may suspend or terminate your access to the
          Offerings immediately without notice.
        </SectionContent>
        <SectionContent index="11.3">
          Interest will be charged on all unpaid amounts at the rate of 5% per
          annum compounded monthly.
        </SectionContent>
      </ContentSection>

      <ContentSection index="12.">
        <SectionHeading>DISCLAIMER</SectionHeading>
        <SectionContent index="12.1">
          To the maximum extent permitted by law, you agree and acknowledge
          that”:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              the Offerings are provided “as is”, “where is” and “as available”,
              and we (including our holding company(ies), subsidiaries,
              Affiliates, directors, officers, employees, agents,
              Representatives, partners and licensors) expressly disclaim all
              warranties, conditions, or other terms, whether implied or
              statutory (including warranties of merchantability, fitness for
              purpose, or warranties with respect to satisfactory quality,
              and/or as to the use of reasonable skill and care with regard to
              the performance of the Services, and/or as to the accuracy or
              reliability of the results of the Offerings);
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              we do not represent, warrant or provide any other form of
              guarantee that the Offerings will meet your requirements, or that
              the Licensed Software will operate without interruption, be error
              free or free from Harmful Code, virus or bugs, nor does it make
              any warranty as to any data that may be generated and/or obtained
              from use of the Offerings;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              we will use commercially reasonable efforts to respond to any
              problems about Offerings detected by us and/or reported by you.
              We, however, do not guarantee that all problems can be fixed
              within any specific timeframe;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(d)">
              we shall not be responsible for any delays or technical problems
              arising from use of the Offerings in connection with the internet
              or the blockchain, or other forms of electronic communications
              that are not within our control.;
            </SectionContent>
          </div>
        </SectionContent>
        <SectionContent index="12.2">
          To the largest extent as applicable to a Licensed Software, you agree
          and acknowledge that distributed ledger technology (“<b>DLT</b>”) is a
          rapidly developing technology, and using of Offerings may carry
          additional risks. You further acknowledge that technological failure
          and network traffic congestion are common and there are other factors
          beyond our reasonable control that may result in failure or delay in
          the execution of instructions given through the Licensed Software
          and/or in handling, processing or transmitting any information and
          data received by, supplied to, or processed on or through the Licensed
          Software. Instructions, personal data and information processed and
          transmitted through the Licensed Software may be subject to the risk
          of being read, intercepted, interrupted or misused by third party, and
          cross-platform transmission may not be entirely secure and likewise
          subject to the aforementioned risks.
        </SectionContent>
        <SectionContent index="12.3">
          You represent that you are technically sophisticated to fully
          understand all risks relating to use of DLT and blockchain-based
          system, and before accessing or using the Offerings at any relevant
          time, you will bear in mind all such risks.
        </SectionContent>
        <SectionContent index="12.4">
          You further understand that blockchain protocols present their own
          risks of use, that supporting or participating in the protocol may
          result in losses if your participation violates certain protocol
          rules, that blockchain-based transactions are irreversible, and that
          you are solely responsible for any approvals or permissions you
          provide by signing blockchain messages or transactions.
        </SectionContent>
        <SectionContent index="12.5">
          You understand and accept that we do not control any blockchain
          protocol, nor do we control any smart contract that is not otherwise
          offered by us as part of the Offerings. You agree that you alone are
          responsible for any transactions that you engage in with regard to any
          blockchain protocol whether through transaction validation or
          otherwise.
        </SectionContent>
        <SectionContent>
          You expressly assume full responsibility for all of the risks of
          accessing and using the Offerings to interact with blockchain
          protocols.
        </SectionContent>
        <SectionContent index="12.6">
          We make no representation that our Offerings are sufficient to enable
          you to comply with all Applicable Law, and any such determination must
          be made by you in your sole discretion.
        </SectionContent>
      </ContentSection>

      <ContentSection index="13.">
        <SectionHeading>INDEMNIFICATION</SectionHeading>
        <SectionContent index="13.1">
          Subject to the remainder of this section 13 and section 14, we, at our
          own expense, will indemnify, defend and hold harmless the User
          Indemnitees from and against any and all Losses directly resulting
          from any Third Party Claim against any User Indemnitee alleging that
          the Licensed Software infringes on, constitutes a misappropriation of
          or otherwise violates any Intellectual Property Right of any Third
          Party.
        </SectionContent>
        <SectionContent index="13.2">
          If any Licensed Software are found to be infringing, or if at any time
          we reasonably believe that any Licensed Software may be subject to a
          claim of infringement, we may choose to:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              at CryptoBLK’s expense, Adapt and Modify the applicable portions
              of the Licensed Software to be non-infringing, provided that such
              Adaptations and Modifications do not degrade the functionality and
              performance of the Licensed Software;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              at CryptoBLK’s expense, obtain a right for you to continue using
              the infringing portions of the Licensed Software; or
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              if neither of the foregoing is commercially practicable, terminate
              the provision of the Offerings, without thereby incurring any
              liability to you.
            </SectionContent>
          </div>
        </SectionContent>
        <SectionContent index="13.3">
          CryptoBLK’s indemnity obligations set out in this Clause will not
          apply to any resulting Losses to the extent caused by or arising out
          of:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              your use of the Licensed Software in violation of the Master
              Agreement, these terms of use, the relevant Licensed Documentation
              or Applicable Law;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              your use of any part of the Licensed Software after written notice
              from CryptoBLK or any Governmental Authority that that it
              infringes on, constitutes a misappropriation of or otherwise
              violates any Intellectual Property right of any Third Party;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              your use of the Licensed Software in combination with any
              software, hardware, materials or service not provided by
              CryptoBLK, except to the extent that such claim of infringement is
              attributable to the Licensed Software.
            </SectionContent>
          </div>
        </SectionContent>
        <SectionContent index="13.4">
          Subject to the remainder of this section 13 and section 14, you will,
          at your own expense, indemnify, defend and hold harmless the CryptoBLK
          Indemnitees from and against any and all Losses directly:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              arising from any breach of the Master Agreement or these terms of
              use, any Use of the Licensed Software and the Services, any
              misrepresentation made, and any breach of Applicable Law by the
              User in the performance of the Master Agreement or these terms of
              use; or
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              resulting from any Third Party Claim alleging that any the User
              Data or the User Marks, and/or the use, development, design,
              production, advertising or marketing of thereof, infringes or
              constitutes a misappropriation of or otherwise violates any
              Intellectual Property rights of any Third Party or was used
              without the User having obtained all required consents and
              approvals.
            </SectionContent>
          </div>
        </SectionContent>
        <SectionContent index="13.5">
          The obligations of the indemnifying party under any indemnities
          provided in this section are conditioned upon the indemnifying party
          receiving written notice of an actual or threatened proceeding or
          claim within 10 days of indemnified party first becoming aware of such
          proceeding or claim and indemnified party reasonably cooperating with
          indemnifying party in the investigation and defence of each such
          proceeding or claim.
        </SectionContent>
        <SectionContent index="13.6">
          If the subject matter of the Third Party Claim is in relation to or
          arising of the Master Agreement, the Platform, the Licensed Software
          and/or the Services, we will have the sole right to control and direct
          the investigation, defines and settlement of each such proceeding or
          claim. You shall fully cooperate with us in the defence or settlement
          of any such Third Party Claim. In other circumstances, the
          indemnifying party shall control and direct the investigation, defines
          and settlement of each such proceeding or claim provided, however,
          that any such settlement will not impose any liability on indemnified
          party.
        </SectionContent>
      </ContentSection>

      <ContentSection index="14.">
        <SectionHeading>LIABILITY</SectionHeading>
        <SectionContent index="14.1">
          Nothing in these terms shall exclude or restrict either Party’s
          liability:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              for fraud or fraudulent misrepresentation;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              for personal injury or death;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              under any provision in the Master Agreement or these terms of use
              or other terms and conditions in relation to the provision of the
              Offerings where a debt or payment is due; or;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(d)">
              in respect of any other matter which cannot be limited or excluded
              under Applicable Law;
            </SectionContent>
          </div>
        </SectionContent>
        <SectionContent index="14.2">
          Subject to section 13.1 and section 14.1, we shall not be liable for
          any losses incurred by you in connection with the Master Agreement,
          your access to or use of the Licensed Software, or your receipt or use
          of the Services.
        </SectionContent>
        <SectionContent index="14.3">
          Subject to section 14.1, neither Party shall be liable (whether in
          contract, tort, misrepresentation, under statute or otherwise,
          howsoever caused including by negligence) for loss of profits, loss of
          business, loss of opportunity or loss of goodwill, or for any other
          losses arising out of or in connection with the provision or use of
          Offerings to the extent that the same are or constitute incidental,
          special, indirect or consequential losses.
        </SectionContent>
        <SectionContent index="14.4">
          Without prejudice to sections 13.1 and 14.1, a failure by us to
          perform any of obligations under the Master Agreement or these terms
          of use shall be excused if, and to the extent that, the failure
          results from a Relief Event.
        </SectionContent>
        <SectionContent index="14.5">
          Without prejudice to sections 14.1 and 14.3, each Party’s total
          aggregate liability to the other Party, whether based on an action or
          claim in contract, tort (including negligence), breach of statutory
          duty or otherwise arising out of or in relation to the provision or
          use of Offerings shall be limited to the fees paid or payable for the
          Offerings in the 12 months prior to the date of the first claim made
          by the Party in question.
        </SectionContent>
      </ContentSection>

      <ContentSection index="15.">
        <SectionHeading>CRYPTOBLK INTELLECTUAL PROPERTY RIGHTS</SectionHeading>
        <SectionContent index="15.1">
          All rights, title and interest, including all Intellectual Property
          Rights, subsisting in the following or the other products or services
          we provide belong to us or have been lawfully licensed to us:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              the Offerings;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              the Rules, any operating procedures and any circulars and alike;
              and
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              CryptoBLK Marks
            </SectionContent>
            <SectionContent className="mb-[16px]">
              (collectively, “<b>CryptoBLK IP</b>”).
            </SectionContent>
          </div>
        </SectionContent>
        <SectionContent index="15.2">
          All rights under Applicable Law are hereby reserved.
        </SectionContent>
        <SectionContent index="15.3">
          Except as expressly provided in the Master Agreement, we grant no
          rights or licenses in or to the Relevant IP (including the Object Code
          or Source Code of the Licensed Software), and all rights and licenses
          not expressly granted thereunder are hereby expressly reserved by us.
          No title to or ownership of any part of the Relevant IP or proprietary
          rights related thereto is transferred to you in the provisions of the
          Offerings or under the Master Agreement.
        </SectionContent>
        <SectionContent index="15.4">
          You shall use all reasonable efforts to protect the Relevant IP and
          cooperate with us, at our reasonable request, in our efforts to
          protect the Relevant IP. You shall report to us any actual or apparent
          infringement of any Relevant IP as well as any improper or
          unauthorized use of the Relevant IP that comes to your attention.
        </SectionContent>
        <SectionContent index="15.5">
          You acknowledge and agree that we are the sole authority to deal with
          any and all issues of infringement or improper or unauthorized use of
          Relevant IP or other proprietary rights of us or our licensors.
        </SectionContent>
      </ContentSection>

      <ContentSection index="16.">
        <SectionHeading>USE OF TRADEMARKS AND LOGOS</SectionHeading>
        <SectionContent index="16.1">
          Our name “KENTRO” and all our trademarks, service marks, trade names,
          logos or other words or symbols identifying CryptoBLK, or our
          Offerings, or other CryptoBLK’s business are our trade marks (be it
          registered or unregistered) (“<b>CryptoBLK Marks</b>”) and may not be
          used without our express prior written consent, save and except any
          use solely in connection with the use of the Offerings and provided
          that any use is in compliance with the policy set out in section 16.3.
        </SectionContent>
        <SectionContent index="16.2">
          You hereby grants to use a worldwide, limited, royalty-free,
          non-exclusive, non-transferrable right to use the User Marks in
          connection with the provision of the Offerings provided that any use
          is in compliance with the policy set out section 16.3.
        </SectionContent>
        <SectionContent index="16.3">
          Each Party agrees not to:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              use any the User Marks or CryptoBLK Marks (as applicable) or any
              part thereof as or as part of a corporate business or trade name;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              use or combine the User Marks or CryptoBLK Marks (as applicable)
              with any other words, marks, logos or devices without the prior
              written consent of the other Party;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              use, develop or seek to register any name logo, symbol or other
              mark or designation that includes, is confusingly similar to, is a
              derivation or colourable imitation of, or competes with, the User
              Marks or CryptoBLK Marks (as applicable); or
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(d)">
              exploit the User Marks or CryptoBLK Marks (as applicable) in any
              manner which is misleading or deceptive or compromises or reflects
              unfavourably upon the good name, goodwill, reputation or image of
              CryptoBLK.
            </SectionContent>
          </div>
        </SectionContent>
      </ContentSection>

      <ContentSection index="17.">
        <SectionHeading>FEEDBACK</SectionHeading>
        <SectionContent index="17.1">
          We value and welcome feedback on our Offerings. You agree that we are
          free to use, disclose, adopt and/or modify any feedback and any
          information (including any ideas, concepts, proposals, suggestions or
          comments) provided by you to use in connection with our Offerings or
          any products or services we offer, without any payment to you, subject
          to the confidentiality obligations to the extent that the Feedback
          contains Confidential Information.
        </SectionContent>
        <SectionContent index="17.2">
          You hereby waive and agree to waive any rights to claim for any fees,
          royalties, charges or other payments in relation to our use,
          disclosure, adoption and/or modification of any of your feedback.
        </SectionContent>
        <SectionContent index="17.3">
          You agree that any such feedback and associated Intellectual Property
          Rights shall be voluntarily given and will become our property and
          hereby assigns and agrees to assign all right, title and interest in
          and to such feedback and associated Intellectual Property Rights to
          us. We shall have the right to use such Feedback
        </SectionContent>
      </ContentSection>

      <ContentSection index="18.">
        <SectionHeading>CONFIDENTIAL INFORMATION AND PUBLICITY</SectionHeading>
        <SectionContent index="18.1">
          All confidential, non-public information that one Party (the “
          <b>Receiving Party</b>”) and its Representatives receives from the
          other Party (the “<b>Disclosing Party</b>”) and its Representatives in
          its performance of the Master Agreement and these terms of use that is
          marked “confidential” or that the Receiving Party knows, or reasonably
          should know, is confidential to the Disclosing Party, including any:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              Licensed Software and CryptoBLK IP;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              information relating to the Disclosing Party’s and its Affiliates’
              business and business strategies, markets, customers, products,
              pricing, condition (financial or otherwise), operations, assets,
              liabilities, results of operations, cash flow and prospects, or
              employees, officers, contractors and agents, including, without
              limitation, technical, commercial, financial, accounting, legal
              and administrative information;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              the existence of and the terms of the Master Agreement and these
              terms of use, as well as the Disclosing Party’s position in any
              dispute in relation to the Master Agreement and these terms of
              use; and
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(d)">
              any User Data
            </SectionContent>
            (collectively, “<b>Confidential Information</b>”) will be held in
            confidence and will not be used or disclosed to any third party,
            subject to these terms of use including but not limited to sections
            9.5 and 6.
          </div>
        </SectionContent>
        <SectionContent index="18.2">
          Each Party acknowledges that the unauthorised disclosure of
          Confidential Information may cause irreparable injury to the
          Disclosing Party.
        </SectionContent>
        <SectionContent index="18.3">
          Information shall not be considered as Confidential Information to the
          extent that it
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              is in the public domain at the time of its disclosure by the
              Disclosing Party or thereafter;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              was properly in the Receiving Party’s or its Representatives’
              possession prior to such disclosure by the Disclosing Party or;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(c)">
              was disclosed to Receiving Party or any of its Representatives by
              a Third Party who did not obtain such information, directly or
              indirectly, from the Disclosing Party subject to any
              confidentiality obligation.
            </SectionContent>
          </div>
        </SectionContent>
        <SectionContent index="18.4">
          The Receiving Party shall exercise at least the same standard of care
          to protect such Confidential Information from unauthorized disclosure
          or use as it uses to protect its own Confidential Information of a
          similar nature, which in no event shall be less than reasonable care.
        </SectionContent>
        <SectionContent index="18.5">
          The Receiving Party shall only use the Disclosing Party’s Confidential
          Information in accordance with the Master Agreement or these terms of
          use in connection with the Offerings and in no event use the
          Disclosing Party’s Confidential information in a manner or for a
          purpose that is adverse to the interest or the business of the
          Disclosing Party or its Affiliates.
        </SectionContent>
        <SectionContent index="18.6">
          The Receiving Party may disclose the Disclosing Party’s Confidential
          Information to:
          <div className="ml-7 mt-4">
            <SectionContent className="mb-[16px]" index="(a)">
              those of the Receiving Party’s Representatives who are bound to
              substantially similar obligations of confidentiality and have a
              need to receive the Confidential Information in connection with
              the Offerings, provided that the Receiving Party shall remain
              responsible and liable for the acts and omissions of such
              Representatives to the same extent as if performed by the
              Receiving Party;
            </SectionContent>
            <SectionContent className="mb-[16px]" index="(b)">
              to the extent that the Receiving Party or its Representatives are
              required to in a judicial, legislative, or administrative
              investigation or proceeding or to a government or other regulatory
              agency, provided that, to the extent permitted by the
              circumstances, the Receiving Party provides to Disclosing Party
              prior written notice of the intended disclosure to enable the
              Disclosing Party the reasonable opportunity to contest or limit
              such disclosure (including reasonable assistance at Disclosing
              Party’s expense) or, if prior written notice is not permitted,
              prompt notice of such disclosure.
            </SectionContent>
          </div>
        </SectionContent>
      </ContentSection>

      <ContentSection index="19.">
        <SectionHeading>
          TERMINATION OF THESE TERMS AND YOUR ACCOUNT(S)
        </SectionHeading>
        <SectionContent index="19.1">
          We reserve the right to suspend or terminate your access to the
          Offerings in the event that you, or (in the case of an Authorised
          User) your Customer or (in the case of a Customer) your Authorised
          User is engaged in, or suspected by us in good faith as being engaged
          in, any unauthorized conduct (including, but not limited to any
          violation of the Master Agreement or these terms of use or the Privacy
          Policy). Our exercise of the suspension rights herein shall not be
          conditioned upon your receipt of any notification. You agree that we
          shall not be liable to you, your Authorised User (if applicable), your
          Customer (if applicable), or any other Third Party, if we exercise our
          suspension rights as permitted by this section. Upon determining that
          such unauthorized conduct leading to the temporary suspension has
          ceased to our reasonable satisfaction, we may reinstate your, your
          Authorised User (if applicable) or your Customer respective Users’
          access and use of the Offerings. Notwithstanding anything in this
          section to the contrary, our suspension of Offerings is in addition to
          any other remedies that we may have under the Master Agreement, these
          terms of use or otherwise, including but not limited to termination of
          the Offerings for cause in accordance with the Master Agreement.
        </SectionContent>
        <SectionContent index="19.2">
          These terms will continue to apply to your Use of the Offerings until
          the corresponding Order Form(s) and/or the Master Agreement is
          terminated by the Customer or us, in accordance with the terms
          therein, or in the case If you are an Authorised User of the Customer
          until you no longer participate in the transactions or the business
          purposes contemplated and stop accessing and using the Offerings,
          whichever is earlier, save and except for sections which by their
          terms or nature, shall survive termination.
        </SectionContent>
        <SectionContent index="19.3">
          In the event of termination or suspension of your account, you will
          lose, and we may delete, your profile and the related information you
          have published, uploaded or made available on the Offerings. However,
          to the extent permitted by Applicable Law and these terms of use, we
          reserve the right to store your profile and any of the User Data on
          our servers.
        </SectionContent>
        <SectionContent index="19.4">
          The termination or suspension of your account will not affect any
          rights or liabilities of you arising out of matters which have taken
          place prior to the termination (as the case may be).
        </SectionContent>
      </ContentSection>

      <ContentSection index="20.">
        <SectionHeading>OTHER IMPORTANT TERMS</SectionHeading>
        <SectionContent index="20.1">
          We may transfer our rights and obligations under these terms to our
          Affiliate or Person that acquires all or substantially all of our
          assets or operations or business primarily related to the provisions
          of the Offerings, provided that such Affiliate or Person acknowledges
          and assumes in writing all our responsibilities hereunder.
        </SectionContent>
        <SectionContent index="20.2">
          You cannot transfer your rights or your obligations under these terms
          to another Person without our prior written consent.
        </SectionContent>
        <SectionContent index="20.3">
          If a court finds part of these terms illegal, the rest will continue
          in force. Each of the paragraphs of these terms operates separately.
          If any court or relevant authority decides that any of them are
          unlawful, the remaining paragraphs will remain in full force and
          effect.
        </SectionContent>
        <SectionContent index="20.4">
          Even if we delay in enforcing these terms, we can still enforce it
          later. If we do not insist immediately that you do anything you are
          required to do under these terms, or if we delay in taking steps
          against you in respect of your breaking this contract, that will not
          mean that you do not have to do those things and it will not prevent
          us taking steps against you at a later date. For example, if you miss
          a payment and we do not chase you but we continue to provide the
          Offerings, we can still require you to make the payment at a later
          date.
        </SectionContent>
      </ContentSection>

      <ContentSection index="21.">
        <SectionHeading>GOVERNING LAW AND JURISDICTION</SectionHeading>
        <SectionContent index="21.1">
          These terms are governed by and shall be construed in accordance with
          the laws of the Hong Kong Special Administrative Region.
        </SectionContent>
        <SectionContent index="21.2">
          Any dispute, controversy, difference or claim arising from or relating
          to the Master Agreement, including the existence, validity,
          interpretation, performance, breach, termination or non-contractual
          obligations, (“<b>Dispute</b>”) shall be referred to and finally
          resolved by arbitration administered by the Hong Kong International
          Arbitration Centre (HKIAC) under the HKIAC Administered Arbitration
          Rules in force upon submission of the Notice of Arbitration The law of
          this arbitration and this arbitration clause shall be the laws of Hong
          Kong. The seat of the arbitration shall be Hong Kong. The arbitration
          proceedings shall be conducted in English. The number of arbitrators
          shall be three (3), appointed in accordance with the HKIAC
          Administered Arbitration Rules. Any decision or award of the arbitral
          tribunal shall be final, effective, and binding upon the parties to
          the arbitration proceedings.
        </SectionContent>
      </ContentSection>

      <div className="h-16" />

      <SectionHeading className="text-center">
        <p>ANNEX 1</p>
        <p>ACCEPTABLE USE POLICY</p>
      </SectionHeading>
      <SectionContent>
        As part of the terms of use, you agree not to misuse the Offerings or
        help anyone else to do so. For example, you agree not to do any of the
        following in connection with the Offerings:
        <div className="ml-7 mt-4">
          <SectionContent className="mb-[16px]" index="(a)">
            use our Offerings for unlawful or unauthorised purposes not
            expressly permitted by the Master Agreement;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(b)">
            provide or otherwise make available any parts of the Offerings in
            any form, to any person or entity other than as allowed by the
            Master Agreement;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(c)">
            license, sublicense, assign, sell, rent, resell, lease, distribute
            or otherwise transfer the Licensed Software or any of your rights in
            relation to the Licensed Software to or permit the use of the
            Licensed Software by a Third Party, grant a timeshare to a Third
            Party in relation to the Licensed Software, provide outsourcing or
            other similar services based on the Use of the Licensed Software
            otherwise market the Licensed Software or in any way disclose or
            allow others to use or benefit from the Licensed Software;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(d)">
            allow the Licensed Software to become the subject of any charge,
            lien or encumbrance;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(e)">
            probe, scan, or test the vulnerability of any system or network;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(f)">
            breach or otherwise circumvent any security or authentication
            measures or service use limits;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(g)">
            access, tamper with, or use non-public areas or parts of the
            Offerings;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(h)">
            interfere with or disrupt any user, host, or network, for example by
            sending a virus, trojan, worm, logic bomb, or any other material
            that is malicious or technologically harmful, overloading, flooding,
            spamming, or mail-bombing any part of the Offerings, or by scripting
            the creation of any content in such manner as to interfere with or
            create an undue burden on the Offerings;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(i)">
            copy, reproduce, publish, reverse engineer, disassemble, reverse
            assemble, convert, translate, merge, decompile create derivative
            works from, or attempt to create, generate or access Source Code
            from the Licensed Software or any related technology of the
            Offerings that is not open source;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(j)">
            adapt, modify, make corrections to, or replicate any features,
            functions, integrations, or interfaces of the Licensed Software or
            any portion thereof;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(k)">
            take any action to introduce any Harmful Code in or to the Licensed
            Software, including through any other code used by you in connection
            with the Licensed Software. If you become aware of the existence of
            any Harmful Code in or relating to any other code used by you in
            connection with the Licensed Software, you shall promptly notify us
            and follow all instructions provided;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(l)">
            remove, destroy, modify, conceal, manipulate or obscure proprietary
            rights notices placed on or in the Licensed Software, screens or
            pages with any copyright notices or confidential legends placed upon
            or contained within the Licensed Software, or any other related
            materials;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(m)">
            circumvent, interfere with, disrupt or disable any security or other
            technological features or measures of the Licensed Software;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(n)">
            modify or alter the tables or files therein relating to the Licensed
            Software or any usage statistics access;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(o)">
            combine the Licensed Software with any Copyleft Open-Source Software
            in any manner that could require that the Licensed Software, any
            portion thereof, or any Adaptation or Modification thereof to be (i)
            disclosed or distributed in Source Code form, (ii) licensed for the
            purpose of making derivative works, or (iii) redistributable at no
            charge.
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(p)">
            access, search, or create accounts for the Offerings by any means
            (automated or otherwise) other than our publicly supported
            interfaces (for example, “scraping” or creating accounts in bulk) or
            attempt to do so;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(q)">
            post, publish, upload, display, distribute, or share materials that
            are unlawful, inappropriate, profane, pornographic, obscene,
            indecent, libelous, defamatory, abusive, or knowingly false, and/or
            that infringe intellectual property rights;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(r)">
            violate the letter or spirit of our terms of use;
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(s)">
            violate Applicable Law in any way; or
          </SectionContent>
          <SectionContent className="mb-[16px]" index="(t)">
            violate the privacy or infringe the rights of others.
          </SectionContent>
        </div>
      </SectionContent>
    </div>
  </>
);
