import HistoryIcon from "assets/svg/history.svg";
import classNames from "classnames";
import { Card } from "common/components/Card";
import { Title } from "features/applicationDashboard/components/Title";
import { ApplicationIcon } from "features/applicationDashboard/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type RecentlyUsedProps = {
  className?: string;
  data: ApplicationIcon[];
};

export const RecentlyUsed = ({
  className,
  data,
}: RecentlyUsedProps): JSX.Element => {
  const { t } = useTranslation();
  const classNameStr = classNames(
    "p-5 min-w-fit min-h-fit bg-white",
    className
  );
  const displayData = useMemo(
    () => (data.length > 6 ? data.splice(0, 6) : data),
    [data]
  );

  return (
    <Card className={classNameStr}>
      <div className="w-full p-5 pl-0">
        <img className="inline-block" src={HistoryIcon} alt="History Icon" />
        <Title
          className="pl-2 inline-block"
          title={t("application.title.recentlyUsed")}
        />
      </div>
      <div className="w-full flex flex-wrap justify-between gap-9">
        {displayData &&
          displayData.map((app, index) => (
            <a
              href={app.url}
              target="_blank"
              rel="noreferrer noopener"
              key={index}
            >
              <img
                className="border border-light-greyscale rounded-md w-fit"
                src={app.src}
                alt={app.alt}
              />
            </a>
          ))}
      </div>
    </Card>
  );
};
