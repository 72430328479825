import { publicOtpApi } from "api/publicOtp";
import { Button } from "common/components/Button";
import { useUserOnboardingInfo } from "features/public/Register/hooks/useUserOnboardingInfo";
import { OnboardingScenario } from "features/public/Register/types";
import { Trans, useTranslation } from "react-i18next";
import tw from "twin.macro";

const Description = tw.p`text-dark-grey font-regular text-left`;

export const VersionUpdate = () => {
  const { t } = useTranslation();
  const { onNextStep, userOnboardingInfo, onboardingScenario } =
    useUserOnboardingInfo();
  const handleNext = async () => {
    try {
      // next page is OTP form in scenario 2
      if (onboardingScenario === OnboardingScenario.KS_USER_LOGIN) {
        await publicOtpApi.requestOtp(
          userOnboardingInfo.email,
          userOnboardingInfo.firstName,
          userOnboardingInfo.lastName
        );
      }
      onNextStep();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div className="w-full">
      <h1 className="text-dark-blue mb-12 leading-9">
        {t("public.versionUpdate.title", {
          originApp: userOnboardingInfo.applicationName,
        })}
      </h1>

      <Description className="mb-8">
        <Trans
          i18nKey="public.versionUpdate.identifiedEmail"
          values={{
            originApp: userOnboardingInfo.applicationName,
            email: userOnboardingInfo.email,
          }}
        >
          <span className="text-light-blue" />
        </Trans>
      </Description>
      <Description className="mb-8">
        <Trans
          i18nKey="public.versionUpdate.confirmation"
          values={{
            originApp: userOnboardingInfo.applicationName,
          }}
        >
          <span className="text-light-blue" />
        </Trans>
      </Description>
      <Description className="mb-16">
        {t("public.versionUpdate.proceed")}
      </Description>
      <Button className="w-full" variant="primary" onClick={handleNext}>
        {t("common.next")}
      </Button>
    </div>
  );
};
