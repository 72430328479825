import { authAPI } from "api/auth";
import { publicUserAPI } from "api/publicUser";
import { VersionDisplay } from "common/components/VersionDisplay";
import {
  composeErrorHandlers,
  GenericError,
  isAPIError,
} from "common/errorHandling";
import { LoginForm, LoginFormValues } from "features/public/forms/LoginForm";
import { useUserOnboardingInfo } from "features/public/Register/hooks/useUserOnboardingInfo";
import { OnboardingScenario } from "features/public/Register/types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "redux/hooks";
import { setLoggedIn } from "redux/slices/auth/authSlice";
import { setCanRedirect } from "redux/slices/redirect/redirectSlice";
import tw, { styled } from "twin.macro";
import { FormSubmissionHandler } from "types/common";

export const LoginView = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setOnboardingScenario, setUserOnboardingInfo, clearOnboardingInfo } =
    useUserOnboardingInfo();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirectURL");

  useEffect(() => {
    // Always trigger default onboarding flow when back to login page
    clearOnboardingInfo();
  }, [clearOnboardingInfo]);

  const handleSubmit: FormSubmissionHandler<LoginFormValues> = async (
    values,
    { setSubmitting, setStatus }
  ) => {
    setSubmitting(true);
    try {
      await authAPI.login({
        username: values.email.trim(),
        password: values.password,
      });
      dispatch(setLoggedIn());
      dispatch(setCanRedirect(true));
      if (redirectUrl) {
        window.location.replace(redirectUrl);
      }
    } catch (e) {
      setSubmitting(false);
      composeErrorHandlers((next) => async (e) => {
        if (isAPIError(e) && e.status === 401) {
          try {
            const migrationRes = await publicUserAPI.checkMigration(
              values.email,
              values.password
            );
            // Scenario 2
            if (migrationRes.token) {
              setUserOnboardingInfo((prev) => {
                return {
                  ...prev,
                  email: values.email,
                  firstName: migrationRes.userProfile.firstName,
                  lastName: migrationRes.userProfile.lastName,
                  applicationName: migrationRes.userProfile.applicationName,
                };
              });
              setOnboardingScenario(OnboardingScenario.KS_USER_LOGIN);
              navigate("../register");
            } else {
              setStatus(`${t("public.login.status")}.`);
            }
          } catch (error) {
            setStatus(`${t("public.login.status")}.`);
          }
        } else if (isAPIError(e) && e.status === 409) {
          setStatus(t("public.login.rateLimit"));
        } else {
          next(e);
        }
      })(e as GenericError);
    }
  };

  return (
    <LoginViewWrapper>
      <ContentWrapper>
        <div className="flex flex-1 flex-col justify-center font-medium">
          <h2 className="mb-6">
            {t("public.login.welcome")}
            <span className="text-light-blue font-medium"> Kentro</span>
          </h2>
          <LoginForm onSubmit={handleSubmit} />
        </div>
        <VersionDisplay versionCondition=">= 1.0.0">
          <div className="text-center mb-[26px]">
            <span className="text-sm font-medium">
              {t("public.onboarding.notHaveAccount")}?
              <NavLink to="../register" className="!pr-0 text-light-blue ml-1">
                {t("public.button.signup")}
              </NavLink>
            </span>
          </div>
        </VersionDisplay>
      </ContentWrapper>
    </LoginViewWrapper>
  );
};

const LoginViewWrapper = styled.div(() => [
  tw`relative col-start-2 col-end-6 w-full h-full py-[24px] flex flex-col items-center justify-center`,
]);
export const ContentWrapper = styled.div(() => [
  tw`flex flex-col w-full h-full`,
]);
