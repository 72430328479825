import { userAPI } from "api/user";
import {
  composeErrorHandlers,
  baseFallbackErrorHandler,
  GenericError,
} from "common/errorHandling";
import useCurrentUserProfile from "common/hooks/useCurrentUserProfile";
import { ToastContainerId } from "common/toast";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "redux/slices/auth/authSlice";

export const useAppStatus = () => {
  const currentUserProfile = useCurrentUserProfile();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!currentUserProfile) {
      return;
    }

    const timers = currentUserProfile.userSubscription
      .filter((it) => !it.isProfileSetup)
      .reduce((acc, curr) => {
        const timer = setInterval(async () => {
          try {
            const isReady = await userAPI.getUserProfileApplicationStatus(
              curr.app.id
            );
            if (isReady) {
              clearInterval(timer);
              const newProfile = _.cloneDeep(currentUserProfile);
              const index = newProfile.userSubscription.findIndex(
                (it) => it.app.id === curr.app.id
              );
              newProfile.userSubscription[index].isProfileSetup = true;
              dispatch(setCurrentUser(newProfile));
            }
          } catch (error) {
            clearInterval(timer);
            composeErrorHandlers(() =>
              baseFallbackErrorHandler(ToastContainerId.AfterLogin)
            )(error as GenericError);
          }
        }, 1000 * 15);
        return acc.add(timer);
      }, new Set<NodeJS.Timer>());
    return () => timers.forEach((it) => clearInterval(it));
  }, [currentUserProfile, dispatch]);
};
