import { useAppDetailInfoById } from "common/hooks/useAppDetailInfoById";
import { AtlasEliteView } from "features/applicationDashboard/pages/applicationDetail/pages/AtlasEliteView";
import { KentroSignView } from "features/applicationDashboard/pages/applicationDetail/pages/KentroSignView";
import { MidasView } from "features/applicationDashboard/pages/applicationDetail/pages/MidasView";
import { TopazView } from "features/applicationDashboard/pages/applicationDetail/pages/TopazView";
import {
  ApplicationDisplayName,
  RoutesPath,
} from "features/applicationDashboard/types";
import { getRoutesPathByAppName } from "features/applicationDashboard/utils/common";
import { useEffect, useMemo } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";

export const ApplicationDetailRoutes = (): JSX.Element => {
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const { appDetailData, error } = useAppDetailInfoById(applicationId);

  useEffect(() => {
    if (error) {
      navigate("/dashboard");
    }
  }, [navigate, error]);

  const navigatePath = useMemo(() => {
    const name = appDetailData?.name as ApplicationDisplayName;
    return getRoutesPathByAppName(name);
  }, [appDetailData?.name]);

  return (
    <Routes>
      <Route element={<Outlet />}>
        {appDetailData && (
          <>
            <Route index element={<Navigate to={navigatePath} />} />
            <Route
              path={RoutesPath.AtlasElite}
              element={<AtlasEliteView data={appDetailData} />}
            />
            <Route
              path={RoutesPath.KentroSign}
              element={<KentroSignView data={appDetailData} />}
            />
            <Route
              path={RoutesPath.Midas}
              element={<MidasView data={appDetailData} />}
            />
            <Route
              path={RoutesPath.Topaz}
              element={<TopazView data={appDetailData} />}
            />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </>
        )}
      </Route>
    </Routes>
  );
};
