import { INPUT_MAX_LENGTH } from "constants/common";

import { ReactComponent as SvgSearch } from "assets/svg/search.svg";
import { formatInputValue } from "common/utils/common";
import debounce from "lodash/debounce";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

export type SearchBarProps = {
  onChange: (value: string) => void;
  debounceTimeout?: number;
};

const SearchField = tw.input`block bg-light-greyscale text-dark-blue rounded-lg px-2 pl-12 py-2.5
  w-full border-2 border-transparent focus:border-light-blue h-10 font-medium placeholder:text-dark-grey`;

export const SearchBar = ({
  onChange,
  debounceTimeout = 500,
}: SearchBarProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValueTrimmed = formatInputValue(e.target.value);
    setSearchValue(searchValueTrimmed);

    if (onChange) {
      debounce(
        (): void => {
          onChange(searchValueTrimmed);
        },
        debounceTimeout,
        { maxWait: 1500 }
      )();
    }
  };

  return (
    <label className="relative">
      <div className="absolute left-4 top-1/2 -translate-y-1/2">
        <SvgSearch className="text-dark-grey" />
      </div>
      <SearchField
        onChange={handleChange}
        placeholder={t("common.search")}
        autoComplete="off"
        maxLength={INPUT_MAX_LENGTH}
        value={searchValue}
      />
    </label>
  );
};
