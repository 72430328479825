import { FIELD_IS_REQUIRED_ERROR } from "constants/commonValidatorError";

import { TFunction } from "i18next";
import { setLocale } from "yup";

/**
 * Builds the Yup Locale
 * Referrence: https://github.com/jquense/yup/issues/758
 */
export function buildYupLocale(_: unknown, t: TFunction): void {
  setLocale({
    mixed: {
      required: t(FIELD_IS_REQUIRED_ERROR),
    },
  });
}
