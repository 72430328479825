import { UserProfileResponse } from "api/publicOtp";
import { request } from "common/HOC/WithRequest";
import { AccountType } from "types/user";

const prefix = "public/user";
export const publicUserAPI = {
  register: (payload: RegisterRequest) => request(`${prefix}`, "POST", payload),
  forgotPassword: (userEmail: string) => {
    const bodyData = {
      email: userEmail,
    };
    return request(`${prefix}/password/forgot`, "POST", bodyData);
  },
  requestNewLink: (token: string) => {
    return request(`${prefix}/password/renew/${token}`, "POST");
  },
  resetPassword: (token: string, password: string) => {
    const bodyData = {
      password,
    };

    return request(`${prefix}/password/reset/${token}`, "POST", bodyData);
  },
  validateToken: (token: string) => {
    return request(`${prefix}/password/validate/${token}`, "GET");
  },
  requestSupport: (supportPayload: supportPayload) => {
    return request(`public/request-support`, "POST", supportPayload);
  },

  checkMigration: (
    username: string,
    password: string
  ): Promise<CheckMigrationResponse> => {
    return request(`${prefix}/check/migration`, "POST", { username, password });
  },
  getResetPasswordTokenByOtp: (
    inputOtp: string,
    email: string
  ): Promise<ResetTokenByOTP> => {
    return request(`${prefix}/password/reset/otp`, "POST", { inputOtp, email });
  },
  getMigrationUserProfile: (
    migrationToken: string
  ): Promise<UserProfileResponse> => {
    return request(`${prefix}/profile?token=${migrationToken}`, "GET");
  },
};

type RegisterProfile = {
  firstName: string;
  lastName: string;
};

type CreateUserCompany = {
  name: string | null;
  title: string | null;
};

type RegisterRequest = {
  email: string;
  personalDetail: RegisterProfile;
  password: string;
  company: CreateUserCompany;
  userType: AccountType.INDIVIDUAL;
  defaultLanguageCode: string;
  actionToken: string;
};

export type supportPayload = {
  attachmentIds: string[];
  description: string;
  email: string;
  subject: string;
};

export type ResetTokenByOTP = {
  token: string;
};

export type CheckMigrationResponse = ResetTokenByOTP & {
  userProfile: UserProfileResponse;
};
