import BackgroundLeft from "assets/images/background-left.png";
import BackgroundRight from "assets/images/background-right.png";
import React from "react";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";

const MainContainer = styled.div(() => [
  tw`col-span-full h-full relative`,
  tw`bg-background-grey`,
]);

export const MainContainerBackground = ({
  isPictureBackground,
  children,
}: {
  isPictureBackground: boolean;
  children: React.ReactNode;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <MainContainer>
      {isPictureBackground && (
        <div className="z-window absolute top-0 bg-white h-[496px] w-full">
          <img
            className="absolute bottom-0 left-0"
            src={BackgroundLeft}
            alt="Background Left"
          />
          <div className="grid grid-cols-12 mx-[130px]">
            <div className="col-start-4 col-span-6 flex flex-col items-center">
              <div className="mt-[120px] text-xl font-bold text-light-blue">
                {t("application.background.title")}
              </div>
              <p className="mt-4 font-medium text-dark-grey text-center">
                {t("application.background.description1")}
              </p>
            </div>
          </div>
          <img
            className="absolute top-0 right-0"
            src={BackgroundRight}
            alt="Background Right"
          />
        </div>
      )}
      <div className="z-fixed relative">{children}</div>
    </MainContainer>
  );
};
