import { authAPI } from "api/auth";
import { useAuth } from "common/hooks";
import { TimeUnit } from "common/hooks/useCountDownTimer";
import { useCurrentUTCTime } from "common/hooks/useCurrentUTCTime";
import { useLocalStorageListener } from "common/hooks/useLocalStorageListener";
import { useRefreshTokenExpiredPopupHandler } from "common/hooks/useRefreshTokenExpiredPopupHandler";
import { LOCAL_STORAGE_KEY } from "common/utils/cross-domain/react/helper";
import { postTokenHandler } from "common/utils/cross-domain/react/postTokenHandler";
import { ReactNode, useCallback, useEffect } from "react";

export const SessionTimeoutLayout = ({ children }: { children: ReactNode }) => {
  const { clearAuthToken } = useAuth();

  // Get UTC time every seconds (exact ratio)
  const { currentUTCTime, onStopTimer, onResumeTimer } = useCurrentUTCTime(
    1,
    TimeUnit.SECOND
  );

  const { show, hide } = useRefreshTokenExpiredPopupHandler(
    () => handleStayLoggedIn(),
    () => handleLogout()
  );

  useLocalStorageListener(
    [LOCAL_STORAGE_KEY.REFRESH_TOKEN, LOCAL_STORAGE_KEY.ACCESS_TOKEN],
    () => {
      onResumeTimer();
      hide();
    }
  );

  const handleStayLoggedIn = useCallback(async () => {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
    try {
      if (refreshToken) {
        await authAPI.refreshToken(refreshToken);
        onResumeTimer();
        hide();
      }
    } catch (e) {
      console.error(e);
    }
  }, [hide, onResumeTimer]);

  const handleLogout = useCallback(() => {
    hide();
    clearAuthToken();
    try {
      postTokenHandler();
    } catch (err) {
      console.error(err);
    } finally {
      window.location.reload(); // Reload to make sure everything reset
    }
  }, [hide, clearAuthToken]);

  useEffect(() => {
    const timeout = localStorage.getItem(LOCAL_STORAGE_KEY.TIMEOUT);
    if (timeout) {
      const timeoutUTC = new Date(JSON.parse(timeout)).toUTCString();
      if (timeoutUTC.slice(0, 22) === currentUTCTime.slice(0, 22)) {
        show();
        onStopTimer();
      }
    }
  }, [currentUTCTime, show, onStopTimer]);

  return <>{children}</>;
};
