import { hashStringToNumber } from "common/utils/common";

export enum Colors {
  RUBY = "ruby",
  ORANGE = "orange",
  LIGHT_GREEN = "light-green",
  FLORESCENCE_GREEN = "florescence-green",
  LIGHT_BLUE = "light-blue",
  LIGHT_YELLOW = "light-yellow",
  LIGHT_PURPLE = "light-purple",
  LIGHT_RED = "light-red",
  LIGHT_GREY = "light-greyscale",
  GREY = "grey",
  YELLOW_GREEN = "yellow-green",
  DODGER_BLUE = "dodger-blue",
  VANILLA_PURPLE = "vanilla-purple",
  DARK_TURQUOISE = "dark-turquoise",
}

export enum ColorsWithOpacity {
  LIGHT_BLUE = "light-blue-opacity",
  LIGHT_YELLOW = "light-yellow-opacity",
  LIGHT_GREEN = "light-green-opacity",
  LIGHT_RED = "light-red-opacity",
}

export const getSecondaryColorByOrder = (index: number) => {
  const order = [
    Colors.LIGHT_PURPLE,
    Colors.LIGHT_GREEN,
    Colors.LIGHT_RED,
    Colors.ORANGE,
    Colors.LIGHT_YELLOW,
    Colors.RUBY,
    Colors.YELLOW_GREEN,
    Colors.DODGER_BLUE,
    Colors.VANILLA_PURPLE,
    Colors.DARK_TURQUOISE,
  ];

  return order[index % order.length];
};

export const generateColorByString = (text: string) => {
  const randomNumber = hashStringToNumber(text.trim().toLowerCase());
  return getSecondaryColorByOrder(randomNumber);
};
