import { LoadingSpinner } from "common/components/LoadingSpinner";
import React from "react";

type LoaderProps = {
  bgClassName?: string;
  isLoading?: boolean;
  error?: boolean;
  overlay?: boolean;
  errorFallback?: React.ReactNode;
  children: React.ReactNode;
  hintContent?: React.ReactNode;
};

export const Loader = ({
  isLoading,
  children,
  overlay,
  bgClassName = "bg-white",
  error,
  errorFallback,
  hintContent = null,
}: LoaderProps): JSX.Element => {
  if (isLoading && !error) {
    if (overlay) {
      if (hintContent === null) {
        return (
          <div className="flex items-center justify-center">
            <LoadingSpinner />
          </div>
        );
      } else {
        return (
          <div className="relative w-full h-full">
            {children}
            {isLoading && (
              <div
                className={`absolute inset-0 flex items-center justify-center ${bgClassName}`}
              >
                {hintContent}
              </div>
            )}
          </div>
        );
      }
    } else {
      return (
        <div
          className={`absolute inset-0 flex items-center justify-center ${bgClassName}`}
        >
          <LoadingSpinner />
        </div>
      );
    }
  } else if (error && errorFallback) {
    return <>{errorFallback}</>;
  } else {
    return <>{children}</>;
  }
};
