import { FIELD_IS_REQUIRED_ERROR } from "constants/commonValidatorError";
import {
  PASSWORD_CONFIRMATION_ERROR,
  PASSWORD_FORMAT_ERROR,
} from "constants/passwordFormatError";
import { REG_PASSWORD_VALIDATOR } from "constants/passwordValidatorKey";

import { Button } from "common/components/Button";
import { PasswordField } from "common/components/Form/PasswordField";
import { Message } from "common/components/Message";
import { PasswordValidator } from "features/public/components/PasswordValidator";
import {
  isCommonErrorShowInPasswordForm,
  PasswordFormType,
} from "features/public/utils/onboarding";
import { Form, Formik } from "formik";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { FormSubmissionHandler } from "types/common";
import * as yup from "yup";

export type ResetPasswordFormValues = {
  password: string;
  confirmedPassword: string;
};

type ResetPasswordFormProps = {
  onSubmit: FormSubmissionHandler<ResetPasswordFormValues>;
};

export const hasValidationErrorExceptRequired = (
  err: string | undefined
): boolean => {
  return !!(err && err.indexOf(t(FIELD_IS_REQUIRED_ERROR)) < 0);
};

export const ResetPasswordForm = ({
  onSubmit,
}: ResetPasswordFormProps): JSX.Element => {
  const { t } = useTranslation();

  const schema: yup.SchemaOf<ResetPasswordFormValues> = yup.object().shape({
    password: yup
      .string()
      .required()
      .matches(REG_PASSWORD_VALIDATOR, t(PASSWORD_FORMAT_ERROR)),
    confirmedPassword: yup
      .string()
      .required()
      .test(
        "is-same-password",
        t(PASSWORD_CONFIRMATION_ERROR),
        (value, context) => {
          return value !== "" && value === context.parent.password;
        }
      ),
  });

  return (
    <Formik
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      initialValues={{
        password: "",
        confirmedPassword: "",
      }}
    >
      {({ values, isSubmitting, errors }) => {
        const isCommonErrorShow = isCommonErrorShowInPasswordForm(
          PasswordFormType.RESET_PASSWORD,
          errors.password,
          errors.confirmedPassword
        );
        return (
          <Form autoComplete="off" noValidate>
            <PasswordField
              name="password"
              label={`${t("common.password")}*`}
              className="mt-8"
              hideErrorMsg={errors.password !== t(FIELD_IS_REQUIRED_ERROR)}
              isErrorShow={errors.password === t(FIELD_IS_REQUIRED_ERROR)}
            />
            <PasswordField
              name="confirmedPassword"
              label={`${t("common.confirmPassword")}*`}
              className="mt-8"
              hideErrorMsg={
                !!errors.password &&
                errors.confirmedPassword !== t(FIELD_IS_REQUIRED_ERROR)
              }
              isErrorShow={
                errors.confirmedPassword === t(FIELD_IS_REQUIRED_ERROR) ||
                (!errors.password && !!errors.confirmedPassword)
              }
            />
            <div className="mt-9 mb-24">
              <PasswordValidator password={values.password} />
            </div>
            <div className="h-5">
              {isCommonErrorShow && (
                <Message
                  className="text-xs mt-6"
                  variant="warning"
                  isBackgroundEnable={false}
                >
                  {errors.password}
                </Message>
              )}
            </div>
            <Button
              className="mt-7 sm:mt-5 lg:mt-3 w-full"
              variant="primary"
              disabled={isSubmitting}
              type="submit"
            >
              {t("common.submit")}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
