import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type RedirectState = {
  canRedirect: boolean;
};

const initialState: RedirectState = {
  canRedirect: true,
};

const redirectSlice = createSlice({
  name: "redirect",
  initialState,
  reducers: {
    setCanRedirect(state, action: PayloadAction<boolean>) {
      state.canRedirect = action.payload;
    },
  },
});

const { setCanRedirect } = redirectSlice.actions;

export { setCanRedirect };

const redirectReducer = redirectSlice.reducer;
export default redirectReducer;
