import { TimeUnit } from "common/hooks/useCountDownTimer";
import { convertTimeToMilisecond } from "common/utils/common";
import { useEffect, useRef, useState } from "react";

export const useCurrentUTCTime = (
  time: number,
  stepUnit: TimeUnit
): {
  currentUTCTime: string;
  onStopTimer: () => void;
  onResumeTimer: () => void;
} => {
  const timerInterval = useRef<NodeJS.Timer>();
  const [isIntervalRun, setIntervalRun] = useState(true);
  const [currentTime, setCurrentTime] = useState(() =>
    new Date().toUTCString()
  );

  const stopInterval = () => {
    setIntervalRun(false);
  };

  const resumeInterval = () => {
    setIntervalRun(true);
  };

  // Get UTC time per second | minute | hour
  useEffect(() => {
    if (isIntervalRun) {
      timerInterval.current = setInterval(() => {
        setCurrentTime(new Date().toUTCString());
      }, convertTimeToMilisecond(time, stepUnit));
    }
    return () => {
      clearInterval(timerInterval.current);
    };
  }, [stepUnit, isIntervalRun, time]);

  return {
    currentUTCTime: currentTime,
    onStopTimer: stopInterval,
    onResumeTimer: resumeInterval,
  };
};
