import { ApplicationListItem } from "features/applicationDashboard/pages/dashboard/components/ApplicationListItem";
import { ApplicationInfo } from "features/applicationDashboard/types";

type ApplicationListProps = {
  data: ApplicationInfo[];
  onClick: (app: ApplicationInfo) => void;
  className?: string;
};

export const ApplicationList = ({
  data,
  onClick,
  className,
}: ApplicationListProps): JSX.Element => {
  return (
    <div
      className={`col-span-full ${className && className}`}
      data-cy="application-list"
    >
      {data.map((app, index) => (
        <div key={index}>
          <ApplicationListItem information={app} onClick={() => onClick(app)} />
          {index !== data.length - 1 && (
            <hr className="col-span-full my-6 text-greyscale" />
          )}
        </div>
      ))}
    </div>
  );
};
