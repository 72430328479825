import { Button } from "common/components/Button";
import { InputField } from "common/components/Form/InputField";
import { TitleSection } from "features/public/components/TitleSection";
import { useUserOnboardingInfo } from "features/public/Register/hooks/useUserOnboardingInfo";
import { UserOnboardingStep } from "features/public/Register/types";
import { getPrefilledDomainFromEmailAddress } from "features/public/utils/onboarding";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormSubmissionHandler } from "types/common";
import * as yup from "yup";

export type PersonalFormValues = {
  firstName: string;
  lastName: string;
  companyName?: string;
  companyTitle?: string;
};

export const PersonalForm = (): JSX.Element => {
  const { t } = useTranslation();

  const schema: yup.SchemaOf<PersonalFormValues> = yup.object().shape({
    firstName: yup.string().required().trim(),
    lastName: yup.string().required().trim(),
    companyName: yup.string(),
    companyTitle: yup.string(),
  });
  const {
    userOnboardingInfo,
    setUserOnboardingInfo,
    onNextStep,
    currentRoute,
  } = useUserOnboardingInfo();

  const handleSubmit: FormSubmissionHandler<PersonalFormValues> = async (
    values
  ) => {
    setUserOnboardingInfo((prev) => {
      return {
        ...prev,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        companyName: values.companyName?.trim(),
        companyTitle: values.companyTitle?.trim(),
      };
    });
    onNextStep();
  };
  return (
    <div className="relative w-full">
      <TitleSection
        title={
          currentRoute === UserOnboardingStep.PERSONAL_ACCOUNT
            ? `${t("public.onboarding.personalAccountTitle")}`
            : `${t("public.onboarding.personalAccountPrefilledTitle")}`
        }
        description={`${t("public.onboarding.personalAccountTitleHint")}.`}
      />
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        initialValues={
          {
            firstName: userOnboardingInfo.firstName,
            lastName: userOnboardingInfo.lastName,
            companyName:
              userOnboardingInfo.companyName ||
              (userOnboardingInfo.email
                ? getPrefilledDomainFromEmailAddress(userOnboardingInfo.email)
                : ""),
            companyTitle: userOnboardingInfo.companyTitle ?? "",
          } as PersonalFormValues
        }
      >
        <Form className="" autoComplete="off" noValidate>
          <div className="flex items-center gap-3 mb-8">
            <InputField
              name="firstName"
              label={`${t("common.firstName")}*`}
              type="text"
              className="flex-1"
            />
            <InputField
              name="lastName"
              label={`${t("common.lastName")}*`}
              type="text"
              className="flex-1"
            />
          </div>
          <InputField
            name="companyName"
            label={t("common.companyName")}
            type="text"
          />
          <InputField
            name="companyTitle"
            label={t("common.companyTitle")}
            type="text"
            className="mt-8 mb-20"
          />
          <Button type="submit" variant="primary" className="w-full">
            {t("common.next")}
          </Button>
        </Form>
      </Formik>
    </div>
  );
};
