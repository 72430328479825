export enum OnboardingScenario {
  KS_USER_LOGIN = "KS_USER_LOGIN",
  KS_USER_SIGN_UP = "KS_USER_SIGN_UP",
  KS_USER_FORGOT_PASSWORD = "KS_USER_FORGOT_PASSWORD",
  NEW_USER_ONBOARDING = "NEW_USER_ONBOARDING",
}

export enum UserOnboardingStep {
  PERSONAL_ACCOUNT = "PERSONAL_ACCOUNT",
  EMAIL_ACCOUNT = "EMAIL_ACCOUNT",
  OTP_CODE = "OTP_CODE",
  PASSWORD_ACCOUNT = "PASSWORD_ACCOUNT",
  VERSION_UPDATE = "VERSION_UPDATE",
}

export type UserOnboardingProgressBarStep = Exclude<
  UserOnboardingStep,
  UserOnboardingStep.VERSION_UPDATE
>;

export const OnboardingUrlMap: Record<UserOnboardingStep, string> = {
  PERSONAL_ACCOUNT: "personal",
  EMAIL_ACCOUNT: "email",
  OTP_CODE: "otp",
  PASSWORD_ACCOUNT: "password",
  VERSION_UPDATE: "version-update",
};
