import { useState } from "react";

type WindowPathConfig = { path: string; isForceRedirect: boolean };

export const useOpenWindow = () => {
  const [windowRef, setWindowRef] = useState<Map<string, Window | null>>(
    new Map<string, Window>()
  );

  const isOpenerExists = (): boolean => window.opener && !window.opener.closed;

  /**
   * return false if opener not exists
   */
  const isOpenerTargetDomain = (url: string): boolean => {
    try {
      // user browser may block the accessing of opener
      return isOpenerExists() && window.opener.location.origin === url;
    } catch {
      return false;
    }
  };

  const isOpenerTargetPath = (path: string): boolean => {
    try {
      // user browser may block the accessing of opener
      return isOpenerExists() && window.opener.location.pathname === path;
    } catch {
      return false;
    }
  };

  const openWindow = (
    domain: string,
    config: WindowPathConfig = {
      path: "",
      isForceRedirect: false,
    }
  ) => {
    // prevent self recreation
    if (window.location.origin === domain) {
      return;
    }

    const targetWindow = windowRef.get(domain);

    if (isOpenerExists() && isOpenerTargetDomain(domain)) {
      if (!isOpenerTargetPath(config.path)) {
        window.opener.location.href = config.path;
      }
      window.opener.focus();
    } else if (
      targetWindow === undefined ||
      targetWindow === null ||
      targetWindow.closed
    ) {
      const newWindow = window.open(`${domain}${config.path}`, `CBK_${domain}`);
      setWindowRef((prev) => prev.set(domain, newWindow));
    } else {
      if (config.isForceRedirect) {
        targetWindow.location = `${domain}${config.path}`;
      }
      targetWindow.focus();
    }
  };

  return { openWindow };
};
