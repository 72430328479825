import { ApplicationDescription } from "features/applicationDashboard/components/ApplicationDescription";
import { Title } from "features/applicationDashboard/components/Title";
import { ApplicationInfo } from "features/applicationDashboard/types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type YouMayAlsoLikeProps = {
  data: ApplicationInfo[];
};

export const YouMayAlsoLike = ({ data }: YouMayAlsoLikeProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = (app: ApplicationInfo) => {
    navigate(`/dashboard/details/${app.id}`);
  };

  return (
    <div className="col-span-4">
      <Title
        className="w-full mb-8"
        title={t("application.title.youMayAlsoLike")}
      />
      {data.map((app, index) => (
        <div
          key={index}
          className="cursor-pointer"
          onClick={() => handleClick(app)}
        >
          <ApplicationDescription
            icon={app.icon}
            title={t(app.name)}
            description={t(app.description)}
            tag={app.category}
          />
          {index === 0 && <hr className="col-span-full my-6 text-greyscale" />}
        </div>
      ))}
    </div>
  );
};
