import { OTPFormValues } from "features/public/Register/components/OTPForm";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import OtpInput from "react-otp-input";

export const OTPFormBody = () => {
  const { values, errors, setFieldValue, handleSubmit } =
    useFormikContext<OTPFormValues>();

  useEffect(() => {
    values.otp.length === 6 && handleSubmit();
  }, [handleSubmit, values]);

  return (
    <OtpInput
      value={values.otp}
      numInputs={6}
      isInputNum={true}
      inputStyle={{
        border: `1px solid ${errors.otp ? "#F1416C" : "transparent"}`,
        borderRadius: "4px",
        width: "57px",
        height: "60px",
        color: "#000",
        background: "#F5F8FA",
        fontWeight: "400",
        padding: "8px",
      }}
      containerStyle="flex gap-5 text-h1 font-medium"
      onChange={(otpCode: string) => {
        setFieldValue("otp", otpCode);
      }}
      data-cy="otp-input"
    />
  );
};
