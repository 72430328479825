import { convertTimeToMilisecond } from "common/utils/common";
import { useEffect, useState } from "react";

export enum TimeUnit {
  HOUR = "HOUR",
  MINUTE = "MINUTE",
  SECOND = "SECOND",
}

export const useCountDownTimer = (
  startTime: number,
  unit: TimeUnit = TimeUnit.SECOND
): {
  onResetTimer: () => void;
  timeLeft: number;
} => {
  const [timeLeft, setTimeLeft] = useState<number>(() => {
    return convertTimeToMilisecond(startTime, unit);
  });

  const resetTime = () => {
    setTimeLeft(convertTimeToMilisecond(startTime, unit));
  };

  useEffect(() => {
    if (!timeLeft) {
      return;
    }

    let countDownInterval: NodeJS.Timer;
    countDownInterval = setInterval(
      () => setTimeLeft((prev) => prev - convertTimeToMilisecond(1, unit)),
      convertTimeToMilisecond(1, unit)
    );
    return () => {
      clearInterval(countDownInterval);
    };
  }, [timeLeft, unit]);
  return {
    onResetTimer: resetTime,
    timeLeft,
  };
};
