import { ApplicationInfo } from "features/applicationDashboard/types";
import { createContext, Dispatch, SetStateAction, useState } from "react";

type LatestAppsContextProps = {
  apps: ApplicationInfo[] | undefined;
  setApps: Dispatch<SetStateAction<ApplicationInfo[] | undefined>>;
};

export const LatestAppsContext = createContext<LatestAppsContextProps>({
  apps: undefined,
  setApps: () => {},
});

type LatestAppsContextProviderProps = {
  children: React.ReactNode;
};

export const LatestAppsContextProvider = ({
  children,
}: LatestAppsContextProviderProps) => {
  const [apps, setApps] = useState<ApplicationInfo[] | undefined>(undefined);

  return (
    <LatestAppsContext.Provider
      value={{
        apps,
        setApps,
      }}
    >
      {children}
    </LatestAppsContext.Provider>
  );
};
