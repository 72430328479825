import { ReactComponent as SvgCheckCircle } from "assets/svg/check-circle.svg";
import React from "react";
import { useTranslation } from "react-i18next";

export const SentEmailView = () => {
  const { t } = useTranslation();
  return (
    <div className="pb-20">
      <h2 className="text-dark-blue">
        {t("public.onboarding.sentEmailTitle")}
      </h2>
      <SvgCheckCircle
        width={48}
        height={48}
        className="text-light-blue mt-10"
      />
      <p className="text-lg text-dark-grey mt-8 leading-6.5">
        {t("public.onboarding.sentEmailDescription")}
      </p>
    </div>
  );
};
