import { FIELD_IS_REQUIRED_ERROR } from "constants/commonValidatorError";

import format from "date-fns/format";
import {
  OnboardingScenario,
  UserOnboardingStep,
} from "features/public/Register/types";
import { PDFFile } from "features/public/types";
import freeEmailDomains from "free-email-domains";
import { t } from "i18next";
import capitalize from "lodash/capitalize";
import { ISODate } from "types/common";
export const isFileDuplicate = (
  fileCheck: PDFFile,
  targetName: string,
  targetSize: number
) => {
  return fileCheck.name === targetName && fileCheck.size === targetSize;
};

export const formatDate = (date: ISODate) => {
  return format(new Date(date), "dd-MMM-yyyy");
};

export const NO_FILE_UPLOADED_ERROR = "public.onboarding.noFileUpload";
export const DUPLICATE_FILE_UPLOADED_ERROR =
  "public.onboarding.fileUploadDuplicate";
export const FILE_NOT_UPLOAD_COMPLETED_ERROR =
  "public.onboarding.fileNotCompletedError";
export const FILE_TYPE_INVALID_ERROR = "public.onboarding.fileTypeError";
export const FILE_SIZE_EXCEED_ERROR = "public.onboarding.fileSizeError";
export const TOO_MANY_FILES_ERROR = "public.onboarding.tooManyFilesError";
export const FILES_CAN_NOT_UPLOAD = "public.onboarding.fileCanNotUploadError";
export const SUPPORT_REQUEST_FILES_UPLOAD_LIMIT_ERROR =
  "public.onboarding.supportRequestFilesUploadLimitError";

/* Scenario 2 */
export const KS_USER_LOGIN_FLOW = [
  UserOnboardingStep.VERSION_UPDATE,
  UserOnboardingStep.OTP_CODE,
  UserOnboardingStep.PERSONAL_ACCOUNT,
  UserOnboardingStep.PASSWORD_ACCOUNT,
];

/* Scenario 3 */
export const KS_USER_SIGN_UP_BEFORE_VERSION_UPDATE_FLOW = [
  UserOnboardingStep.EMAIL_ACCOUNT,
  UserOnboardingStep.OTP_CODE,
  UserOnboardingStep.VERSION_UPDATE,
];

export const KS_USER_SIGN_UP_AFTER_VERSION_UPDATE_FLOW = [
  UserOnboardingStep.PERSONAL_ACCOUNT,
  UserOnboardingStep.PASSWORD_ACCOUNT,
];

/* Scenario 4 */
export const KS_USER_FORGOT_PASSWORD_FLOW = [
  UserOnboardingStep.VERSION_UPDATE,
  UserOnboardingStep.PERSONAL_ACCOUNT,
  UserOnboardingStep.PASSWORD_ACCOUNT,
];

/* Scenario 5 */
export const NEW_USER_ONBOARDING_FLOW = [
  UserOnboardingStep.EMAIL_ACCOUNT,
  UserOnboardingStep.OTP_CODE,
  UserOnboardingStep.PERSONAL_ACCOUNT,
  UserOnboardingStep.PASSWORD_ACCOUNT,
];

// Decide which step register popup show for each scenario
export const RegisterPopupStepMap: Record<
  OnboardingScenario,
  UserOnboardingStep
> = {
  KS_USER_LOGIN: UserOnboardingStep.PERSONAL_ACCOUNT,
  KS_USER_SIGN_UP: UserOnboardingStep.PERSONAL_ACCOUNT,
  KS_USER_FORGOT_PASSWORD: UserOnboardingStep.PERSONAL_ACCOUNT,
  NEW_USER_ONBOARDING: UserOnboardingStep.EMAIL_ACCOUNT,
};

export const getUserOnboardingFlow = (scenario: OnboardingScenario) => {
  switch (scenario) {
    case OnboardingScenario.KS_USER_LOGIN:
      return KS_USER_LOGIN_FLOW;
    case OnboardingScenario.KS_USER_SIGN_UP:
      return KS_USER_SIGN_UP_BEFORE_VERSION_UPDATE_FLOW.concat(
        KS_USER_SIGN_UP_AFTER_VERSION_UPDATE_FLOW
      );
    case OnboardingScenario.KS_USER_FORGOT_PASSWORD:
      return KS_USER_FORGOT_PASSWORD_FLOW;
    default:
      return NEW_USER_ONBOARDING_FLOW;
  }
};

/**
 * Function will return domain if email is not free domain. If email is free domain, return empty string
 * */
export const getPrefilledDomainFromEmailAddress = (email: string) => {
  const emailDomain = email.split("@")[1];
  return freeEmailDomains.includes(emailDomain)
    ? ""
    : capitalize(emailDomain.split(".")[0]);
};

export enum PasswordFormType {
  REGISTER_PASSWORD = "REGISTER_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
}
export const isCommonErrorShowInPasswordForm = (
  formType: PasswordFormType,
  passwordError?: string,
  confirmPasswordError?: string,
  currentPasswordError?: string
): boolean => {
  switch (formType) {
    case PasswordFormType.UPDATE_PASSWORD:
      return (
        currentPasswordError !== t(FIELD_IS_REQUIRED_ERROR) &&
        !!(
          passwordError &&
          !confirmPasswordError &&
          passwordError !== t(FIELD_IS_REQUIRED_ERROR)
        )
      );
    default:
      return !!(
        passwordError &&
        confirmPasswordError !== t(FIELD_IS_REQUIRED_ERROR) &&
        passwordError !== t(FIELD_IS_REQUIRED_ERROR)
      );
  }
};
