import { ReactComponent as SvgWarning } from "assets/svg/warning.svg";
import { Button } from "common/components/Button";
import { TimeUnit, useCountDownTimer } from "common/hooks/useCountDownTimer";
import { useTranslation } from "react-i18next";

export const RefreshTokenExpiredPopup = ({
  onStay,
  onLeave,
}: {
  onStay: () => void;
  onLeave: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const { timeLeft: stayLoggedInTimeLeft } = useCountDownTimer(
    30,
    TimeUnit.SECOND
  );
  return (
    <div className="flex items-center flex-col">
      <SvgWarning width={48} height={48} className="text-light-blue mb-10" />
      <p className="font-medium text-center">
        {stayLoggedInTimeLeft > 0
          ? t("dashboard.session.notExpiredTitle")
          : t("dashboard.session.expiredTitle")}
      </p>
      <p className="mt-10 font-medium text-center text-xs mb-4 text-dark-grey">
        {stayLoggedInTimeLeft > 0
          ? t("dashboard.session.notExpiredTitleHint")
          : t("dashboard.session.expiredTitleHint")}
      </p>
      {stayLoggedInTimeLeft > 0 ? (
        <>
          <Button className="w-full mb-4" onClick={onStay} variant="primary">
            {t("dashboard.session.stayLoggedIn")} ({stayLoggedInTimeLeft / 1000}
            s)
          </Button>
          <Button className="w-full mb-4" onClick={onLeave} variant="secondary">
            {t("dashboard.session.logout")}
          </Button>
        </>
      ) : (
        <Button className="w-full mb-4" onClick={onLeave} variant="primary">
          {t("public.button.login")}
        </Button>
      )}
    </div>
  );
};
