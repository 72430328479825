import { SearchBar } from "common/components/SearchBar";
import { useErrorMessagePopupHandler } from "common/hooks/useErrorMessagePopupHandler";
import { useOpenWindow } from "common/hooks/useOpenWindow";
import { postTokenHandler } from "common/utils/cross-domain/react/postTokenHandler";
import { Title } from "features/applicationDashboard/components/Title";
import {
  ApplicationIcon,
  ApplicationInfo,
} from "features/applicationDashboard/types";
import { useLoadingModal } from "features/public/ForgotPassword/hooks/useLoadingModal";
import { useTranslation } from "react-i18next";

type MyApplicationsProps = {
  className?: string;
  data: ApplicationInfo[];
  onChange: (search: string) => void;
  isSearching: boolean;
};

export const MyApplications = ({
  className,
  data,
  onChange,
  isSearching,
}: MyApplicationsProps): JSX.Element => {
  const { t } = useTranslation();
  const { openWindow } = useOpenWindow();
  const [showLoadingModal, hideLoadingModal] = useLoadingModal();
  const { show: showErrorMessagePopup } = useErrorMessagePopupHandler();

  const handleIconClick = async (
    isProfileSetup: boolean,
    icon: ApplicationIcon
  ) => {
    showLoadingModal();
    try {
      if (!isProfileSetup) {
        throw new Error("Account Not Ready");
      }
      // broadcast again to prevent token missing in children domains
      await postTokenHandler();
      icon.url && openWindow(icon.url);
    } catch (error) {
      showErrorMessagePopup();
      console.error(error);
    }
    hideLoadingModal();
  };

  return (
    <div
      className={`mb-10 ${className && className}`}
      data-cy="my-applications"
    >
      <div className="grid items-center grid-cols-8">
        <Title
          className="col-span-3"
          title={t(
            isSearching
              ? "application.enquirePopup.selectFieldLabel"
              : "application.title.myApplications"
          )}
        />
        {/*TODO: search bar*/}
        <div className="col-start-6 col-span-4">
          <SearchBar onChange={onChange} />
        </div>
      </div>
      {!isSearching && (
        <div
          className="col-span-full grid grid-cols-10 mt-10"
          data-cy="my-application-list"
          role="listitem"
        >
          {data &&
            data.map(({ isProfileSetup, icon }, index) => (
              <div className="col-span-2" key={index}>
                <img
                  className="cursor-pointer w-20 h-20"
                  src={icon.src}
                  alt={icon.alt}
                  onClick={() => {
                    handleIconClick(isProfileSetup, icon);
                  }}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
