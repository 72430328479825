import KentroHeaderLogo from "assets/images/kentro-header-logo.png";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";

type HeaderProps = {
  children: ReactNode;
  className?: string;
};

const Wrapper = styled.div(() => [
  tw`w-full z-header relative`,
  tw`text-grey `,
  tw`bg-white`,
  tw`border-b-4 border-light-greyscale`,
]);

const ContentContainer = styled.div(() => [
  tw`w-full grid grid-cols-12 col-start-2`,
  tw`relative py-3`,
]);

const LeftContainer = styled.div(tw`col-span-2 flex items-center`);
const RightContainer = styled.div(
  tw`col-span-1 col-end-13 flex justify-end items-center`
);

export const Header = ({ children, className }: HeaderProps): JSX.Element => {
  return (
    <Wrapper className={className}>
      <ContentContainer>
        <LeftContainer>
          <Link to={`/dashboard`}>
            <img
              className="w-40 h-7"
              src={KentroHeaderLogo}
              alt="Kentro Header Logo"
            />
          </Link>
        </LeftContainer>
        <RightContainer>{children}</RightContainer>
      </ContentContainer>
    </Wrapper>
  );
};
