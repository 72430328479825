export const MAX_FILE_SIZE = 15 * 1024 * 1024; // 15MB

export const PNG_HEADER = "data:image/png;base64,";

export enum FILE_TYPE {
  JPG = "JPG",
  JPEG = "JPEG",
  PNG = "PNG",
  JSON = "JSON",
  PDF = "PDF",
}
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const MIME_RECORD: Record<FILE_TYPE, string> = {
  JPG: "image/jpeg",
  JPEG: "image/jpeg",
  PNG: "image/png",
  JSON: "application/json",
  PDF: "application/pdf",
};

export const FILE_EXTENSION_RECORD: Record<FILE_TYPE, string> = {
  JPG: ".jpg",
  JPEG: ".jpeg",
  PNG: ".png",
  JSON: ".json",
  PDF: ".pdf",
};
