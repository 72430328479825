import { publicOtpApi } from "api/publicOtp";
import { useUserOnboardingInfo } from "features/public/Register/hooks/useUserOnboardingInfo";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const COUNT_DOWN_TIME = 30;
export const OTPResend = (): JSX.Element => {
  const { t } = useTranslation();
  const [countDownTime, setCountDownTime] = useState(COUNT_DOWN_TIME);
  const [timeoutEnable, setTimeoutEnable] = useState(false);
  const { userOnboardingInfo } = useUserOnboardingInfo();

  useEffect(() => {
    if (!timeoutEnable) {
      return;
    }
    const timeoutInterval = setInterval(() => {
      setCountDownTime((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timeoutInterval);
  }, [timeoutEnable]);

  useEffect(() => {
    if (countDownTime < 0) {
      setTimeoutEnable(false);
      setCountDownTime(30);
    }
  }, [countDownTime]);

  const handleRequestOTP = async (email: string) => {
    try {
      await publicOtpApi.requestOtp(
        email,
        userOnboardingInfo.firstName,
        userOnboardingInfo.lastName
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleResend = async () => {
    setTimeoutEnable(true);
    await handleRequestOTP(userOnboardingInfo.email);
  };
  return (
    <div className="mt-9 text-dark-grey font-medium text-sm leading-5.5">
      {timeoutEnable ? (
        <div>{t("public.onboarding.OTPTimeout", { time: countDownTime })}</div>
      ) : (
        <div>
          <span>{t("public.onboarding.OTPNotReceive")}</span>
          <span
            className="text-light-blue cursor-pointer ml-1"
            onClick={handleResend}
          >
            {t("public.onboarding.OTPResend")}
          </span>
        </div>
      )}
    </div>
  );
};
