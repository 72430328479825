import { Tab } from "@headlessui/react";
import classNames from "classnames";

type StyledTabProps = {
  onClick?: () => void;
  children: React.ReactNode;
};

export const StyledTab = ({ onClick = () => {}, children }: StyledTabProps) => (
  <Tab
    className={({ selected }) =>
      classNames(
        "font-medium text-sm",
        "border-b-2 py-3",
        selected
          ? "border-light-blue text-light-blue"
          : "border-transparent text-grey"
      )
    }
    onClick={onClick}
  >
    {children}
  </Tab>
);
