import { userAPI } from "api/user";
import { Button } from "common/components/Button";
import { InputField } from "common/components/Form/InputField";
import { GridWrapper } from "common/components/GridWrapper";
import {
  baseFallbackErrorHandler,
  composeErrorHandlers,
  GenericError,
} from "common/errorHandling";
import { toast, ToastContainerId } from "common/toast";
import { EditProfileFormValues } from "features/userSettings/types";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "redux/hooks";
import { setCurrentUser } from "redux/slices/auth/authSlice";
import tw, { styled } from "twin.macro";
import { CurrentUserProfile, UpdateProfileSettingPayload } from "types/user";
import * as yup from "yup";

const StyledInputField = styled(InputField)`
  ${tw`text-xs leading-4`}
`;

export type EditProfileContentProps = {
  userProfile?: CurrentUserProfile | null;
};

export const EditProfileContent = ({
  userProfile,
}: EditProfileContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLanguage = "en"; // TODO: wait language setting api all finish fix this part

  const schema: yup.SchemaOf<EditProfileFormValues> = yup.object().shape({
    firstName: yup.string().required().trim(),
    lastName: yup.string().required().trim(),
    companyName: yup.string().trim().notRequired().nullable(),
    companyTitle: yup.string().trim().notRequired().nullable(),
  });

  const initialValues = {
    firstName: userProfile?.firstName || "",
    lastName: userProfile?.lastName || "",
    companyName: userProfile?.companyName || "",
    companyTitle: userProfile?.companyTitle || "",
  };

  const handleSubmitEditProfile = async (values: EditProfileFormValues) => {
    try {
      const updateBody: UpdateProfileSettingPayload = {
        personalDetail: {
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
        },
        company: {
          name: values.companyName ? values.companyName.trim() : null,
          title: values.companyTitle ? values.companyTitle.trim() : null,
        },
        setting: {
          languageCode: currentLanguage,
        },
      };

      const userRes = await userAPI.updateUserProfile(updateBody);
      if (userRes) {
        dispatch(setCurrentUser(userRes));
        navigate("/settings/user-profile");
        toast.success(t("common.toast.userProfile"));
      }
    } catch (error) {
      composeErrorHandlers(() =>
        baseFallbackErrorHandler(ToastContainerId.AfterLogin)
      )(error as GenericError);
      console.error(error);
    }
  };

  return (
    <div className="w-full">
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmitEditProfile}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({ isSubmitting, dirty }) => (
          <Form autoComplete="off" noValidate>
            <GridWrapper>
              <div className="col-span-6 ml-8">
                <StyledInputField
                  name="firstName"
                  label={t("settings.editProfile.firstName")}
                  type="text"
                />
              </div>
              <div className="col-span-6 mr-8">
                <StyledInputField
                  name="lastName"
                  label={t("settings.editProfile.lastName")}
                  type="text"
                />
              </div>
            </GridWrapper>

            <GridWrapper className="mt-5">
              <div className="col-span-6 ml-8">
                <StyledInputField
                  name="companyName"
                  label={t("common.companyName")}
                  type="text"
                />
              </div>
              <div className="col-span-6 mr-8">
                <StyledInputField
                  name="companyTitle"
                  label={t("common.companyTitle")}
                  type="text"
                />
              </div>
            </GridWrapper>

            <GridWrapper className="mt-10">
              <Button
                disabled={!dirty || isSubmitting}
                variant="primary"
                className="col-end-13 col-span-2 mr-8 tracking-wider !min-w-fit"
              >
                {t("common.submit")}
              </Button>
            </GridWrapper>
          </Form>
        )}
      </Formik>
    </div>
  );
};
