import { INPUT_MAX_LENGTH } from "constants/common";

import { FieldContainer } from "common/components/Form/FieldContainer";
import { StyledField } from "common/components/Form/StyleField";
import { useField } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type PasswordFieldProps = {
  name: string;
  label?: string;
  type?: string;
  placeholder?: string;
  errorMsgClassName?: string;
  hideErrorMsg?: boolean;
  className?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  isErrorShow?: boolean;
  maxLength?: number;
};

const StyledInlineButtonField = tw.div`relative inline-block bg-background-grey w-full`;
/** 
 Don't use tag <Button/> because this tag has focus. When clicked,
 it will cause the tag <Field/> onblur and trigger validation
 **/
const StyledInlineButton = tw.div`absolute top-4 right-4 text-grey text-xxs font-bold cursor-pointer tracking-[1.5px] uppercase`;

export const PasswordField = ({
  name,
  label,
  type,
  placeholder,
  errorMsgClassName,
  hideErrorMsg,
  className,
  onBlur,
  onFocus,
  isErrorShow = false,
  maxLength = INPUT_MAX_LENGTH,
}: PasswordFieldProps): JSX.Element => {
  const [, meta] = useField(name);
  const [isShow, setIsShow] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <FieldContainer
      className={`${className}`}
      name={name}
      label={label}
      hideErrorMsg={hideErrorMsg}
      errorMsgClassName={errorMsgClassName}
      fieldError={meta.error}
    >
      <StyledInlineButtonField>
        <StyledField
          id={name}
          type={isShow ? "text" : "password"}
          name={name}
          placeholder={placeholder}
          $hasError={!!meta.error && isErrorShow}
          $hideErrorMsg={hideErrorMsg}
          onFocus={onFocus}
          maxLength={maxLength}
          autoComplete="off"
          trimvalue="false"
        />
        <StyledInlineButton
          onClick={() => {
            setIsShow((prevState) => !prevState);
          }}
        >
          {isShow ? t("common.hide") : t("common.show")}
        </StyledInlineButton>
      </StyledInlineButtonField>
    </FieldContainer>
  );
};
