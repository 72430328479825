import { SelectField, SelectOption } from "common/components/Form/SelectField";
import { useFormikContext, Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StylesConfig } from "react-select";

type LanguageSelectionProp = {
  options: SelectOption[];
  onSelect: (language: SelectOption) => void;
};

type AutoSubmitSelectionProp = {
  currentValue: string;
};

type SelectLanguageValue = {
  selectLanguage: string;
};

const customLanguageSelect: StylesConfig<SelectOption> = {
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#B5B5C3",
  }),
  control: (base) => ({
    ...base,
    paddingTop: 10,
    paddingBottom: 10,
    border: "none",
    background: "#F5F8FA",
    boxShadow: "none",
    color: "#3F4254",
    cursor: "pointer",
  }),
  menu: (base) => ({
    ...base,
    boxShadow: "none",
    background: "#F5F8FA",
    color: "#3F4254",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    boxShadow: "0px 4px 16px rgba(160, 160, 160, 0.25)",
  }),
  option: (base, state) => {
    return {
      ...base,
      paddingTop: 10,
      paddingBottom: 10,
      background: state.isSelected ? "#F5F8FA" : "",
      "&:hover": {
        background: "rgba(128, 198, 255, 0.7)",
      },
      color: "#3F4254",
      borderRadius: "5px",
      cursor: "pointer",
    };
  },
  singleValue: (base) => ({
    ...base,
    color: "#3F4254",
  }),
};

const AutoSubmitSelection = ({ currentValue }: AutoSubmitSelectionProp) => {
  const { values, submitForm } = useFormikContext<SelectLanguageValue>();

  useEffect(() => {
    const newValue = values.selectLanguage;
    typeof newValue === "string" && newValue !== currentValue && submitForm();
  }, [currentValue, values, submitForm]);

  return null;
};

export const LanguageSelection = ({
  options,
  onSelect: handleSelectLanguage,
}: LanguageSelectionProp): JSX.Element => {
  const { t, i18n } = useTranslation();

  const findLanguageOption = (selectedValue: String): SelectOption =>
    options.find(({ value }) => value === selectedValue) ?? options[0];

  const [currentLanguage, setCurrentLanguage] = useState<SelectOption>(
    findLanguageOption(i18n.language)
  );

  const handleSubmitForm = (selectedValue: string): void => {
    const selectedOption = findLanguageOption(selectedValue);
    setCurrentLanguage(selectedOption);
    handleSelectLanguage(selectedOption);
  };

  return (
    <div className="p-10 rounded-md bg-white">
      <h2 className="text-dark-blue">{t("settings.language.title")}</h2>
      <p className="py-3 text-dark-grey">
        {t("settings.languageSettings.description")}
      </p>
      <Formik
        initialValues={{ selectLanguage: currentLanguage }}
        onSubmit={({ selectLanguage }) => {
          typeof selectLanguage === "string" &&
            handleSubmitForm(selectLanguage);
        }}
      >
        <Form>
          <SelectField
            className="border-transparent"
            name="selectLanguage"
            label=""
            options={options}
            defaultValue={currentLanguage}
            selectStyles={customLanguageSelect}
            isSearchable={false}
          />
          <AutoSubmitSelection currentValue={currentLanguage.value} />
        </Form>
      </Formik>
    </div>
  );
};
