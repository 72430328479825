import { VerifyOtpResponse } from "api/publicOtp";
import {
  OnboardingScenario,
  OnboardingUrlMap,
  UserOnboardingStep,
} from "features/public/Register/types";
import { getUserOnboardingFlow } from "features/public/utils/onboarding";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountType } from "types/user";

type UserOnboardingInfoProps = Omit<VerifyOtpResponse, "userProfile"> & {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  userType: AccountType.INDIVIDUAL;
  companyName?: string;
  companyTitle?: string;
  applicationName?: string;
};

type UserOnboardingContextProviderProps = {
  children: ReactNode;
};

type UserOnboardingContextProps = {
  userOnboardingInfo: UserOnboardingInfoProps;
  setUserOnboardingInfo: Dispatch<
    React.SetStateAction<UserOnboardingInfoProps>
  >;
  currentRoute: UserOnboardingStep;
  onboardingScenario: OnboardingScenario;
  allSteps: UserOnboardingStep[];
  onNextStep: () => void;
  onPreviousStep: () => void;
  setOnboardingScenario: Dispatch<React.SetStateAction<OnboardingScenario>>;
  clearOnboardingInfo: () => void;
};

export const UserOnboardingContext = createContext<UserOnboardingContextProps>({
  userOnboardingInfo: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    userType: AccountType.INDIVIDUAL,
    createdAt: null,
    lastLoginAt: null,
    resetPasswordToken: null,
    actionToken: "",
  },
  currentRoute: UserOnboardingStep.PERSONAL_ACCOUNT,
  onboardingScenario: OnboardingScenario.NEW_USER_ONBOARDING,
  allSteps: [],
  setUserOnboardingInfo: () => {},
  onNextStep: () => {},
  onPreviousStep: () => {},
  setOnboardingScenario: () => {},
  clearOnboardingInfo: () => {},
});

export const DEFAULT_USER_ONBOARDING_INFO: UserOnboardingInfoProps = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  userType: AccountType.INDIVIDUAL,
  createdAt: null,
  lastLoginAt: null,
  resetPasswordToken: null,
  actionToken: "",
};
export const UserOnboardingContextProvider = ({
  children,
}: UserOnboardingContextProviderProps) => {
  const navigate = useNavigate();
  const [onboardingScenario, setOnboardingScenario] =
    useState<OnboardingScenario>(OnboardingScenario.NEW_USER_ONBOARDING);
  const [userOnboardingInfo, setUserOnboardingInfo] =
    useState<UserOnboardingInfoProps>(DEFAULT_USER_ONBOARDING_INFO);
  const [currentRouteIndex, setCurrentRouteIndex] = useState(0);
  const location = useLocation();

  const USER_ONBOARDING_STEPS = getUserOnboardingFlow(onboardingScenario);

  const currentRoute = useMemo(() => {
    return USER_ONBOARDING_STEPS[currentRouteIndex];
  }, [USER_ONBOARDING_STEPS, currentRouteIndex]);

  /* Next Step */
  const onNextStep = useCallback(() => {
    if (currentRouteIndex !== USER_ONBOARDING_STEPS.length - 1) {
      setCurrentRouteIndex((prev) => prev + 1);
      navigate(
        `/public/register/${
          OnboardingUrlMap[USER_ONBOARDING_STEPS[currentRouteIndex + 1]]
        }`
      );
    }
  }, [navigate, currentRouteIndex, USER_ONBOARDING_STEPS]);

  /* Previous Step */
  const onPreviousStep = useCallback(() => {
    if (location.pathname.includes("account-existed")) {
      navigate(-1);
    }
    if (currentRouteIndex !== 0) {
      setCurrentRouteIndex((prev) => prev - 1);
    } else {
      // Redirect to login page when have no previous page
      navigate("/public");
    }
  }, [navigate, currentRouteIndex, location.pathname]);

  const clearOnboardingInfo = useCallback(() => {
    setUserOnboardingInfo(DEFAULT_USER_ONBOARDING_INFO);
    setOnboardingScenario(OnboardingScenario.NEW_USER_ONBOARDING);
    setCurrentRouteIndex(0);
  }, []);

  return (
    <UserOnboardingContext.Provider
      value={{
        onboardingScenario,
        userOnboardingInfo,
        currentRoute,
        allSteps: USER_ONBOARDING_STEPS,
        setOnboardingScenario,
        setUserOnboardingInfo,
        onNextStep,
        onPreviousStep,
        clearOnboardingInfo,
      }}
    >
      {children}
    </UserOnboardingContext.Provider>
  );
};
