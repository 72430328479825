export enum CrossDomainMessageType {
  MESSAGE = "MESSAGE",
  RESPONSE = "RESPONSE",
}

export enum LOCAL_STORAGE_KEY {
  ACCESS_TOKEN = "accessToken",
  REFRESH_TOKEN = "refreshToken",
  TIMEOUT = "timeout",
}

export type CrossDomainMessageValue = {
  key: LOCAL_STORAGE_KEY;
  value: string;
};
