import { request } from "common/HOC/WithRequest";
import { UUID } from "types/common";
import { CurrentUserProfile, UpdateProfileSettingPayload } from "types/user";

export const userAPI = {
  getCurrentUserInfo: (): Promise<CurrentUserProfile> => {
    return request<CurrentUserProfile>("user/profile", "GET");
  },
  getUserProfileApplicationStatus: (applicationId: UUID): Promise<boolean> => {
    return request<boolean>(`user/profile/app/${applicationId}/status`, "GET");
  },
  updateUserProfile: (
    updateUserProfileBody: UpdateProfileSettingPayload
  ): Promise<CurrentUserProfile> => {
    return request("user/profile", "PUT", updateUserProfileBody);
  },
  changeUserPassword: (oldPwd: string, newPwd: string) => {
    const bodyData = { old: oldPwd, new: newPwd };
    return request("user/password", "PUT", bodyData);
  },
};
