import {
  REG_HEAD_SPACE,
  REG_MIDDLE_SPACE,
  REG_TAIL_SPACE,
} from "constants/common";
import { MIME_RECORD, FILE_TYPE, FILE_EXTENSION_RECORD } from "constants/file";

import { TimeUnit } from "common/hooks/useCountDownTimer";
import { Colors, getSecondaryColorByOrder } from "enums/colors";
import { X500Name } from "types/common";

export const emptyFunction = (): void => {
  /* */
};
export const emptyPromise = async (): Promise<undefined> => undefined;

export const getNameInitial = (name: string): string =>
  name[0]?.toUpperCase() ?? "";

export const lastOfArray = <T>(array: T[]): T | undefined =>
  array[array.length - 1];

export const parseX500Name = (x500Name: X500Name): Map<string, string> =>
  new Map(
    x500Name.split(", ").map((item) => {
      const [key, value] = item.split("=");
      return [key, value];
    })
  );

/**
 * two characters for English name, but one for non-English name
 */
export const getInitials = (fullName: string, delimiter = " ") => {
  const isEnglish = fullName.match(/^[a-zA-Z ,-]*$/);
  const words = fullName.trim().split(delimiter);
  return (
    (words.shift()?.[0] ?? "") + (isEnglish ? words.pop()?.[0] ?? "" : "")
  ).toUpperCase();
};

export const generateRandomInteger = (toMaxRange = 100) => {
  return Math.floor(Math.random() * toMaxRange);
};

export const downloadFile = (href: string, fileName: string) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.setAttribute("style", "display:none");
  fakeLink.download = fileName;
  fakeLink.href = href;
  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);
  fakeLink.remove();
};

export const generateFullName = (
  firstName: string | undefined,
  lastName: string | undefined
) => {
  return firstName && lastName
    ? `${firstName} ${lastName}`
    : firstName
    ? firstName
    : lastName
    ? lastName
    : "";
};

/**
 * Given a sorted array of distinct integers and a target value, return the index if the target is found.
 * If not, return the index where it would be if it were inserted in order.
 */
export const searchInsertPosition = <T>(inputArray: T[], target: T) => {
  if (!inputArray || inputArray.length === 0) {
    return 0;
  }

  let left = 0;
  let right = inputArray.length - 1;
  while (left < right) {
    const mid = Math.floor(left + (right - left) / 2);
    if (inputArray[mid] === target) {
      return mid;
    } else if (inputArray[mid] > target) {
      right = mid;
    } else {
      left = mid + 1;
    }
  }

  return inputArray[left] < target ? left + 1 : left;
};

export const hashStringToNumber = (text: string) => {
  let hash = 0,
    i,
    chr;
  if (text.length === 0) return hash;
  for (i = 0; i < text.length; i++) {
    chr = text.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return Math.abs(hash);
};

export const getSignatureColorByEmail = (
  signerEmails: string[],
  currentEmail: string
): Colors => {
  return getSecondaryColorByOrder(signerEmails.indexOf(currentEmail));
};

export const removeElementWithinArray = <T>(
  originArray: T[],
  removeIndex: number
): T[] => {
  if (removeIndex >= originArray.length || removeIndex < 0) {
    throw new Error("Invalid Index");
  }

  return [
    ...originArray.slice(0, removeIndex),
    ...originArray.slice(removeIndex + 1),
  ];
};

export const updateElementWithinArray = <T>(
  originArray: T[],
  newElement: T,
  updateIndex: number
): T[] => {
  if (updateIndex >= originArray.length || updateIndex < 0) {
    throw new Error("Invalid Index");
  }

  return [
    ...originArray.slice(0, updateIndex),
    newElement,
    ...originArray.slice(updateIndex + 1),
  ];
};

export const isEqualIgnoreCase = (
  a: string | undefined,
  b: string | undefined
) => {
  return (
    !!a && !!b && a.localeCompare(b, undefined, { sensitivity: "accent" }) === 0
  );
};

export const transformFileTypeToMIMEType = (
  acceptFileType: FILE_TYPE | FILE_TYPE[] | undefined
) => {
  if (acceptFileType) {
    if (typeof acceptFileType === "string") {
      return MIME_RECORD[acceptFileType];
    }
    return acceptFileType.map((type) => MIME_RECORD[type]);
  }
  return "";
};

export const transformFileTypeToFileExtension = (
  acceptFileType: FILE_TYPE | FILE_TYPE[] | undefined
) => {
  if (acceptFileType) {
    if (typeof acceptFileType === "string") {
      return FILE_EXTENSION_RECORD[acceptFileType];
    }
    return acceptFileType.map((type) => FILE_EXTENSION_RECORD[type]);
  }
  return "";
};

export const getFileTypeByFileName = (name: string) => {
  const fileTypeArray = name.match(/[^.]+$/);
  if (fileTypeArray) {
    const fileType = fileTypeArray[0].toLocaleUpperCase();
    return fileType as FILE_TYPE;
  }
  return null;
};

export const transformObjectToMap = <T extends object>(
  object: T
): Map<string, string> => {
  return new Map(Object.entries(object));
};

export const isSettingPage = (pathname: string): boolean => {
  return /\/settings\/([^/]+)/.test(pathname);
};

export const formatInputValue = (value: string): string => {
  if (REG_HEAD_SPACE.test(value)) {
    value = value.trimStart();
  }

  if (REG_MIDDLE_SPACE.test(value)) {
    value = value.replace(/\s+/g, " ");
  }

  if (REG_TAIL_SPACE.test(value)) {
    value = value.trimEnd();
  }

  return value;
};

export const getDateTimeAfter = (
  dateTimeBegin: Date,
  timeRange: number,
  timeUnit: TimeUnit
): Date => {
  switch (timeUnit) {
    case TimeUnit.HOUR:
      dateTimeBegin.setHours(dateTimeBegin.getHours() + timeRange);
      break;
    case TimeUnit.MINUTE:
      dateTimeBegin.setMinutes(dateTimeBegin.getMinutes() + timeRange);
      break;
    default:
      dateTimeBegin.setSeconds(dateTimeBegin.getSeconds() + timeRange);
      break;
  }
  const dateTimeAfter = new Date(dateTimeBegin);
  return dateTimeAfter;
};

export const convertTimeToMilisecond = (
  time: number,
  unit: TimeUnit = TimeUnit.SECOND
) => {
  switch (unit) {
    case TimeUnit.HOUR:
      return time * 3600000;
    case TimeUnit.MINUTE:
      return time * 60000;
    default:
      return time * 1000;
  }
};
