import { ReactComponent as SvgLeftArrow } from "assets/svg/left-arrow.svg";
import { VersionUpdate } from "features/public/components/VersionUpdate";
import { useRegisterPopupByScenario } from "features/public/hooks/useRegisterPopupByScenario";
import { AccountExist } from "features/public/Register/components/AccountExist";
import { EmailForm } from "features/public/Register/components/EmailForm";
import { OnboardingProgress } from "features/public/Register/components/OnboardingProgress";
import { OTPForm } from "features/public/Register/components/OTPForm";
import { PasswordAccountForm } from "features/public/Register/components/PasswordAccountForm";
import { PersonalForm } from "features/public/Register/components/PersonalForm";
import { useUserOnboardingInfo } from "features/public/Register/hooks/useUserOnboardingInfo";
import {
  OnboardingUrlMap,
  UserOnboardingStep,
} from "features/public/Register/types";
import { ContentWrapper } from "features/PublicRoutes";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import tw from "twin.macro";

export const BackButton = tw.div`absolute top-[78px] z-fixed col-start-1 col-end-1 text-grey flex items-center cursor-pointer`;
const RegisterContainer = tw.div`px-6 w-full h-full overflow-y-auto grid grid-cols-6 flex flex-col justify-center`;

export const RegisterRoutes = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  useRegisterPopupByScenario();

  const { currentRoute, onPreviousStep } = useUserOnboardingInfo();

  const initURL = OnboardingUrlMap[currentRoute];

  // Redirect to correct initURL by scenario
  useEffect(() => {
    if (location.pathname.includes("account-existed")) {
      return;
    }
    navigate(initURL);
  }, [initURL, navigate, location.pathname]);

  const isProgressBarShow = useMemo(() => {
    if (location.pathname.includes("account-existed")) {
      return false;
    } else return currentRoute !== UserOnboardingStep.VERSION_UPDATE;
  }, [location.pathname, currentRoute]);

  return (
    <>
      <BackButton onClick={onPreviousStep}>
        <SvgLeftArrow className="mr-3" />
        <span>{t("common.back")}</span>
      </BackButton>

      <ContentWrapper>
        <RegisterContainer>
          <div className="flex mt-60 justify-center col-start-2 col-end-6">
            <Routes>
              <Route index element={<Navigate to={initURL} />} />
              <Route path="personal" element={<PersonalForm />} />
              <Route path="email" element={<EmailForm />} />
              <Route path="otp" element={<OTPForm />} />
              <Route path="password" element={<PasswordAccountForm />} />
              <Route path="account-existed" element={<AccountExist />} />
              <Route path="version-update" element={<VersionUpdate />} />
            </Routes>
          </div>
        </RegisterContainer>
        {isProgressBarShow && (
          <OnboardingProgress className="w-full pb-6 h-fit mt-auto" />
        )}
      </ContentWrapper>
    </>
  );
};
