import { Button } from "common/components/Button";
import { useTranslation } from "react-i18next";

type SubscribedButtonProps = {
  isSubscribed: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

export const SubscribedButton = ({
  isSubscribed,
  onClick,
  className,
}: SubscribedButtonProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Button
      className="w-full"
      variant="primary"
      disabled={isSubscribed}
      onClick={onClick}
    >
      {isSubscribed
        ? t("application.button.subscribed")
        : t("application.button.enquireMore")}
    </Button>
  );
};
