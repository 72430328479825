import { useInfiniteAppList } from "common/hooks/useInfiniteAppList";
import { ApplicationInfo } from "features/applicationDashboard/types";
import { useMemo } from "react";

export type useYouMayAlsoLikeDataProps = {
  data: ApplicationInfo[];
};

export const useYouMayAlsoLikeData = (
  currentApp: ApplicationInfo
): useYouMayAlsoLikeDataProps => {
  const { apps } = useInfiniteAppList();

  const appListWithoutCurrentApp = useMemo(
    () => apps?.filter((app) => app.name !== currentApp.name),
    [currentApp, apps]
  );
  /**TODO : The current backend has not done the first sort unsubscribe on apps api, but it will affect put you may also like data here
     After waiting, there is no subscription prioritization on api,front-end can remove the logic here*/
  const subscribedAppList = useMemo(
    () => appListWithoutCurrentApp?.filter((app) => app.isSubscribed) ?? [],
    [appListWithoutCurrentApp]
  );
  const unSubscribedAppList = useMemo(
    () => appListWithoutCurrentApp?.filter((app) => !app.isSubscribed) ?? [],
    [appListWithoutCurrentApp]
  );
  const theFirstTwoData = useMemo(() => {
    return unSubscribedAppList.concat(subscribedAppList).slice(0, 2);
  }, [unSubscribedAppList, subscribedAppList]);

  return {
    data: theFirstTwoData,
  };
};
