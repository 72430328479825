import { request, TokenResponse } from "common/HOC/WithRequest";

const authPrefix = "auth";

export const authAPI = {
  login: (data: LoginRequest): Promise<LoginResponse> => {
    return request<LoginResponse, LoginRequest>("login", "POST", data);
  },
  logout: (): Promise<unknown> => {
    return request<unknown>("logout", "POST", undefined);
  },
  refreshToken: (refreshToken: string): Promise<TokenResponse> => {
    return request<TokenResponse>(
      `${authPrefix}/token/refresh`,
      "POST",
      undefined,
      {
        headers: {
          "refresh-token": refreshToken,
        },
      }
    );
  },
};

type LoginRequest = {
  username: string;
  password: string;
};

type LoginResponse = {
  mfaRequired: boolean;
  mfaSecret: {
    base64: string;
    qrcodePngBase64: string;
  };
};
