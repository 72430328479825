import { isTopWindow, ORIGIN_URL } from "common/utils/cross-domain/base/utils";

type ChildrenIFrameProps = {
  childrenDomain: ORIGIN_URL[];
};
export const ChildrenIFrame = ({ childrenDomain }: ChildrenIFrameProps) => {
  return (
    <>
      {isTopWindow() &&
        childrenDomain.map((domain) => (
          <iframe
            key={domain}
            id={domain}
            title="token-iframe"
            className="hidden"
            src={domain}
          />
        ))}
    </>
  );
};
