import { BaseUploadedFiles } from "common/components/BaseUploadedFiles";
import { SupportRequestFormValues } from "features/public/ResetPassword/components/SupportRequestForm";
import { useFormikContext } from "formik";

type SupportRequestUploadedFilesListProps = {
  className?: string;
};
export const SupportRequestUploadedFilesList = ({
  className,
}: SupportRequestUploadedFilesListProps) => {
  const { values } = useFormikContext<SupportRequestFormValues>();
  return (
    <BaseUploadedFiles
      values={values.attachments}
      className={className}
      size="sm"
    />
  );
};
