import { RecentlyUsed } from "common/components/RecentlyUsed";
import { VersionDisplay } from "common/components/VersionDisplay";
import { WithEmptyMessage } from "common/HOC/WithEmptyMessage";
import { WithLoading } from "common/HOC/WithLoading";
import { useInfiniteAppList } from "common/hooks/useInfiniteAppList";
import { ApplicationList } from "features/applicationDashboard/pages/dashboard/components/ApplicationList";
import { ExploreOtherApplications } from "features/applicationDashboard/pages/dashboard/components/ExploreOtherApplications";
import { MyApplications } from "features/applicationDashboard/pages/dashboard/components/MyApplications";
import { ApplicationInfo } from "features/applicationDashboard/types";
import { MockAppIconData } from "mock/MockApplicationDashboardData";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";

export const StyledDashboardCard = tw.div`bg-white rounded-lg shadow-primary px-8 py-10`;

type ExploreOtherApplicationsProps = {
  className?: string;
};

const AppListWithLoading = WithLoading(ApplicationList);
const AppListWithEmptyMessage = WithEmptyMessage(AppListWithLoading);

export const DashboardView = ({
  className,
}: ExploreOtherApplicationsProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState("");

  const { apps, isLoading } = useInfiniteAppList(searchValue);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const myApplicationData = useMemo(
    () => apps.filter((app) => app.isSubscribed),
    [apps]
  );
  const recentlyUsedData = MockAppIconData;
  const isSearching = !!searchValue;

  const onChangeSearchValue = useCallback((searchValue: string) => {
    setSearchValue(searchValue);
  }, []);

  const handleClickListItem = (app: ApplicationInfo) => {
    navigate(`/dashboard/details/${app.id}`);
  };

  return (
    <div className="w-full grid grid-cols-12 gap-x-5">
      <StyledDashboardCard className="col-span-full flex flex-col space-y-10">
        <MyApplications
          data={myApplicationData}
          onChange={onChangeSearchValue}
          isSearching={isSearching}
        />
        {isSearching ? (
          <AppListWithEmptyMessage
            data={apps}
            isLoading={isLoading}
            onClick={handleClickListItem}
            isEmpty={!apps.length}
            message={t("common.noResult")}
          />
        ) : (
          <ExploreOtherApplications data={apps} onClick={handleClickListItem} />
        )}
      </StyledDashboardCard>
      <VersionDisplay versionCondition=">=2.0.0">
        <div className="col-span-4">
          <RecentlyUsed data={recentlyUsedData} />
        </div>
      </VersionDisplay>
    </div>
  );
};
