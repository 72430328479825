import { ReactComponent as SvgCheckCircle } from "assets/svg/check-circle.svg";
import { Button } from "common/components/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const PasswordResetSuccessfulView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleContinue = () => {
    navigate("/public");
  };
  return (
    <div className="mb-20">
      <SvgCheckCircle width={48} height={48} className="text-light-blue" />
      <h1 className="text-dark-blue mt-8">
        {t("public.onboarding.resetPwdSuccessTitle")}
      </h1>
      <p className="text-dark-grey mt-3">
        {t("public.onboarding.resetPwdSuccessTitleHint")}
      </p>

      {/* TODO KT-35 Forgot password integration */}
      <Button
        variant="primary"
        className="mt-44 w-full"
        onClick={handleContinue}
      >
        {t("common.continue")}
      </Button>
    </div>
  );
};
