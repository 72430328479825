import { ReactComponent as SvgLanguage } from "assets/svg/language.svg";

type LanguageSwitchProps = {
  currentLanguage: string;
  className?: string;
};

export const LanguageSwitch = ({
  currentLanguage,
  className,
}: LanguageSwitchProps): JSX.Element => {
  return (
    <div
      className={`flex items-center justify-end gap-2.5 font-medium leading-5.5 text-light-blue ${className}`}
    >
      <div className="shrink-0">
        <SvgLanguage className="w-full" />
      </div>
      <span>{currentLanguage}</span>
    </div>
  );
};
