import { SAME_PASSWORD_ERROR } from "constants/passwordFormatError";

import { publicUserAPI } from "api/publicUser";
import {
  composeErrorHandlers,
  GenericError,
  isAPIError,
} from "common/errorHandling";
import { useUserOnboardingInfo } from "features/public/Register/hooks/useUserOnboardingInfo";
import { OnboardingScenario } from "features/public/Register/types";
import {
  ResetPasswordForm,
  ResetPasswordFormValues,
} from "features/public/ResetPassword/components/ResetPasswordForm";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormSubmissionHandler } from "types/common";

export const ResetPasswordView = ({
  setToken,
}: {
  setToken: (value: string | null) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetPwdToken = searchParams.get("resetToken");
  const migrationToken = searchParams.get("migrationToken");
  const actionToken = searchParams.get("actionToken");

  const { setOnboardingScenario, setUserOnboardingInfo, onNextStep } =
    useUserOnboardingInfo();

  // Auto redirect to version upgrade page when reset password link include migration token
  useEffect(() => {
    const handleMigrationUserResetPassword = async () => {
      try {
        if (migrationToken) {
          const migrationUserProfile =
            await publicUserAPI.getMigrationUserProfile(migrationToken);
          setUserOnboardingInfo((prev) => {
            return {
              ...prev,
              ...migrationUserProfile,
              actionToken: actionToken ?? "",
            };
          });
          setOnboardingScenario(OnboardingScenario.KS_USER_FORGOT_PASSWORD);
          navigate("../register");
        }
      } catch (e) {
        composeErrorHandlers((next) => (e) => {
          if (isAPIError(e)) {
            switch (e.errors[0]?.key) {
              case "token.not.found":
                setToken(null);
                navigate("/public/reset-password/link-invalid");
                break;
              case "token.expired":
                navigate("/public/reset-password/link-invalid");
                break;
              default:
                next(e);
            }
          } else {
            next(e);
          }
        })(e as GenericError);
      }
    };
    handleMigrationUserResetPassword();
  }, [
    migrationToken,
    actionToken,
    setUserOnboardingInfo,
    setOnboardingScenario,
    onNextStep,
    navigate,
    setToken,
  ]);

  const handleSubmit: FormSubmissionHandler<ResetPasswordFormValues> = async (
    values,
    { setSubmitting, setFieldError }
  ) => {
    setSubmitting(true);
    if (resetPwdToken) {
      try {
        await publicUserAPI.resetPassword(resetPwdToken, values.password);
        navigate("/public/reset-password/reset-successful");
      } catch (error) {
        composeErrorHandlers((next) => (e) => {
          if (isAPIError(e)) {
            switch (e.errors[0]?.key) {
              case "User.Password.Duplicate":
                setFieldError("password", t(SAME_PASSWORD_ERROR));
                break;
              case "token.not.found":
              case "token.expired":
                navigate("/public/reset-password/link-invalid");
                break;
              default:
                next(e);
            }
          } else {
            next(e);
          }
        })(error as GenericError);
      }
    }
    setSubmitting(false);
  };

  return migrationToken ? (
    <></>
  ) : (
    <div className="">
      <h2 className="text-secondary mb-4">
        {t("public.onboarding.resetPwdTitle")}
      </h2>
      <p className="mb-5 text-dark-grey">
        {t("public.onboarding.resetPwdTitleHint")}
      </p>
      <ResetPasswordForm onSubmit={handleSubmit} />
    </div>
  );
};
