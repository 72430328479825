export enum RoutesPath {
  AtlasElite = "atlas-elite",
  KentroSign = "kentro-sign",
  Midas = "midas",
  Topaz = "topaz",
}

export type ApplicationIcon = {
  src: string;
  alt: string;
  url?: string;
};

export type ApplicationInfo = {
  id: string;
  icon: ApplicationIcon;
  name: string;
  description: string;
  category: Categories;
  isSubscribed: boolean;
  isProfileSetup: boolean;
};

export enum Categories {
  TOOLS = "Tools",
  CONSTRUCTION = "Construction",
  FINANCE = "Finance",
  INSURANCE = "Insurance",
  ALL = "ALL",
}

export enum ApplicationName {
  AtlasElite = "ATLAS ELITE",
  KentroSign = "Kentro Sign",
  Midas = "MIDAS",
  Topaz = "TOPAZ",
}

export enum ApplicationDisplayName {
  AtlasElite = "application.atlasElite.name",
  KentroSign = "application.kentroSign.name",
  Midas = "application.midas.name",
  Topaz = "application.topaz.name",
}
