import TopazPreview1 from "assets/images/topaz-preview-01.png";
import TopazPreview2 from "assets/images/topaz-preview-02.png";
import TopazPreview3 from "assets/images/topaz-preview-03.png";
import TopazPreview4 from "assets/images/topaz-preview-04.png";
import { ReactComponent as SvgTopaz } from "assets/svg/topaz.svg";
import { ApplicationDetailLayout } from "features/applicationDashboard/pages/applicationDetail/ApplicationDetailLayout";
import {
  StyledSubtitle,
  StyledText,
} from "features/applicationDashboard/pages/applicationDetail/pages/AtlasEliteView";
import { AppDetailPageProps } from "features/applicationDashboard/pages/applicationDetail/types";
import { useTranslation } from "react-i18next";

export const TopazView = ({
  data,
  className,
}: AppDetailPageProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ApplicationDetailLayout
      className={className && className}
      data={data}
      icon={<SvgTopaz />}
      previewImages={[
        TopazPreview1,
        TopazPreview2,
        TopazPreview3,
        TopazPreview4,
      ]}
    >
      <div className="w-full mb-6">
        <StyledSubtitle>{t("application.topaz.detail.title1")}</StyledSubtitle>
        <StyledText>{t("application.topaz.detail.text1")}</StyledText>
        <StyledSubtitle>{t("application.topaz.detail.title2")}</StyledSubtitle>
        <StyledText>{t("application.topaz.detail.text2")}</StyledText>
        <StyledSubtitle>{t("application.topaz.detail.title3")}</StyledSubtitle>
        <StyledText>{t("application.topaz.detail.text3")}</StyledText>
      </div>
    </ApplicationDetailLayout>
  );
};
