import { ReactComponent as SvgCheckCircle } from "assets/svg/check-circle.svg";
import { ToastContainerId } from "common/toast";
import {
  CloseButtonProps,
  ToastContainer,
  ToastContainerProps,
} from "react-toastify";
import tw, { styled } from "twin.macro";

const KentroToastContainerBase = styled(ToastContainer)`
  --toastify-z-index: 0;
  &.Toastify__toast-container {
    ${tw`w-full z-default p-0`}

    &--top-center {
      ${tw`absolute left-0 top-0`}
      transform: unset;
    }
  }

  .Toastify {
    &__toast {
      ${tw`min-h-[48px] rounded-none shadow-none mb-0 px-[130px]`}
      ${tw`flex items-center`}
      ${tw`font-medium`}

      &--success {
        ${tw`text-light-green bg-green/10`}
      }

      &--warning {
        ${tw`text-light-red bg-error-red/10`}
      }

      &--error {
        ${tw`text-light-red bg-error-red/10`}
      }
    }

    &__toast-body {
      ${tw`p-0 m-0 flex flex-1`}
    }

    &__toast-icon {
      ${tw`w-5 mr-3`}
    }
  }
`;

export const KentroToastContainer = ({
  containerId = ToastContainerId.AfterLogin,
  position = "top-center",
  enableMultiContainer = true,
  autoClose,
  closeOnClick,
  closeButton = ToastCloseButton,
}: ToastContainerProps) => {
  return (
    <KentroToastContainerBase
      icon={({ type }) => type === "success" && <SvgCheckCircle />}
      containerId={containerId}
      position={position}
      enableMultiContainer={enableMultiContainer}
      autoClose={autoClose}
      closeButton={closeButton}
      closeOnClick={closeOnClick}
    />
  );
};

export const ToastCloseButton = ({ closeToast }: CloseButtonProps) => (
  <div onClick={closeToast} className="text-dark-grey">
    x
  </div>
);
