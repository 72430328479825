import {
  FIELD_IS_REQUIRED_ERROR,
  ONBOARDING_EMAIL_REGISTERED,
} from "constants/commonValidatorError";
import {
  PASSWORD_CONFIRMATION_ERROR,
  PASSWORD_FORMAT_ERROR,
} from "constants/passwordFormatError";
import { REG_PASSWORD_VALIDATOR } from "constants/passwordValidatorKey";

import { authAPI } from "api/auth";
import { publicUserAPI } from "api/publicUser";
import { Button } from "common/components/Button";
import { PasswordField } from "common/components/Form/PasswordField";
import { Message } from "common/components/Message";
import {
  composeErrorHandlers,
  isAPIError,
  GenericError,
} from "common/errorHandling";
import { PasswordValidator } from "features/public/components/PasswordValidator";
import { TitleSection } from "features/public/components/TitleSection";
import { useActionTokenExpirePopupHandler } from "features/public/hooks/useActionTokenExpirePopupHandler";
import { useUserOnboardingInfo } from "features/public/Register/hooks/useUserOnboardingInfo";
import {
  isCommonErrorShowInPasswordForm,
  PasswordFormType,
} from "features/public/utils/onboarding";
import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoggedIn } from "redux/slices/auth/authSlice";
import { FormSubmissionHandler } from "types/common";
import { AccountType } from "types/user";
import * as yup from "yup";

export type PasswordAccountFormValues = {
  password: string;
  confirmedPassword: string;
};

export const PasswordAccountForm = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userOnboardingInfo, clearOnboardingInfo } = useUserOnboardingInfo();

  const handleRefreshActionToken = useCallback(() => {
    clearOnboardingInfo();
    navigate("../../register");
  }, [navigate, clearOnboardingInfo]);

  const { handler: showExpiredTokenPopup } = useActionTokenExpirePopupHandler(
    handleRefreshActionToken
  );

  const schema: yup.SchemaOf<PasswordAccountFormValues> = yup.object().shape({
    password: yup
      .string()
      .required()
      .matches(REG_PASSWORD_VALIDATOR, t(PASSWORD_FORMAT_ERROR)),
    confirmedPassword: yup
      .string()
      .required()
      .test(
        "is-same-password",
        t(PASSWORD_CONFIRMATION_ERROR),
        (value, context) => {
          return value !== "" && value === context.parent.password;
        }
      ),
  });

  const handleSubmit: FormSubmissionHandler<PasswordAccountFormValues> = async (
    value,
    { setSubmitting, setStatus }
  ) => {
    try {
      setSubmitting(true);
      await publicUserAPI.register({
        email: userOnboardingInfo.email.trim(),
        password: value.password,
        personalDetail: {
          firstName: userOnboardingInfo.firstName.trim(),
          lastName: userOnboardingInfo.lastName.trim(),
        },
        company: {
          name: userOnboardingInfo.companyName?.trim() || null,
          title: userOnboardingInfo.companyTitle?.trim() || null,
        },
        userType: AccountType.INDIVIDUAL,
        actionToken: userOnboardingInfo.actionToken,
        defaultLanguageCode: "en", // TODO: integrate with language setting
      });
      await authAPI.login({
        username: userOnboardingInfo.email,
        password: value.password,
      });
      dispatch(setLoggedIn());
    } catch (e) {
      setSubmitting(false);
      composeErrorHandlers((next) => (e) => {
        if (isAPIError(e)) {
          e.status === 409
            ? setStatus(t(ONBOARDING_EMAIL_REGISTERED))
            : showExpiredTokenPopup();
        } else {
          next(e);
        }
      })(e as GenericError);
    }
  };

  return (
    <div className="relative w-full">
      <TitleSection
        title={`${t("public.onboarding.accountTitle")}`}
        description={t("public.onboarding.accountTitleHint")}
      />
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        initialValues={{
          password: "",
          confirmedPassword: "",
        }}
      >
        {({ values, isSubmitting, errors, status }) => {
          const isCommonErrorShow = isCommonErrorShowInPasswordForm(
            PasswordFormType.REGISTER_PASSWORD,
            errors.password,
            errors.confirmedPassword
          );
          return (
            <Form autoComplete="off" noValidate>
              <PasswordField
                name="password"
                label={`${t("common.password")}*`}
                className="mt-8"
                hideErrorMsg={errors.password === t(PASSWORD_FORMAT_ERROR)}
                isErrorShow={errors.password !== t(PASSWORD_FORMAT_ERROR)}
              />
              <PasswordField
                name="confirmedPassword"
                label={`${t("common.confirmPassword")}*`}
                className="mt-8"
                hideErrorMsg={
                  !!errors.password &&
                  errors.confirmedPassword !== t(FIELD_IS_REQUIRED_ERROR)
                }
                isErrorShow={
                  errors.confirmedPassword === t(FIELD_IS_REQUIRED_ERROR) ||
                  (!errors.password && !!errors.confirmedPassword)
                }
              />
              <div className="mt-9">
                <PasswordValidator password={values.password} />
              </div>
              {isCommonErrorShow ? (
                <Message
                  className="text-xs mt-6"
                  variant="warning"
                  isBackgroundEnable={false}
                >
                  {errors.password}
                </Message>
              ) : status ? (
                <Message
                  className="text-xs mt-6"
                  variant="warning"
                  isBackgroundEnable={false}
                >
                  {status}
                </Message>
              ) : null}

              <Button
                disabled={isSubmitting}
                variant="primary"
                className="mt-20 w-full"
                type="submit"
              >
                {t("public.button.create")}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
