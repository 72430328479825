import { useSelector } from "redux/hooks";
import { RootState } from "redux/rootReducer";
import { RedirectState } from "redux/slices/redirect/redirectSlice";

const useRedirectState = (): RedirectState => {
  const redirectState = useSelector((state: RootState) => state.redirect);

  return redirectState;
};

export default useRedirectState;
