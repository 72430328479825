import { CURRENT_PASSWORD_INCORRECT } from "constants/passwordFormatError";

import { userAPI } from "api/user";
import { Modal } from "common/components/Modal";
import {
  composeErrorHandlers,
  isAPIError,
  GenericError,
} from "common/errorHandling";
import { useModalWithData } from "common/hooks";
import { toast } from "common/toast";
import {
  UpdatePasswordFormValues,
  UpdatePasswordView,
} from "features/userSettings/components/passwordSetting/UpdatePasswordView";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";
import { FormSubmissionHandler } from "types/common";

const ResetButton = styled.button(() => [
  tw`rounded-lg h-12 flex justify-center items-center text-base py-[15px] px-3`,
  tw`text-dark-grey font-bold tracking-wider leading-4.5`,
  tw`bg-background-grey hover:bg-greyscale`,
  tw`bg-light-blue text-white hover:bg-navy`,
]);

export const UpdatePasswordModal = () => {
  const { t } = useTranslation();
  const handleSubmit: FormSubmissionHandler<UpdatePasswordFormValues> = async (
    values,
    { setFieldError }
  ) => {
    try {
      await userAPI.changeUserPassword(values.currentPassword, values.password);
      hideModal();
      toast.success(t("common.toast.accountDetail"));
    } catch (error) {
      composeErrorHandlers((next) => (e) => {
        if (isAPIError(e)) {
          if (e.errors[0]?.key === "user.password.wrong") {
            setFieldError("currentPassword", t(CURRENT_PASSWORD_INCORRECT));
          } else {
            next(e);
          }
        }
      })(error as GenericError);
    }
  };
  const [showModal, hideModal] = useModalWithData(() => ({ in: isOpen }) => (
    <Modal open={isOpen} onClose={hideModal} size="sm">
      <UpdatePasswordView hideModal={hideModal} onSubmit={handleSubmit} />
    </Modal>
  ));

  return (
    <ResetButton onClick={showModal}>
      {t("settings.accountDetail.changePassword")}
    </ResetButton>
  );
};
