type ProgressBarProps = {
  progressPercentage: number;
  className?: string;
};

export const ProgressBar = ({
  progressPercentage,
  className,
}: ProgressBarProps): JSX.Element => {
  return (
    <div
      className={`h-[5px] w-full bg-light-greyscale rounded overflow-hidden ${className}`}
      role="progressbar"
    >
      <div
        style={{ width: `${progressPercentage}%` }}
        className={`h-full bg-light-blue`}
      />
    </div>
  );
};
