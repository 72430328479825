import { Tab } from "@headlessui/react";
import { Button } from "common/components/Button";
import { StyledTab } from "common/components/StyledTab";
import { Privacy } from "features/public/Register/components/RegisterPoup/Privacy";
import { TermOfUse } from "features/public/Register/components/RegisterPoup/TermOfUse";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

export type RegisterPopupSectionProps = {
  id?: string;
};

const ScrollContainer = styled.div`
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #e4e6ef;
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 9999px;
    background-color: #00a3ff;
  }
`;

export const SectionHeading = tw.div`font-bold text-[18px] uppercase text-dark-blue mb-8`;
export const ContentTitle = tw.div`font-bold text-dark-blue mb-8`;

export const RegisterPopup = ({
  hideModal,
}: {
  hideModal: () => void;
}): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isAlreadyRead, setIsAlreadyRead] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const privacyRef = useRef<HTMLDivElement>(null);
  const termOfUseRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleScroll = () => {
    const scrollTop = containerRef.current?.scrollTop ?? 0;
    const containerHeight = containerRef.current?.clientHeight ?? 0;
    const privacyHeight = privacyRef.current?.clientHeight ?? 0;
    const termOfUseHeight = termOfUseRef.current?.clientHeight ?? 0;
    // reference: https://stackoverflow.com/questions/30320512/code-on-scroll-event-is-not-executing-if-window-is-zoomed-in-out-in-chrome
    const alreadyRead =
      Math.ceil(scrollTop + containerHeight) >= termOfUseHeight + privacyHeight;
    setIsAlreadyRead(alreadyRead);
    setSelectedIndex(scrollTop + containerHeight >= privacyHeight ? 1 : 0);
  };

  const handleTabSelected = (index: number) => {
    if (index) {
      termOfUseRef.current?.scrollIntoView(true);
    } else privacyRef.current?.scrollIntoView(true);
  };

  return (
    <Tab.Group
      selectedIndex={selectedIndex}
      onChange={(index) => handleTabSelected(index)}
    >
      <div className="p-8">
        <Tab.List className="flex gap-8">
          <StyledTab>{`01 ${t("common.privacyPolicy")}`}</StyledTab>
          <StyledTab>{`02 ${t("common.termsOfUse")}`}</StyledTab>
        </Tab.List>
        <ScrollContainer
          ref={containerRef}
          onScroll={handleScroll}
          className="my-8 pr-10 h-[514px] overflow-y-auto scroll-smooth"
          data-cy="scroll-container"
        >
          <div ref={privacyRef} className="pb-20">
            <Privacy />
          </div>
          <div ref={termOfUseRef} className="pb-20">
            <TermOfUse />
          </div>
        </ScrollContainer>
        <div className="flex justify-end">
          <Button
            variant="primary"
            disabled={!isAlreadyRead}
            onClick={hideModal}
            data-cy="accept-btn"
          >
            <span className="px-8">
              {t("public.onboarding.acceptPrivacy&termsOfUse")}
            </span>
          </Button>
        </div>
      </div>
    </Tab.Group>
  );
};
