import { GridWrapper } from "common/components/GridWrapper";
import { UpdatePasswordModal } from "features/userSettings/components/passwordSetting/UpdatePasswordPopup";
import {
  ProfileSettingCardLeftContainer,
  ProfileSettingCardRightContainer,
} from "features/userSettings/components/profileSetting/PersonalDetail";
import {
  Divider,
  ItemLabel,
  UserProfileProps,
  Wrapper,
} from "features/userSettings/components/UserSettingsView";
import padStart from "lodash/padStart";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";

const DetailTitle = styled.label(() => [
  tw`text-base leading-5.5 w-full text-dark-blue`,
]);
const DetailValue = styled.span(() => [tw`text-dark-grey text-sm leading-5.5`]);

export const AccountDetails = ({ userProfile }: UserProfileProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="flex flex-col w-full px-8">
        <ItemLabel>{t("settings.accountDetail.title")}</ItemLabel>
        <Divider />
      </div>
      <GridWrapper>
        <ProfileSettingCardLeftContainer>
          <Wrapper className="mt-5">
            <DetailTitle>{t("common.password")}</DetailTitle>
          </Wrapper>
          <Wrapper className="mt-2">
            <DetailValue>
              {padStart("", userProfile?.email.length, "*")}
            </DetailValue>
          </Wrapper>
        </ProfileSettingCardLeftContainer>
        <ProfileSettingCardRightContainer className="flex items-center">
          <UpdatePasswordModal />
        </ProfileSettingCardRightContainer>
      </GridWrapper>
    </div>
  );
};
