import { ReactNode } from "react";

export enum BottomSpacingSize {
  CLOSE = "close",
  WIDE = "wide",
}

const bottomSpacingMap: Record<BottomSpacingSize, string> = {
  close: "mb-10",
  wide: "mb-16",
};

type TitleSectionProps = {
  title: string;
  description: ReactNode;
  bottomSpacing?: BottomSpacingSize;
};

export const TitleSection = ({
  title,
  description,
  bottomSpacing = BottomSpacingSize.WIDE,
}: TitleSectionProps): JSX.Element => {
  return (
    <div className={bottomSpacingMap[bottomSpacing]} data-cy="title-section">
      <h1 className="text-dark-blue mb-3">{title}</h1>
      <div className="text-dark-grey">{description}</div>
    </div>
  );
};
