import { ReactComponent as SvgWarning } from "assets/svg/warning.svg";
import { Button } from "common/components/Button";
import { InputField } from "common/components/Form/InputField";
import { PasswordField } from "common/components/Form/PasswordField";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { FormSubmissionHandler } from "types/common";
import * as yup from "yup";

export type LoginFormValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSubmit: FormSubmissionHandler<LoginFormValues>;
};

export const LoginForm = ({ onSubmit }: LoginFormProps): JSX.Element => {
  const { t } = useTranslation();
  const schema: yup.SchemaOf<LoginFormValues> = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required(),
  });

  return (
    <Formik
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
      initialValues={{
        email: "",
        password: "",
      }}
    >
      {({ isSubmitting, status }) => {
        return (
          <Form className="" autoComplete="off" noValidate>
            <InputField
              name="email"
              label={t("common.email")}
              type="text"
              className="mb-[32px]"
            />
            <PasswordField
              name="password"
              label={t("common.password")}
              isErrorShow={true}
            />
            <div className="flex justify-end mt-8 mb-[76px]">
              <NavLink
                to="../forgot-password"
                className="!pr-0 text-dark-grey text-sm font-medium hover:text-light-blue"
              >
                {`${t("public.button.forgotPassword")}?`}
              </NavLink>
            </div>
            {status && (
              <div className="flex items-center gap-2 text-error-red mb-2.5">
                <SvgWarning className="w-3.5 h-3.5" />
                <p className="text-xs">{status}</p>
              </div>
            )}
            <Button
              type="submit"
              disabled={isSubmitting}
              variant="primary"
              className="w-full mb-3"
            >
              {t("public.button.login")}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
