import { Tab } from "@headlessui/react";
import { StyledTab } from "common/components/StyledTab";
import { Categories } from "features/applicationDashboard/types";
import { getTabText } from "features/applicationDashboard/utils/common";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type CategoriesTabProps = {
  selectedIndex: number;
  onClick: (index: number, category: Categories) => void;
  children: ReactNode;
};

export const CategoriesTab = ({
  selectedIndex,
  onClick: onSelectedTab,
  children,
}: CategoriesTabProps) => {
  const { t } = useTranslation();
  const sortedCategories = [
    Categories.ALL,
    Categories.CONSTRUCTION,
    Categories.INSURANCE,
    Categories.FINANCE,
    Categories.TOOLS,
  ];

  return (
    <Tab.Group selectedIndex={selectedIndex}>
      <Tab.List className="flex gap-[32px]">
        {sortedCategories.map((category, index) => (
          <StyledTab key={index} onClick={() => onSelectedTab(index, category)}>
            {t(getTabText(category))}
          </StyledTab>
        ))}
      </Tab.List>
      {children}
    </Tab.Group>
  );
};
