import { AppInfo } from "api/dashboard";
import AtlasEliteLogo from "assets/images/atlas-elite-vertical.png";
import KentroSignLogo from "assets/images/kentro-sign-vertical.png";
import MidasLogo from "assets/images/midas-vertical.png";
import TopazLogo from "assets/images/topaz-vertical.png";
import {
  ApplicationDisplayName,
  ApplicationInfo,
  ApplicationName,
  Categories,
} from "features/applicationDashboard/types";

export const MockCurrentUser = {
  email: "ecchan@kentro.com",
  firstName: "Eric",
  lastName: "Chan",
  companyName: null,
  companyTitle: null,
};

export const MockAppIconData = [
  {
    src: AtlasEliteLogo,
    alt: "Atlas Elite",
    url: "",
  },
  {
    src: KentroSignLogo,
    alt: "Kentro Sign",
    url: "https://kentro-staging.document.gateway.kameleontech.com/",
  },
  {
    src: MidasLogo,
    alt: "Midas",
    url: "",
  },
  {
    src: TopazLogo,
    alt: "Topaz",
    url: "",
  },
];

export const MockAppPreviewImageData = [];

export const MockAppInformationData: ApplicationInfo[] = [
  {
    id: "id1",
    name: ApplicationDisplayName.AtlasElite,
    icon: MockAppIconData[0],
    description:
      "Atlas Elite is a trade finance application that allows corporates to seek for various financing solutions (e.g. invoice financing) from the various financiers on the Kentro network.",
    category: Categories.FINANCE,
    isSubscribed: false,
    isProfileSetup: true,
  },
  {
    id: "id2",
    name: ApplicationDisplayName.KentroSign,
    icon: MockAppIconData[1],
    description:
      "Kentro Sign is a signing and authentication tool where businesses and individuals can get their documents digitally signed by other parties. Authenticity of the documents can also be ensured on the application.",
    category: Categories.TOOLS,
    isSubscribed: true,
    isProfileSetup: true,
  },
  {
    id: "id3",
    name: ApplicationDisplayName.Midas,
    icon: MockAppIconData[2],
    description:
      "Motor Insurance DLT-Based Authentication System (MIDAS) is an industry-wide blockchain authentication platform that tackles motor insurance frauds and provisions the digitization of the industry. It is a joint collaboration between the Hong Kong Federation of Insurers (HKFI) and CryptoBLK.",
    category: Categories.INSURANCE,
    isSubscribed: true,
    isProfileSetup: false,
  },
  {
    id: "id4",
    name: ApplicationDisplayName.Topaz,
    icon: MockAppIconData[3],
    description:
      "Topaz is a construction management platform focusing on delivering a smooth payment application process between all participating parties. (e.g. developers, main contractors, sub-contractors/suppliers, quantity surveyor & architect)",
    category: Categories.CONSTRUCTION,
    isSubscribed: true,
    isProfileSetup: true,
  },
];

export const MockAppInfoData: AppInfo[] = [
  {
    app: {
      id: "id1",
      appName: ApplicationName.AtlasElite,
    },
    enabled: false,
  },
  {
    app: {
      id: "id2",
      appName: ApplicationName.KentroSign,
    },
    enabled: true,
  },
  {
    app: {
      id: "id3",
      appName: ApplicationName.Midas,
    },
    enabled: true,
  },
  {
    app: {
      id: "id4",
      appName: ApplicationName.Topaz,
    },
    enabled: true,
  },
];

export const getMockAppDataById = (
  applicationId: string | undefined
): ApplicationInfo | undefined => {
  switch (applicationId) {
    case "id1":
      return MockAppInformationData[0];
    case "id2":
      return MockAppInformationData[1];
    case "id3":
      return MockAppInformationData[2];
    case "id4":
      return MockAppInformationData[3];
    default:
      return undefined;
  }
};
