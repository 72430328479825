import { ProgressBarWithText } from "common/components/ProgressBarWithText";
import { useUserOnboardingInfo } from "features/public/Register/hooks/useUserOnboardingInfo";
import {
  OnboardingScenario,
  UserOnboardingProgressBarStep,
  UserOnboardingStep,
} from "features/public/Register/types";
import {
  KS_USER_SIGN_UP_BEFORE_VERSION_UPDATE_FLOW,
  KS_USER_SIGN_UP_AFTER_VERSION_UPDATE_FLOW,
} from "features/public/utils/onboarding";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

type RegistrationProgressProps = {
  className?: string;
};

const TITLE: {
  [key in UserOnboardingProgressBarStep]: string;
} = {
  [UserOnboardingStep.PERSONAL_ACCOUNT]:
    "public.onboarding.personalFormBarTitle",
  [UserOnboardingStep.EMAIL_ACCOUNT]: "public.onboarding.emailFormBarTitle",
  [UserOnboardingStep.OTP_CODE]: "public.onboarding.OTPFormBarTitle",
  [UserOnboardingStep.PASSWORD_ACCOUNT]:
    "public.onboarding.passwordFormBarTitle",
};

export const OnboardingProgress = ({
  className,
}: RegistrationProgressProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentRoute, allSteps, onboardingScenario } =
    useUserOnboardingInfo();

  const totalStep = useMemo(() => {
    // Special progress steps in Scenario 3
    if (onboardingScenario === OnboardingScenario.KS_USER_SIGN_UP) {
      if (KS_USER_SIGN_UP_BEFORE_VERSION_UPDATE_FLOW.includes(currentRoute)) {
        return KS_USER_SIGN_UP_BEFORE_VERSION_UPDATE_FLOW;
      } else return KS_USER_SIGN_UP_AFTER_VERSION_UPDATE_FLOW;
    }
    return allSteps.filter((it) => it !== UserOnboardingStep.VERSION_UPDATE);
  }, [allSteps, currentRoute, onboardingScenario]);

  const progressBarStep = useMemo(() => {
    return totalStep.indexOf(currentRoute) + 1;
  }, [totalStep, currentRoute]);

  return (
    <ProgressBarWithText
      currentPage={progressBarStep}
      pageTitle={
        currentRoute !== UserOnboardingStep.VERSION_UPDATE
          ? t(TITLE[currentRoute])
          : undefined
      }
      pageAmount={totalStep.length}
      progressPercentage={(progressBarStep / totalStep.length) * 100}
      className={className}
    />
  );
};
