import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "redux/slices/auth/authSlice";
import partyReducer from "redux/slices/party/partySlice";
import redirectReducer from "redux/slices/redirect/redirectSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  party: partyReducer,
  redirect: redirectReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
