import { useModal } from "@liholiho/react-modal-hook";
import { Modal } from "common/components/Modal";
import { useLogout } from "common/hooks";
import { LogoutConfirmPopup } from "features/applicationDashboard/components/LogoutConfirmPopup";

export const useLogoutPopupHandler = () => {
  const logout = useLogout();
  const [show, hide] = useModal(({ in: isOpen }) => {
    return (
      <Modal open={isOpen} onClose={hide} size="md">
        <LogoutConfirmPopup onConfirm={() => logout()} onCancel={hide} />
      </Modal>
    );
  }, []);

  return {
    handler: show,
  };
};
