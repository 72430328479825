import { ApplicationAccessItem } from "features/userSettings/components/applicationAccess/ApplicationAccessItem";
import {
  Divider,
  ItemLabel,
  UserProfileProps,
} from "features/userSettings/components/UserSettingsView";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const ApplicationAccess = ({
  userProfile,
}: UserProfileProps): JSX.Element => {
  const { t } = useTranslation();

  const sortedUserSubscription = useMemo(() => {
    const cloneUserSubscription = userProfile?.userSubscription
      ? [...userProfile.userSubscription]
      : [];
    cloneUserSubscription.sort((a, b) =>
      a.app.appName.toString().localeCompare(b.app.appName)
    );
    return cloneUserSubscription;
  }, [userProfile?.userSubscription]);

  return (
    <div className="w-full px-8">
      <div className="flex flex-col w-full">
        <ItemLabel>{t("settings.applicationsAccess.title")}</ItemLabel>
        <Divider />
      </div>
      <div role="listitem">
        {sortedUserSubscription.length > 0 ? (
          <>
            {sortedUserSubscription.map(({ app }) => {
              return (
                <ApplicationAccessItem
                  key={app.id}
                  className="mb-5"
                  app={app.appName}
                  company={userProfile?.companyName}
                />
              );
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};
