import { FIELD_IS_REQUIRED_ERROR } from "constants/commonValidatorError";
import {
  PASSWORD_CONFIRMATION_ERROR,
  PASSWORD_FORMAT_ERROR,
  SAME_PASSWORD_ERROR,
} from "constants/passwordFormatError";
import { REG_PASSWORD_VALIDATOR } from "constants/passwordValidatorKey";

import { Button } from "common/components/Button";
import { PasswordField } from "common/components/Form/PasswordField";
import { Message } from "common/components/Message";
import { PasswordValidator } from "features/public/components/PasswordValidator";
import {
  isCommonErrorShowInPasswordForm,
  PasswordFormType,
} from "features/public/utils/onboarding";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { FormSubmissionHandler } from "types/common";
import * as yup from "yup";

export type UpdatePasswordFormValues = {
  currentPassword: string;
  password: string;
  confirmedPassword: string;
};

type UpdatePasswordFormProps = {
  onSubmit: FormSubmissionHandler<UpdatePasswordFormValues>;
  hideModal: () => void;
};

export const UpdatePasswordView = ({
  onSubmit,
  hideModal,
}: UpdatePasswordFormProps): JSX.Element => {
  const { t } = useTranslation();
  const schema: yup.SchemaOf<UpdatePasswordFormValues> = yup.object().shape({
    currentPassword: yup.string().required(),
    password: yup
      .string()
      .required()
      .matches(REG_PASSWORD_VALIDATOR, t(PASSWORD_FORMAT_ERROR))
      .test("is-same-password", t(SAME_PASSWORD_ERROR), (value, context) => {
        return value !== context.parent.currentPassword;
      }),
    confirmedPassword: yup
      .string()
      .required()
      .test(
        "is-same-password",
        t(PASSWORD_CONFIRMATION_ERROR),
        (value, context) => {
          return REG_PASSWORD_VALIDATOR.test(context.parent.password)
            ? value === context.parent.password
            : true;
        }
      ),
  });

  return (
    <>
      <Formik
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
        initialValues={{
          currentPassword: "",
          password: "",
          confirmedPassword: "",
        }}
      >
        {({ values, errors }) => {
          const isCommonErrorShow = isCommonErrorShowInPasswordForm(
            PasswordFormType.UPDATE_PASSWORD,
            errors.password,
            errors.confirmedPassword,
            errors.currentPassword
          );
          return (
            <Form autoComplete="off" noValidate data-cy="reset-password-form">
              <PasswordField
                name="currentPassword"
                label={`${t("common.currentPassword")}*`}
                className="mt-8"
                hideErrorMsg={
                  errors.currentPassword === t(PASSWORD_FORMAT_ERROR)
                }
                isErrorShow={
                  errors.currentPassword !== t(PASSWORD_FORMAT_ERROR)
                }
              />
              <PasswordField
                name="password"
                label={`${t("common.password")}*`}
                className="mt-8"
                hideErrorMsg={
                  errors.password === t(PASSWORD_FORMAT_ERROR) ||
                  errors.password === t(SAME_PASSWORD_ERROR)
                }
                isErrorShow={errors.password === t(FIELD_IS_REQUIRED_ERROR)}
              />
              <PasswordField
                name="confirmedPassword"
                label={`${t("common.confirmPassword")}*`}
                className="mt-8"
                hideErrorMsg={
                  (errors.confirmedPassword !==
                    t(PASSWORD_CONFIRMATION_ERROR) ||
                    errors.currentPassword === t(FIELD_IS_REQUIRED_ERROR)) &&
                  errors.confirmedPassword !== t(FIELD_IS_REQUIRED_ERROR)
                }
                isErrorShow={
                  errors.confirmedPassword === t(FIELD_IS_REQUIRED_ERROR) ||
                  (!errors.password &&
                    !errors.currentPassword &&
                    !!errors.confirmedPassword)
                }
              />
              <div className="mt-9">
                <PasswordValidator password={values.password} />
              </div>
              {isCommonErrorShow && (
                <Message
                  variant="warning"
                  isBackgroundEnable={false}
                  className="text-xs leading-4 mt-[52px]"
                >
                  {errors.password}
                </Message>
              )}
              {isCommonErrorShow ? (
                <Button className="w-full mb-4 mt-3" variant="primary">
                  {t("settings.accountDetail.updatePassword")}
                </Button>
              ) : (
                <Button className="w-full mb-4 mt-20" variant="primary">
                  {t("settings.accountDetail.updatePassword")}
                </Button>
              )}
            </Form>
          );
        }}
      </Formik>
      <Button className="w-full" onClick={hideModal} variant="secondary">
        {t("common.cancel")}
      </Button>
    </>
  );
};
