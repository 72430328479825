import { ORIGIN_URL } from "common/utils/cross-domain/base/utils";
import { CrossDomainMessageType } from "common/utils/cross-domain/react/helper";
import { UUID } from "types/common";

export type ReceiveMessageCallback<T> = (
  id: UUID,
  actionType: CrossDomainMessageType,
  receiveMessage: T,
  eventOrigin?: string
) => void;

export type CrossDomainMessage<T> = {
  id: UUID;
  action: CrossDomainMessageType;
  value: T;
};

/**
 * @template T Description of the type of receive message.
 * @param event MessageEvent
 * @param callbackFn callback function to handle to received message
 * @param domains domain to listen to
 */
export const receiveMessageHandler = <T>(
  event: MessageEvent<CrossDomainMessage<T>>,
  callbackFn: ReceiveMessageCallback<T>,
  domains: ORIGIN_URL[]
) => {
  if (domains.includes(event.origin)) {
    const { id, action, value } = event.data;
    callbackFn(id, action, value, event.origin);
  }
};
