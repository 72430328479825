import { PublicLayout } from "common/layouts/PublicLayout";
import { LoginView } from "features/public/components/LoginView";
import { ForgotPasswordLayout } from "features/public/ForgotPassword/components/ForgotPasswordLayout";
import { ForgotPasswordView } from "features/public/ForgotPassword/pages/ForgotPasswordView";
import { SentEmailView } from "features/public/ForgotPassword/pages/SentEmailView";
import { ResetPasswordLayout } from "features/public/ResetPassword/components/ResetPasswordLayout";
import { LinkInvalidView } from "features/public/ResetPassword/pages/LinkInvalidView";
import { LinkSentView } from "features/public/ResetPassword/pages/LinkSentView";
import { PasswordResetSuccessfulView } from "features/public/ResetPassword/pages/PasswordResetSuccessfulView";
import { ResetPasswordView } from "features/public/ResetPassword/pages/ResetPasswordView";
import { SupportRequestView } from "features/public/ResetPassword/pages/SupportRequestView";
import { RegisterRoutes } from "features/RegisterRoutes";
import { useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import tw from "twin.macro";

export const Container = tw.div`px-6 absolute w-full grid grid-cols-6 top-0 flex flex-col justify-center w-full h-full overflow-y-auto`;
export const ContentWrapper = tw.div`col-span-6 flex flex-col justify-between items-center min-h-[720px] h-full`;

export const PublicRoutes = (): JSX.Element => {
  const [token, setToken] = useState<string | null>(null);
  return (
    <PublicLayout>
      <Routes>
        <Route index element={<Navigate to="login" />} />
        <Route path="login" element={<LoginView />} />
        <Route path="register/*" element={<RegisterRoutes />} />
        <Route
          path="forgot-password"
          element={
            <ForgotPasswordLayout>
              <Outlet />
            </ForgotPasswordLayout>
          }
        >
          <Route index element={<Navigate to="form" />} />
          <Route path="form" element={<ForgotPasswordView />} />
          <Route path="sent-email" element={<SentEmailView />} />
        </Route>
        <Route
          path="reset-password"
          element={
            <ResetPasswordLayout setToken={setToken}>
              <Outlet />
            </ResetPasswordLayout>
          }
        >
          <Route index element={<ResetPasswordView setToken={setToken} />} />
          <Route
            path="link-invalid"
            element={<LinkInvalidView token={token} />}
          />
          <Route path="support-request" element={<SupportRequestView />} />
          <Route path="link-sent" element={<LinkSentView />} />
          <Route
            path="reset-successful"
            element={<PasswordResetSuccessfulView />}
          />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </PublicLayout>
  );
};
