import { StatusItem } from "common/components/StatusItem";

type passwordStatusItemProps = {
  label: string;
  password: string;
  testRegex: RegExp;
};

export const PasswordStatusItem = ({
  label,
  password,
  testRegex,
}: passwordStatusItemProps) => {
  const passwordValid = testRegex.test(password);
  return (
    <StatusItem
      className={`${
        passwordValid ? "text-light-blue" : "text-grey"
      } text-xs leading-4
      `}
      spacing="sm"
      isDotIconShow={true}
    >
      {label}
    </StatusItem>
  );
};
