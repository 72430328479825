import AtlasEliteHorizontal from "assets/images/atlas-elite-horizontal.png";
import KentroSignHorizontal from "assets/images/kentro-sign-horizontal.png";
import MidasHorizontal from "assets/images/midas-horizontal.png";
import TopazHorizontal from "assets/images/topaz-horizontal.png";
import { Checkbox } from "common/components/Form/Checkbox";
import { SelectOption } from "common/components/Form/SelectField";
import { ApplicationDisplayName } from "features/applicationDashboard/types";
import { useMemo } from "react";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";

type ApplicationLabelProps = {
  option: SelectOption;
  formatOptionLabelMeta: FormatOptionLabelMeta<SelectOption>;
};

export const renderHorizontalIconByApplicationName = (
  appName: ApplicationDisplayName
) => {
  switch (appName) {
    case ApplicationDisplayName.Topaz:
      return <img src={TopazHorizontal} alt="Topaz" />;
    case ApplicationDisplayName.AtlasElite:
      return <img src={AtlasEliteHorizontal} alt="Atlas Elite" />;
    case ApplicationDisplayName.Midas:
      return <img src={MidasHorizontal} alt="Midas" />;
    case ApplicationDisplayName.KentroSign:
      return <img src={KentroSignHorizontal} alt="kentro Sign" />;
    default:
      return null;
  }
};

export const ApplicationLabel = ({
  option,
  formatOptionLabelMeta,
}: ApplicationLabelProps) => {
  const isSelected = useMemo(
    () =>
      formatOptionLabelMeta.selectValue.find((it) => it.value === option.value),
    [formatOptionLabelMeta, option]
  );

  const label = option.label as ApplicationDisplayName;

  return formatOptionLabelMeta.context === "menu" ? (
    <div className="flex items-center gap-2.5">
      <Checkbox
        name={option.value}
        value={option.value}
        isChecked={!!isSelected}
        handleOnChange={() => {}}
        className="cursor-pointer"
      />
      <div>{renderHorizontalIconByApplicationName(label)}</div>
    </div>
  ) : (
    <div>{renderHorizontalIconByApplicationName(label)}</div>
  );
};
