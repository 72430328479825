import { AuthenticatedCheck } from "common/components/AuthenticatedCheck";
import { Loader } from "common/components/Loader";
import { useAuthRehydrate } from "common/hooks";
import { ApplicationDashboardLayout } from "common/layouts/ApplicationDashboardLayout";
import { SessionTimeoutLayout } from "common/layouts/SessionTimeoutLayout";
import { BusinessRoutes } from "features/BusinessRoutes";
import { UserOnboardingContextProvider } from "features/public/contexts/UserOnboardingContext";
import { PublicRoutes } from "features/PublicRoutes";
import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

const App = (): JSX.Element => {
  const isHydrated = useAuthRehydrate();
  return (
    <div>
      <Loader isLoading={!isHydrated}>
        <Routes>
          <Route
            path="/public/*"
            element={
              <AuthenticatedCheck isPublic>
                <UserOnboardingContextProvider>
                  <PublicRoutes />
                </UserOnboardingContextProvider>
              </AuthenticatedCheck>
            }
          />
          <Route
            path="/"
            element={
              <AuthenticatedCheck>
                <SessionTimeoutLayout>
                  <ApplicationDashboardLayout>
                    <Outlet />
                  </ApplicationDashboardLayout>
                </SessionTimeoutLayout>
              </AuthenticatedCheck>
            }
          >
            <Route index element={<Navigate to="dashboard" />} />
            <Route path="*" element={<BusinessRoutes />} />
          </Route>
        </Routes>
      </Loader>
    </div>
  );
};

export default App;
